import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import Cookies from 'js-cookie';
import api from '../api';

export type TrackingConsent = {
  required: boolean;
  preferences: boolean;
  analytics: boolean;
  marketing: boolean;
};

const gaTrackingId='G-DE0MXS9NX8';
const mpTrackingId='349346427935097';
const uxDiagnostics = true;

let gaInitialised = false;
let mpInitialised = false;

const acceptGATracking = () => {
  // Initialize Google Analytics only in production environment
  if (gaTrackingId) {
    ReactGA.initialize(gaTrackingId);
    gaInitialised = true;
  }
};


const rejectGATracking = () => {
  if (gaTrackingId) {
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
  }
  gaInitialised = false;
};

const acceptMPTracking = () => {
  // Initialise Meta Pixel only in production environment
  if (mpTrackingId) {
    ReactPixel.init(mpTrackingId, undefined, {
      autoConfig: true,
      debug: false,
    });
    ReactPixel.grantConsent();
    mpInitialised = true;
  }
};

const rejectMPTracking = () => {
  if (mpTrackingId) {
    ReactPixel.revokeConsent();
  }
  mpInitialised = false;
};

// Function to track page views
export const trackPageView = (page: string) => {
  if (gaInitialised) {
    ReactGA.send({ hitType: 'pageview', page });
  }

  if (mpInitialised) {
    ReactPixel.track(page);
  }

  if (uxDiagnostics) {
    ( async () =>
      api.sendUXDiagnostics('pageview', `page=${page}`)
    )();
  }
};

// Function to track custom events
export const trackEvent = (category: string, action: string, label?: string) => {
  if (gaInitialised) {
    ReactGA.event({ category, action, label });
  }

  if (mpInitialised) {
    ReactPixel.trackCustom(category, { action, label });
  }

  if (uxDiagnostics) {
    ( async () =>
      api.sendUXDiagnostics('event', `category=${category}:action=${action}:label=${label}`)
    )();
  }
};

export const isTrackingEnabled = () => {
  return gaInitialised || mpInitialised;
};

export const enableTracking = () => {
  acceptGATracking();
  acceptMPTracking();
};

export const disableTracking = () => {
  rejectGATracking();
  rejectMPTracking();
};

export const onConsent = (consent: TrackingConsent) => {
  (consent.analytics) ? enableTracking() : disableTracking();
};
