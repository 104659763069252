import * as React from 'react';
import Chip from '@mui/material/Chip';

interface EmotionColors {
  [key: string]: {
    background: string;
    text: string;
  };
}

const emotionColors: EmotionColors = {
  Annoyance: {
    background: "#E6FAF9",
    text: "#029288",
  },
  Sadness: {
    background: "#EFE6FD",
    text: "#6038E1",
  },
  Fear: {
    background: "#FEF5E7",
    text: "#AE7008",
  },
  Anger: {
    background: "#FEECEB",
    text: "#AA3028",
  },
  Boastful: {
    background: "#FDEDF8",
    text: "#D940AB",
  },
  Surprise: {
    background: "#EAF1FF",
    text: "#2566E8",
  },
  Happiness: {
    background: "#E8F7F0",
    text: "#0D623A",
  },
  Resentment: {
    background: "#D0D3D9",
    text: "#383E49",
  },
  Confident: {
    background: "#EFE6FD",
    text: "#6038E1",
  },
  Neutral: {
    background: "#F7F7F7",
    text: "#333333",
  }
};

const getEmotionColor = (emotion: string) => {
  return emotionColors[emotion] || emotionColors.Neutral;
};

interface ChipProps {
  emotion: string;
}

const EmotionChip: React.FC<ChipProps> = ({ emotion }) => {
  const capitalizedEmotion = emotion.charAt(0).toUpperCase() + emotion.slice(1);
  return (
    <Chip
      label={capitalizedEmotion}
      size="small"
      sx={{
        backgroundColor: getEmotionColor(capitalizedEmotion).background,
        color: getEmotionColor(capitalizedEmotion).text,
        // marginTop: 1
      }}
    />
  );
};

export default EmotionChip;
