import * as React from 'react';
import { Box } from '@mui/material';
import LoginForm from '../components/LoginForm';
import AppDrawer from '../components/AppDrawer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const Login = () => {
  const mainContent = (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: '20%',
          left: '50%',
          transform: 'translate(-50%, 0%)',
          width: '400px',
        }}
      >
        <Paper sx={{ p: 3, borderRadius: '8px' }}>
          <Typography variant="h6" sx={{pb: 1}}>
            Log in
          </Typography>
          <Typography variant="body2">
            Log in to your Mindbloom account.
          </Typography>
          <LoginForm />
        </Paper>
      </Box>
    </>
  );
  return (
    <AppDrawer pageName="" mainContent={mainContent} />
  );
};

export default Login;
