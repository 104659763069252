import React from 'react';
import { ConversationResponseDTO } from '../../api/types/conversation';
import TruncatedText from '../TruncatedText';
import { Box, Typography } from '@mui/material';
import Transcript from './Transcript';

export type ConversationSummaryProps = {
  seedConversation: ConversationResponseDTO[];
  conversationBrief?: string;
  botName? : string;
}

const ConversationSummary = ({seedConversation, conversationBrief, botName} : ConversationSummaryProps) => {
  const botFirstName = botName?.trim().split(' ')[0];
  const messages = (seedConversation ?? []).map(conversation => ({
    isBot: conversation.isBot,
    content: conversation.statement
  }));

  return (
    <Box height="100%" overflow="auto" p={2}>
      {conversationBrief && (
        <Box pb={2}>
          <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
            Backstory
          </Typography>
          <Box>
            <TruncatedText text={conversationBrief} maxLines={6} />
          </Box>
        </Box>
      )}
      {seedConversation && (
        <Box pb={1}>
          <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
            Conversation History
          </Typography>
        </Box>
      )}
      <Transcript pastTranscripts={messages}
                  liveTranscript=''
                  messagePending={false}
                  botName={botFirstName ?? ''}
                  scrollable={false}
      />
    </Box>
  );
}

export default ConversationSummary;