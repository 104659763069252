import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ py: 4, mt: 8 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={6} md={4}>
            <Typography variant="body2" color="text.secondary">
              © {new Date().getFullYear()} Mindbloom
            </Typography>
          </Grid>
          {!isMediumScreen && ( // Render the middle grid only if not on a small screen
            <Grid item xs={6} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box
                component="img"
                src="/logo_blacktext.svg"
                alt="Mindbloom"
                sx={{ height: 40 }}
              />
            </Grid>
          )}
          <Grid item xs={6} md={4}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Link variant="body2" color="text.secondary" href="/privacy">Privacy Policy</Link>
              <Link variant="body2" color="text.secondary" href="/terms" sx={{ ml: 4 }}>Terms of Service</Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
