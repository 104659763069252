import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConversationDTO, ConversationSummaryDTO } from '../api/types/conversation';

type ConversationsState = {
  conversations: ConversationSummaryDTO[];
  selection?: ConversationDTO;
};

const initialState: ConversationsState = {
  conversations: []
};

export type SetConversationsPayload = {
  conversations: ConversationSummaryDTO[]
};

export type SelectDisposalPayload = {
  selection: ConversationDTO;
};

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {

    setSelection: (state, action: PayloadAction<SelectDisposalPayload | undefined>) => {
      if (action.payload) {
        state.selection = action.payload.selection;
      } else {
        state.selection = undefined;
      }
    },

    setConversations: (state, action: PayloadAction<SetConversationsPayload>) => {
      state.conversations = action.payload?.conversations || {};
    },
  },
});

export default conversationsSlice.reducer;
export const {
  setSelection,
  setConversations,
} = conversationsSlice.actions;
