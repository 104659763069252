import { BrowserMindbloomAPI } from './api/mb-api-impl';

const baseUrl = new URL(window.location.origin);
baseUrl.pathname = '/api';

const api = new BrowserMindbloomAPI({ 
  baseUrl: baseUrl.toString(),
  defaultHeaders: {
    'content-type': 'application/json',
  }
});
export default api;
