import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TermsContent from '../components/TermsContent';
import { trackEvent } from '../lib/tracking';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleAgreeTerms: () => void;
}

const TermsAndConditionsModal: React.FC<ModalProps> = ({ open, handleClose, handleAgreeTerms }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const handleAgreeAndRegister = () => {
    trackEvent('Button', 'Click', 'Agree to T&C');
    handleAgreeTerms();
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'min(800px, 100vw)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          maxHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ p: 0 }}>
          <Typography variant="h6" gutterBottom sx={{ fontFamily: 'inter, sans-serif', p: 2 }}>
            Terms and Conditions
          </Typography>
        </Box>
        <Box 
          ref={contentRef}
          sx={{ flexGrow: 1, overflowY: 'auto', pl: 0 }} 
        >
          <TermsContent onAgree={handleAgreeAndRegister}/>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2,
            backgroundColor: 'background.paper',
          }}
        >
        </Box>
      </Box>
    </Modal>
  );
};

export default TermsAndConditionsModal;
