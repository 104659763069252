/**
 * @module masterdata
 * @description This module provides utility functions for managing master data.
 */
import { Ethnicity, Gender, toEthnicity, toGender } from '../api/types/common';

const DEFAULT_GENDER = Gender.MALE;
const GENDERS = new Set([
  Gender.MALE,
  Gender.FEMALE,
  Gender.NON_BINARY,
  Gender.AGENDER,
  Gender.OTHER,
]);

const DEFAULT_ETHNICITY = Ethnicity.BRITISH;
const ETHNICITIES = new Set([
  Ethnicity.INDIAN,
  Ethnicity.BRITISH,
  Ethnicity.CHINESE,
  Ethnicity.OTHER_ASIAN,
  Ethnicity.AFRICAN,
  Ethnicity.EUROPEAN,
  Ethnicity.LATIN_AMERICAN,
  Ethnicity.MIDDLE_EASTERN,
  Ethnicity.MIXED_ETHNIC,
  Ethnicity.OTHER_WHITE,
]);

const DEFAULT_PERSONALITY = 'Open to new experience';
const PERSONALITIES = new Set([
  'Open to new experience',
  'Conscientious',
  'Extroverted',
  'Agreeable',
  'Emotionally reactive',
]);

const DEFAULT_EMOTION = 'Neutral';
const EMOTIONS = new Set([
  'Anger',
  'Annoyance',
  'Boastful',
  'Confident',
  'Fear',
  'Happiness',
  'Neutral',
  'Resentment',
  'Sadness',
  'Surprise',
]);

const DEFAULT_SKILLS = ['Active Listening', 'Clarity'];
const SKILLS = new Set([
  'Active Listening',
  'Clarity',
  'Empathy',
]);

export const getEmotion = (emotion?: string) => {
  return (emotion && EMOTIONS.has(emotion)) ? emotion : DEFAULT_EMOTION;
};

export const getEmotions = () => Array.from(EMOTIONS);

export const getPersonality = (personality?: string) => {
  return (personality && PERSONALITIES.has(personality)) ? personality : DEFAULT_PERSONALITY;
};

export const getPersonalities = () => Array.from(PERSONALITIES);

export const getGender = (gender?: string) => {
  return (gender && GENDERS.has(toGender(gender))) ? toGender(gender) : DEFAULT_GENDER;
};

export const getGenders = () => Array.from(GENDERS);

export const getEthnicity = (ethnicity?: string) => {
  return (ethnicity && ETHNICITIES.has(toEthnicity(ethnicity))) ? toEthnicity(ethnicity) : DEFAULT_ETHNICITY;
};

export const getEthnicities = () => Array.from(ETHNICITIES);

export const getSkillSet = (skills?: string[]) => {
  if (skills && skills.length > 0 && skills.every(skill => SKILLS.has(skill))) {
    return skills;
  }

  return DEFAULT_SKILLS;
};

export const getSkills = () => Array.from(SKILLS);

