import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const HowItWorks = () => {
  return (
    <Box
      sx={{
        width: '100%',
        pt: 4,
        pb: 4,
        textAlign: 'center',
        backgroundImage: 'url(/landing/bg_science.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Container maxWidth="lg" sx={{ pt: 4 }}>
        <Typography variant="h4" component="div" sx={{ color: 'white' }}>
          How does it work?
        </Typography>
        <Grid container spacing={4} sx={{ pt: 8, pb: 12 }}>
          <Grid item xs={6} md={3}>
            <Box
              sx={{
                p: 2,
                backgroundImage: 'linear-gradient(to top right, #2D185A, #4C3095)',
                borderRadius: 4,
                border: '1px solid #7351B0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: '235px',
              }}
            >
              <img
                src="/landing/icon_step1.svg"
                alt="roleplay"
                style={{ width: '50px', height: 'auto', margin: '25px 0' }}
              />
              <Typography variant="body1" component="div" sx={{ color: 'white', pb: 4 }}>
                Pick your Roleplay AI partner and situation
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box
              sx={{
                p: 2,
                backgroundImage: 'linear-gradient(to top right, #2D185A, #4C3095)',
                borderRadius: 4,
                border: '1px solid #7351B0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img
                src="/landing/icon_step2.svg"
                alt="dynamic conversation"
                style={{ width: '50px', height: 'auto', margin: '25px 0' }}
              />
              <Typography variant="body1" component="div" sx={{ color: 'white', pb: 4 }}>
                Have your dynamic conversation with live transcription
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box
              sx={{
                p: 2,
                backgroundImage: 'linear-gradient(to top right, #2D185A, #4C3095)',
                borderRadius: 4,
                border: '1px solid #7351B0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: '235px',
              }}
            >
              <img
                src="/landing/icon_step3.svg"
                alt="get feedback"
                style={{ width: '50px', height: 'auto', margin: '25px 0' }}
              />
              <Typography variant="body1" component="div" sx={{ color: 'white', pb: 4 }}>
                Get feedback and insights on your chosen skills
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box
              sx={{
                p: 2,
                backgroundImage: 'linear-gradient(to top right, #2D185A, #4C3095)',
                borderRadius: 4,
                border: '1px solid #7351B0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: '235px',
              }}
            >
              <img
                src="/landing/icon_step4.svg"
                alt="track progress"
                style={{ width: '50px', height: 'auto', margin: '25px 0' }}
              />
              <Typography variant="body1" component="div" sx={{ color: 'white', pb: 4 }}>
                Track your progress over time with in-depth reporting
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HowItWorks;
