import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


interface TruncatedTextProps {
  text: string;
  maxLines: number;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({ text, maxLines }) => {
  const [showFullText, setShowFullText] = useState(false);
  const toggleShowFullText = () => setShowFullText(!showFullText);

  return (
    <Box>
      <Collapse in={showFullText} collapsedSize={`${maxLines * 1.5}em`}>
        <Typography variant="caption">
          {text}
        </Typography>
      </Collapse>
      <Box display="flex" mt={1}>
        <Button size="small" onClick={toggleShowFullText} endIcon={showFullText ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
          {showFullText ? 'Show less' : 'Show more'}
        </Button>
      </Box>
    </Box>
  );
};

export default TruncatedText;