import React, { useState } from 'react';
import { Avatar, MenuItem, Select } from '@mui/material';
import { AvatarDTO } from '../api/types/master-data';
import { Gender } from '../api/types/common';

const simplifyGender = (gender: Gender): Gender => {
  switch (gender) {
    case Gender.FEMALE:
      return Gender.FEMALE;

    case Gender.MALE:
      return Gender.MALE;

    default:
      return Gender.OTHER;
  }
};

const filterByGender = (avatar: AvatarDTO, gender: Gender) => {
  const asg = simplifyGender(avatar.gender);
  const bsg = simplifyGender(gender);
  switch (bsg) {
    case Gender.MALE:
    case Gender.FEMALE:
      return asg === bsg;

    default:
      return true;
  }
};

const EditAvatar = ({ avatars, avatar, gender, onChange }: {
  avatars: AvatarDTO[],
  avatar: string,
  gender: Gender,
  onChange: (avatar: string) => void,
}) => {

  const [editAvatar, setEditAvatar] = useState<string>('');
  const [filteredAvatars, setFilteredAvatars] = useState<AvatarDTO[]>([]);

  React.useEffect(() => {
    setFilteredAvatars(avatars.filter(a => a.thumbnail === avatar || filterByGender(a, gender)));
  }, [avatars]);

  React.useEffect(() => {
    setEditAvatar(avatar);
  }, [avatar]);

  return (
    <Select
      value={editAvatar}
      onChange={(e: any) => onChange(e.target.value)}
      variant="outlined">
      {filteredAvatars.map((a) => (
        <MenuItem key={a.thumbnail} value={a.thumbnail}>
          <Avatar src={a.thumbnail}
                  sx={{ width: 90, height: 90 }}
          />
        </MenuItem>
      ))}
    </Select>
  );
};

export default EditAvatar;
