import React, { useCallback, useState } from 'react';
import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import { GeneratedScenarioDTO, ScenarioDTO } from '../api/types/scenario';
import GenderWithIcon from './GenderWithIcon';
import EmotionChip from './EmotionChip';
import EditAvatar from './EditAvatar';
import api from '../api';
import { PersonasDTO } from '../api/types/master-data';
import { toGender } from '../api/types/common';

function formatString(str: String) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase().replace(/_/g, ' ');
  }
  return str;
}

export type ScenarioCardEditFields = {
  botAvatar: string
};


export const ScenarioCard = ({ scenario, onEdit }: {
  scenario: GeneratedScenarioDTO | ScenarioDTO,
  onEdit?: (data: ScenarioCardEditFields) => void,
}) => {
  const [personas, setPersonas] = useState<PersonasDTO>();

  React.useEffect(() => {
    api.getPublicPersonas().then((response) => {
      setPersonas(response);
    });
  }, []);

  const onAvatarChanged = useCallback((avatar: string) => {
    onEdit && onEdit({ botAvatar: avatar });
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', p: 2 }}>
      {onEdit && (
        <EditAvatar avatars={personas ? Object.values(personas.avatars) : []}
                    avatar={scenario.botAvatar ? scenario.botAvatar : ''}
                    gender={scenario.botGender}
                    onChange={onAvatarChanged}
        />
      )}
      {!onEdit && (
      <Avatar alt={scenario.botName} src={scenario.botAvatar ? scenario.botAvatar : ''}
              sx={{ width: 110, height: 110 }} />
        )}
      <Box sx={{ marginLeft: 2, flex: 1 }}>
        <Grid container>
          <Grid item md={12}>
            <Typography variant="h6" component="div">{scenario.botName}</Typography>

            <Box style={{ display: 'flex', alignItems: 'center', marginTop: 2,  }}>
              <GenderWithIcon genderName={formatString(scenario.botGender)} />
              <Divider orientation="vertical" flexItem
                       sx={{
                         borderWidth: '1px',
                         margin: '0 0.3rem',
                       }} />
              <Typography
                sx={{ display: 'inline' }}
                variant="body2"
                color="text.secondary"
              >
                Age: {scenario.botAge}
              </Typography>
              <Divider orientation="vertical" flexItem
                       sx={{
                         borderWidth: '1px',
                         margin: '0 0.3rem',
                       }} />
              <Typography
                sx={{ display: 'inline' }}
                variant="body2"
                color="text.secondary"
              >
                Ethnicity: {formatString(scenario.botEthnicity)}
              </Typography>
            </Box>
            <Box marginTop={0.5}>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ marginRight: '0.3rem' }}>
                Position: {scenario.botJobTitle || 'Employee'}
              </Typography>
            </Box>
            <Box marginTop={0.5}>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ marginRight: '0.3rem' }}>
                Personality: {scenario.botPersonality}
              </Typography>
            </Box>
            <Box display="flex" marginTop={0.5}>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ marginRight: '0.3rem' }}>
                Emotion:
              </Typography><EmotionChip emotion={scenario.botEmotion} />
            </Box>
          </Grid>
          {/*<Grid item md={4}>*/}
          {/*  <ScoreBox score={item.average_score} />*/}
          {/*</Grid>*/}
        </Grid>
      </Box>
    </Box>
  );
};

export default Navigator;
