import * as React from 'react';

import AppDrawer from '../components/AppDrawer';
import api from '../api';
import { ProfileForm } from '../components/ProfileForm';
import Box from '@mui/material/Box';
import { UserDetailsDTO } from '../api/types/auth';
import Grid from '@mui/material/Grid';


const Settings = () => {
  const [profile, setProfile] = React.useState<UserDetailsDTO | undefined>(undefined);

  const fetchUserProfile = async () => {

    api.getMyDetails()
      .then((p) => setProfile(p))
      .catch((err) => console.error(err));
  };

  const handleProfileSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = Object.fromEntries(new FormData(e.currentTarget));
    if (profile) {
      const updatedProfile = {
        ...profile,
        ...form,
      };

      api.updateMyDetails(updatedProfile)
        .then(() => setProfile(updatedProfile))
        .catch((err) => console.error(err));
    }
  };

  React.useEffect(() => {
    fetchUserProfile();
  }, []);

  const mainContent = (
    <div>
      {profile && (
        <Box sx={{ px: 2, py: 5 }}>
          <Grid container>
            <Grid item xs={0} lg={2}>
            </Grid>
            <Grid item xs={12} lg={8}>
              <ProfileForm
                formLabel={''}
                submitLabel={'Save Settings'}
                profile={profile}
                onSubmit={handleProfileSubmit}
              />
            </Grid>
          </Grid>
        </Box>
        )}
    </div>
  );

  return (
    <AppDrawer pageName="Settings" mainContent={mainContent} />
  );
};

export default Settings;
