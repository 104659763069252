import { useEffect, useState } from "react";
import { Alert, Box, Button, FormControl, Paper, TextField, Typography, useTheme } from "@mui/material";
import AppDrawer from "../components/AppDrawer";
import api from '../api';
import { isComplexPassword } from "../lib/utilities";
import { useSearchParams } from "react-router-dom";
import HelpOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';

export const VerifyPasswordReset = () => {
  const [ errorMessage, setErrorMessage ] = useState<string>('');
  const [ password, setPassword ] = useState<string | null>(null);
  const [ success, setSuccess ] = useState<boolean>(false);
  const [ params ] = useSearchParams();

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params.get('u') || !params.get('t')) {
      navigate('/');
    }
  }, [params]);

  useEffect(() => {
    const doPasswordChange = async (user: string, token: string, password: string) => {
      try {
        await api.validatePasswordReset(
          user,
          token,
          password
        );

        setSuccess(true);
      } catch (err) {
        setErrorMessage('There was an error resetting your password. Please contact support@mindbloom.ai');
      }
    };

    const user  = params.get('u');
    const token = params.get('t');

    if (user && token && password) {
      doPasswordChange(user, token, password);
    }
  }, [params, password]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);
    
    const passwordA = form.get('password');
    const passwordB = form.get('password-confirm');

    if (!passwordA) {
      return setErrorMessage('Please enter a password');
    }

    if (!passwordB) {
      return setErrorMessage('Please enter a password');
    }

    if (passwordA !== passwordB) {
      return setErrorMessage('Passwords do not match');
  
    }

    const meetsComplexity = isComplexPassword(passwordA.toString()).isSufficientlyComplex;
    if (!meetsComplexity) {
      return setErrorMessage('Password must contain at least 8 characters, including uppercase, lowercase, numbers and special characters');
    }

    setPassword(passwordA.toString());
  };

  const mainContent = (
    <Box
      sx={{
        position: 'absolute',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        width: '400px',
        height: 'auto'
      }}
    >
      <Paper sx={{ p: 3, borderRadius: '8px' }}>
        { !success && (
          <>
            {errorMessage && (
              <Alert severity="error" sx={{ my: '1rem' }}>{errorMessage}</Alert>
            )}
            <Typography variant="h6" sx={{ pb: 1 }}>
              Choose a new Password
            </Typography>
            <Typography variant="body2">
              Forgotten your password? We'll send you an email to create a new password.
            </Typography>
            <Box sx={{ textAlign: 'center' }}>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth sx={{ textAlign: 'center', mt: '1.5rem' }}>
                  <TextField
                    type="password"
                    autoFocus
                    id="password-input"
                    name="password"
                    label="Password"
                    error={!!errorMessage}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ textAlign: 'center', mt: '1.5rem' }}>
                  <TextField
                    type="password"
                    autoFocus
                    id="password-input"
                    name="password-confirm"
                    label="Confirm Password"
                    error={!!errorMessage}
                  />
                </FormControl>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                  sx={{
                    margin: "2rem 0"
                  }}

                >Save Password</Button>
              </form>
            </Box>
          </>
        )}
        {success && (
            <Box sx={{ textAlign: 'center' }}>
              <HelpOutlineIcon sx={{fontSize: '40px', mb: '0.5rem'}} color="primary" />
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: '0.5rem' }}>Password Changed</Typography>
              <Typography sx={{ p: 1, color: `${theme.palette.grey}` }}>
                You can now login with your new password
              </Typography>
            </Box>
          )}
      </Paper>
    </Box>
  );

  return (
    <AppDrawer pageName="" mainContent={mainContent} />
  );
}
