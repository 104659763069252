import * as React from 'react';
import Typography from '@mui/material/Typography';

const GenderIcons: Record<string, string> = {
  Male: "/gender/male.svg",
  Female: "/gender/female.svg",
  Trans: "/gender/transnonbinary.svg",
  "Non binary": "/gender/transnonbinary.svg",
  Agender: "/gender/transnonbinary.svg",
}

const GenderWithIcon = ({ genderName }: {genderName: string; }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {GenderIcons[genderName] && (
        <img
          src={GenderIcons[genderName]}
          alt={genderName}
          style={{ width: '20px', height: '20px', marginRight: '2px' }}
        />
      )}
      <Typography variant="body2" color="text.secondary">
        {genderName}
      </Typography>
    </div>
  );
};

export default GenderWithIcon;
