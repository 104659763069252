import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { trackEvent } from '../../lib/tracking';

const Hero = () => {
  const navigate = useNavigate();

  const handleCoursesClick = () => {
    trackEvent('Button', 'Click', 'Landing Hero Start for Free');
    navigate('/scenarios');
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 64px)', // Set height to viewport height
        background: `url('/landing/hero.png') center center / cover no-repeat`,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {/*<Container maxWidth="lg">*/}
        <Grid container alignItems="center">
          <Grid item sx={{ textAlign: 'left' }}>
            <Box sx={{
              pl: { md: '100px', xs: '20px' },
            }}>
              <Typography
                variant="h3"
                component="h1"
                gutterBottom
                sx={{
                  textAlign: 'left',
                  fontWeight: 'bold',
                  fontSize: {
                    xs: 'calc(1rem + 1vw)',
                    sm: 'calc(1rem + 1vw)',
                    md: 'calc(1.5rem + 1.5vw)',
                    lg: 'calc(2rem + 1.5vw)',
                    xl: 'calc(2rem + 1.5vw)'
                  }
                }}
              >
                Practice difficult<br />
                conversations with<br />
                <Box component="span" sx={{ color: '#6A31FC' }}>
                  emotionally<br />
                  intelligent AI
                </Box>
              </Typography>
              <Button variant="contained" color="primary" onClick={handleCoursesClick} sx={{ mt: 3 }}>
                Start for Free
              </Button>
            </Box>
          </Grid>
        </Grid>
      {/*</Container>*/}
    </Box>
  );
};

export default Hero;
