import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Ethnicity, Gender, toEthnicity, toEthnicityCode, toEthnicityText, toGender, toGenderCode, toGenderText } from '../api/types/common';
import { useState } from 'react';
import { UserDetailsDTO } from '../api/types/auth';
import { getEthnicities, getEthnicity, getGender, getGenders, getPersonalities, getPersonality } from '../lib/masterdata';
import React from 'react';

type ProfileFormProps = {
  formLabel?: string,
  submitLabel?: string,
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
  profile?: UserDetailsDTO,
};

type ProfileFieldError = {
  name: string;
  error: string;
}

const fields: Record<string, string> = {
  birthYear: 'Birth Year',
  company: 'Company',
  ethnicity: 'Ethnicity',
  gender: 'Gender',
  personality: 'Personality',
  jobTitle: 'Job Title',
  yearsAtCompany: 'Years at Company',
  highestEducation: 'Highest Education',
}

const asNumber = (value?: string): number | undefined => {
  if (value !== undefined && value !== null && value.trim() !== '') {
    const n = parseInt(value);
    if (!isNaN(n)) return n;
  }
  return undefined;
};

const asString = (value?: string): string | undefined => {
  return (value !== undefined && value !== null && value.trim() !== '') ? value : '';
};

const validate = (form: Record<string, FormDataEntryValue>): ProfileFieldError[] => {
  const required = [
    'birthYear',
    'company',
    'ethnicity',
    'gender',
    'personality',
    'highestEducation',
    'jobTitle',
    'yearsAtCompany',
  ];

  let errors = required.reduce((acc, key) => {
    if (!form[key]) {
      acc.push({
        name: key,
        error: `${fields[key]} is required`
      });
    }
    return acc;
  }, [] as ProfileFieldError[]);

  if (!errors.length) {

  }

  return errors;
};

const getFieldError = (errors: ProfileFieldError[], field: string) => {
  return errors.find(error => error.name === field)?.error;
};

export const ProfileForm = ({
                              formLabel = 'Complete my Settings',
                              submitLabel = "Submit my Settings",
                              onSubmit,
                              profile,
}: ProfileFormProps) => {
  const [errors, setErrors] = useState<ProfileFieldError[]>([]);
  const [birthYear, setBirthYear] = useState<number | undefined>((profile) ? profile.birthYear : undefined);
  const [gender, setGender] = useState<Gender>(getGender(profile ? profile.gender : undefined));
  const [ethnicity, setEthnicity] = useState<Ethnicity>(getEthnicity(profile ? profile.ethnicity : undefined));
  const [company, setCompany] = useState<string | undefined>(profile ? profile.company : undefined);
  const [jobTitle, setJobTitle] = useState<string | undefined>(profile ? profile.jobTitle : undefined);
  const [yearsAtCompany, setYearsAtCompany] = useState<number | undefined>(profile ? profile.yearsAtCompany : undefined);
  const [highestEducation, setHighestEducation] = useState<string | undefined>(profile ? profile.highestEducation : undefined);
  const [personality, setPersonality] = useState<string>(getPersonality(profile ? profile.personality : undefined));


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const values = Object.fromEntries(new FormData(e.currentTarget))
    const errors = validate(values);
    if (!errors.length) {
      onSubmit(e);
    } else {
      setErrors(errors);
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit}>
      <Box sx={{ mb: '2rem' }}>
        <Typography variant="h6" sx={{
          mb: '0.5rem'
        }}>
          {formLabel}
        </Typography>
        <Typography variant="body2">
          Please fill the form below so that we can build a better conversation experience.
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            name="birthYear"
            label="Birth Year"
            value={birthYear}
            type="number"
            helperText={getFieldError(errors, 'birthYear')}
            error={!!getFieldError(errors, 'birthYear')}
            onChange={(e) => setBirthYear(asNumber(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            required
            name="gender"
            label="Gender"
            value={gender}
            onChange={(e) => setGender(toGender(e.target.value))}
          >
            {getGenders().map((g: Gender) => (
              <MenuItem key={toGenderCode(g)} value={g}>{toGenderText(g)}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            required
            name="ethnicity"
            label="Ethnicity"
            value={ethnicity}
            onChange={(e) => setEthnicity(toEthnicity(e.target.value))}
          >
            {getEthnicities().map((e: Ethnicity) => (
              <MenuItem key={toEthnicityCode(e)} value={e}>{toEthnicityText(e)}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            name="company"
            label="Company"
            value={company}
            type="text"
            helperText={getFieldError(errors, 'company')}
            error={!!getFieldError(errors, 'company')}
            onChange={(e) => setCompany(asString(e.target.value))}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            fullWidth
            required
            name="jobTitle"
            label="Job Title"
            value={jobTitle}
            helperText={getFieldError(errors, 'jobTitle')}
            error={!!getFieldError(errors, 'jobTitle')}
            onChange={(e) => setJobTitle(asString(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            name="yearsAtCompany"
            label="Years at Company"
            value={yearsAtCompany}
            type="number"
            helperText={getFieldError(errors, 'yearsAtCompany')}
            error={!!getFieldError(errors, 'yearsAtCompany')}
            onChange={(e) => setYearsAtCompany(asNumber(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            name="highestEducation"
            label="Highest Education"
            value={highestEducation}
            helperText={getFieldError(errors, 'highestEducation')}
            error={!!getFieldError(errors, 'highestEducation')}
            onChange={(e) => setHighestEducation(asString(e.target.value))}
          />
        </Grid>
        <Grid item sm={12}>
          <Select
            fullWidth
            name="personality"
            label="Personality"
            value={personality}
            onChange={(e) => setPersonality(e.target.value)}
          >
            {getPersonalities().map((p) => (
              <MenuItem key={p} value={p}>{p}</MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ height: '48px', mt: '2rem' }}
      >
        {submitLabel}
      </Button>
    </Box>
  );
};
