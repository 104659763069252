import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const renderTextWithEmailLinks = (text: string) => {
  const email = 'admin@mindbloom.ai';
  const parts = text.split(email);

  return (
    <>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {index < parts.length - 1 && (
            <a href={`mailto:${email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              {email}
            </a>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const renderTextWithLinks = (text: string) => {
  const urlPattern = /(\bhttps?:\/\/[^\s]+\b)/gi;
  const match = text.match(urlPattern);

  if (match) {
    return (
      <a href={match[0]} key={match[0]} target="_blank" rel="noopener noreferrer">
        {text.replace(urlPattern, '').trim()}
      </a>
    );
  }
  return text;
};

const FAQ = () => {
  const faqs = [
    {
      question: 'What is Mindbloom?',
      answer: [
        'Mindbloom is an emotionally intelligent AI to practice difficult conversations before they happen. Speak in real-time with a better than human coach that gives you feedback on a variety of soft skills.',
      ],
    },
    {
      question: 'How does it work?',
      answer: [
        'We use natural voice/in out coupled with fine-tuned large language models to help build confidence, give you privacy to practice high impact conversations, and highlight areas for improvement. Mindbloom can digest your entire training history and give personalised recommendations, as well as suggesting improvements on an individual sentence basis.',
      ],
    },
    {
      question: 'What happens to my data?',
      answer: [
        'Mindbloom uses depersonalized, aggregated data in order to train our models, but that data is never associated with you, or sold. Enterprise clients have the option of creating a private model.',
      ],
    },
    {
      question: 'Can I make my own scenario?',
      answer: [
        'Not yet. We are developing a module to let you make your own scenario which will be available soon. In the meantime, if there are scenarios you would like to see, please submit them to admin@mindbloom.ai',
      ],
    },
    {
      question: 'Can I use Mindbloom at my company?',
      answer: [
        'You can use Mindbloom as-is right now as an individual. To discuss an enterprise plan, please contact the team at admin@mindbloom.ai',
      ],
    },
    {
      question: 'Can I request a new feature?',
      answer: [
        'Please do! If you have any feedback at all, please send to the team at admin@mindbloom.ai',
      ],
    },
    {
      question: 'How do I know it works?',
      answer: [
        'Mindbloom is based on research demonstrating the power of emotive intelligence in management. We offer routine practice at any time to let you train your EI whenever you need. A collection of resources discussing this are below:',
        'The Business Case for Emotional Intelligence https://www.eiconsortium.org/reports/business_case_for_ei.html',
        'Empathetic leadership cross culturally https://www.sciencedirect.com/science/article/abs/pii/S104898431100107X?via%3Dihub',
        'EY report on empathy in workplaces https://www.ey.com/en_us/newsroom/2023/03/new-ey-us-consulting-study',
        'Repeated EI training outperforms intensive training sessions https://www.researchgate.net/profile/Anthony-Grant-4/publication/235278966_Enhancing_coach_skills_and_emotional_intelligence_through_training/links/54f510390cf2f28c1363070c/Enhancing-coach-skills-and-emotional-intelligence-through-training.pdf',
      ],
    },
    {
      question: 'What does Mindbloom solve?',
      answer: [
        'There is a huge training and practice gap. Managers are expected to handle difficult, high impact conversations like performance reviews, workplace disputes and redundancies with little to no practice. Training usually takes the form of expensive roleplay with in-person actors (costing between £650-£1350/day in the UK). Mindbloom can solve this by providing a practice environment that is available whenever, and at scale.',
        'Need to go over your points for a tricky set of meetings next week? Mindbloom can not only be a roleplay partner, but suggest how you can improve and deliver key emotive points more effectively.',
        'Want to upskill on DEI topics, without the risk of offending someone or misstepping? Mindbloom will tirelessly practice with you, confidentially and kindly.',
        'Want to test out a line of questioning for a topic you’re unsure of? Mindbloom will run through it in various different configurations until you’re confident you can tackle whatever comes up.',
      ],
    },
    {
      question: 'Is each conversation unique?',
      answer: [
        'Yes they are. Our AI is adaptive to your questions and responsive, not just a series of pre-programmed responses. Every time you enter a scenario the conversation will be different, so you can keep choosing the same one and learn something new every time.',
      ],
    },
  ];
  return (
    <Container id="faq" maxWidth="lg" sx={{ pt: 8, textAlign: 'center', pb: 4 }}>
      <Typography variant="h4" component="div">
        Frequently asked questions
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{ color: '#696969', pt: 1 }}>
        Everything you need to know about Mindbloom
      </Typography>
      <Grid container sx={{ textAlign: 'left' }}>
        <Grid item xs={12} md={6} sx={{ ml: '-20px' }}>
          <Container maxWidth="md" sx={{ marginTop: 8 }}>
            {faqs.map((faq, index) => (
              <Accordion key={index} sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <Typography variant="h6">{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div" sx={{ color: '#696969' }}>
                    {faq.answer.map((paragraph, index) => (
                      <Typography
                        key={index}
                        component="div"
                        paragraph
                      >
                        {paragraph.includes('admin@mindbloom.ai')
                          ? renderTextWithEmailLinks(paragraph)
                          : renderTextWithLinks(paragraph)}
                      </Typography>
                    ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src="/landing/faq.png" alt="FAQ" style={{ maxWidth: '100%', height: 'auto' }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FAQ;
