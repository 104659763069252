import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface ChromeNoAirpodsAlertInterface {
  open: boolean;
  handleClose: () => void;
}

const ChromeNoAirpodsAlert: React.FC<ChromeNoAirpodsAlertInterface> = ({ open, handleClose }) => {
  const [showAlert, setShowAlert] = useState(open);

  useEffect(() => {
    const dontShowAgain = Cookies.get('dontShowChromeNoAirpodsAlert');
    if (dontShowAgain === 'true') {
      setShowAlert(false);
    } else {
      setShowAlert(true);
    }
  }, [open]);

  const handleDontShowAgain = () => {
    Cookies.set('dontShowChromeNoAirpodsAlert', 'true', { expires: 30 });
    setShowAlert(false);
    handleClose();
  };

  return (
    <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}
             action={ <CloseIcon onClick={handleDontShowAgain} /> }
      >
        You are using Chrome, so AirPods may not work. Please use speaker instead.
      </Alert>
    </Snackbar>
  );
};

export default ChromeNoAirpodsAlert;
