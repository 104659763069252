import * as React from 'react';

import Box from '@mui/material/Box';

import AppDrawer from '../components/AppDrawer';
import ReportDetailedTable from '../components/ReportDetailedTable';

function Reports() {

  const mainContent = (
    <>
      <Box sx={{ pl: 4, pr: 4, pb: 4 }}>
        <ReportDetailedTable />
      </Box>
    </>
  );

  return (
    <AppDrawer pageName="Reports" mainContent={mainContent} />
  );
}

export default Reports;