import * as React from 'react';
import { FormEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import { buildConvPassthruUrl, isComplexPassword } from '../lib/utilities';

type SignUpFormProps = {
  submitLabel?: string,
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
};

export function SignUpForm({ onSubmit, submitLabel = 'Create Account' }: SignUpFormProps) {
  const [usernameHelperText, setUsernameHelperText] = useState<string>('');
  const [firstNameHelperText, setFirstNameHelperText] = useState<string>('');
  const [lastNameHelperText, setLastNameHelperText] = useState<string>('');
  const [emailHelperText, setEmailHelperText] = useState<string>('');
  const [passwordHelperText, setPasswordHelperText] = useState<string>('');
  const [passwordAgainHelperText, setPasswordAgainHelperText] = useState<string>('');
  const { conversationUuid = '', correlationUuid = undefined } = useParams();

  const navigate = useNavigate();

  const validate = (form: Record<string, FormDataEntryValue>) => {
    let isValid = true;
    if (isEmpty(form.username)) {
      setUsernameHelperText('Please enter your username');
      isValid = false;
    }

    if (isEmpty(form.firstName)) {
      setFirstNameHelperText('Please enter your first name');
      isValid = false;
    }

    if (isEmpty(form.lastName)) {
      setLastNameHelperText('Please enter your last name');
      isValid = false;
    }

    if (isEmpty(form.email)) {
      setEmailHelperText('Please enter your email address');
      isValid = false;
    }

    if (isEmpty(form.password)) {
      setPasswordHelperText('Please enter your password');
      isValid = false;
    } else {
      const pwc = isComplexPassword(form.password.toString());

      if (!pwc.isSufficientlyComplex) {
        setPasswordHelperText('Must contain at least 8 characters, including uppercase, lowercase, numbers and special characters');
        isValid = false;
      }
    }

    if (isEmpty(form.passwordAgain)) {
      setPasswordAgainHelperText('Please enter your password again');
      isValid = false;
    }

    if (form.password !== form.passwordAgain) {
      setPasswordAgainHelperText('Passwords do not match');
      isValid = false;
    }

    return isValid;
  };

  const handleLoginClick = () => {
    navigate(buildConvPassthruUrl('/login', conversationUuid, correlationUuid), { state: { next: `/report/${conversationUuid}` } });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
   
    const valid = validate(
      Object.fromEntries(new FormData(e.currentTarget))
    );
    
    if (valid) {
      onSubmit(e);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h6" sx={{ pb: 1 }}>
        Sign up
      </Typography>
      <Typography variant="body2">
        Please sign up to join the conversation
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="username"
              label="Username"
              autoFocus
              name="username"
              helperText={usernameHelperText}
              error={!!usernameHelperText}
              InputLabelProps={{ required: false }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="firstName"
              label="First name"
              name="firstName"
              helperText={firstNameHelperText}
              error={!!firstNameHelperText}
              InputLabelProps={{ required: false }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="lastName"
              label="Last name"
              name="lastName"
              helperText={lastNameHelperText}
              error={!!lastNameHelperText}
              InputLabelProps={{ required: false }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email address"
              name="email"
              helperText={emailHelperText}
              error={!!emailHelperText}
              InputLabelProps={{ required: false }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              helperText={passwordHelperText}
              error={!!passwordHelperText}
              InputLabelProps={{ required: false }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              name="passwordAgain"
              label="Password again"
              type="password"
              id="password-again"
              autoComplete="password-again"
              helperText={passwordAgainHelperText}
              error={!!passwordAgainHelperText}
              InputLabelProps={{ required: false }}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ height: '48px', my: '2rem' }}
        >
          {submitLabel}
        </Button>
        <Grid container justifyContent="center">
          <Grid item>
            <Link
              component="span"
              onClick={handleLoginClick}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              variant="body2"
            >
              Already have an account? Log in
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
