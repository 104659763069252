import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConversationDTO } from '../api/types/conversation';

interface ConversationState {
  conversation: ConversationDTO | null;
}

const initialState: ConversationState = {
  conversation: null,
};

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setConversation(state, action: PayloadAction<any>) {
      state.conversation = action.payload;
    },
  },
});

export const { setConversation } = conversationSlice.actions;
export default conversationSlice.reducer;
