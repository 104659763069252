import { Ethnicity, Gender } from "./common";

export enum ConversationSortBy {
  CREATED_AT_ASC,
  CREATED_AT_DESC
};

export type ConversationSkillScoreDTO = {
  skill: string;
  score: number;
  strengths: string;
  weaknesses: string;
  improvementAreas: string;
  createdAt: Date;
};

type ConversationAttrs = {
  brief: string;
  candidateName: string;
  candidatePersonality: string;
  candidateGender: Gender;
  candidateEthnicity: Ethnicity;
  botName: string;
  botEmotion: string;
  botPersonality: string;
  botGender: Gender;
  botEthnicity: Ethnicity;
  botAvatar: string,
  botVoice: string,
  scoringMetrics: string[];
  scenario?: string;
  isCompleted?: boolean;
  relationship?: string;      // The relationship between the bot and candidate.
  prologue?: string;          // The context that drives the conversation.
  botInnerThoughts?: string;  // The inner thoughts of the bot.
  botBackstory?: string;      // The backstory of the bot.
  candidateBackstory?: string; // The backstory of the candidate.
};

export type ConversationSearchFilterDTO = {
  users?: string[]
  scenarios?: string[]
};

export type ConversationResponseDTO = {
  isBot: boolean;
  uuid: string;
  isSeed: boolean;
  statement: string;
  inferredEmotion?: boolean;
  anger?: number;
  fear?: number;
  sadness?: number;
  happiness?: number;
  indignation?: number;
  surprise?: number;
  anticipation?: number;
  processedSkills?: boolean;
  empathy?: number;
  empathyReasoning?: string;
  clarity?: number;
  clarityReasoning?: string;
  activeListening?: number;
  activeListeningReasoning?: string;
  createdAt: Date;
  lastModifiedAt?: Date;
};

export type CreateConversationDTO = ConversationAttrs & {
  uuid?: string;
  seedResponses? : ConversationResponseDTO[];
};

export type ConversationDTO = ConversationAttrs & {
  uuid: string;
  user: string;
  responses? : ConversationResponseDTO[];
  skills?: ConversationSkillScoreDTO[];
  createdAt: Date;
  isCompleted: boolean;
  lastModifiedAt?: string;
};

export type ConversationWithCorrelationDTO = ConversationDTO & {
  correlationUuid: string;
};

export type ConversationSummaryDTO = ConversationAttrs & {
  uuid: string;
  user: string;
  createdAt: Date;
  lastModifiedAt?: Date;
  skillScores: {[key:string]: number};
};

export type CreateConversationFromScenarioDTO = {
  uuid?: string;
  candidateName: string;
  candidatePersonality: string;
  candidateGender: Gender;
  candidateEthnicity: Ethnicity;
};
