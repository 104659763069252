import { Box, LinearProgress, Typography  } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { linearProgressClasses } from '@mui/material/LinearProgress';


import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';

// Yuck.
const BorderLinearProgress = styled(LinearProgress)(
  ({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#6A31FC',
      ...theme.applyStyles('dark', {
        backgroundColor: '#6A31FC',
      }),
    },
  })
);

type ProgressIndicatorProps = {
  projectedTimeMs: number;
  intervalMs: number;
  isComplete: boolean;
  message?: string;
};

export const ProgressIndicator = ({
  projectedTimeMs,
  intervalMs = 100,
  isComplete = false,
  message = 'We are finalising the conversation...',
}: ProgressIndicatorProps) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const totalSteps = projectedTimeMs / intervalMs;
    const increment = 100 / totalSteps;
    let timer: NodeJS.Timeout;

    if (!isComplete) {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = Math.min(oldProgress + increment, 100);
          if (newProgress === 100) {
            clearInterval(timer);
          }
          return newProgress;
        });
      }, intervalMs);
    } else {
      setProgress(100);
    }

    return () => clearInterval(timer);
  }, [projectedTimeMs, intervalMs, isComplete]);

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <Box sx={{ p: 2, textAlign: 'center', fontFamily: 'Inter, sans-serif', margin: '2rem' }}>
        <Box sx={{ mb: 3 }}>
          <HourglassBottomRoundedIcon 
            sx={{ 
              width: 32, 
              height: 32, 
              color: '#6A31FC' 
            }} 
          />
        </Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold'}}>Please Wait</Typography>
        <Typography sx={{ color: '#555555', mb: 3 }}>
          {message}
        </Typography>
        <Box sx={{ width: '65%', margin: '1.5rem auto' }}>
          <BorderLinearProgress variant="determinate" value={progress} />
        </Box>
      </Box>
    </Dialog>
  );
};
