import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AppDrawer from '../components/AppDrawer';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import Chart from '../components/Chart';
import ReportConversationDrawer from '../components/ReportConversationDrawer';
import api from '../api';
import { ConversationDTO } from '../api/types/conversation';
import { ScenarioDTO } from '../api/types/scenario';
import { toDateString } from '../lib/utilities';

interface CircularProgressProps {
  value: number;
  title: string;
}

const SkillColors: Record<string, { dark: string; light: string }> = {
  Empathy: {
    dark: '#F16BC9',
    light: '#FAC6EA',
  },
  Clarity: {
    dark: '#45C188',
    light: '#B7E7D1',
  },
  'Active Listening': {
    dark: '#F59E0B',
    light: '#FCE1B3',
  },
  Leadership: {
    dark: '#2970FF',
    light: '#BDD3FF',
  },
  Tolerance: {
    dark: '#AE7008',
    light: '#F8BE5C',
  },
  Patience: {
    dark: '#6038E1',
    light: '#D1C3FD',
  },
  Negotiation: {
    dark: '#03CEC0',
    light: '#B1F0EB',
  },
};
const CircularProgressWithText: React.FC<CircularProgressProps> = ({ value, title }) => {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {/* Actual CircularProgress component */}
      <CircularProgress
        variant="determinate"
        value={value}
        size={110}
        thickness={8}
        sx={{
          color: SkillColors[title].dark,
          '& circle': {
            strokeLinecap: 'round', // Rounded endpoints
          },
        }}
      />
      {/* Background CircularProgress component */}
      <CircularProgress
        variant="determinate"
        value={100}
        size={96}
        thickness={4}
        style={{
          position: 'absolute',
          zIndex: -1,
          top: 'calc(50% - 4px)',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: SkillColors[title].light,
        }}
      />
      {/* Text components */}
      <Typography
        variant="caption"
        component="div"
        sx={{
          fontWeight: 'bold',
          lineHeight: '1', // Adjust this value as needed
          textAlign: 'center', // Center text horizontally
          display: 'flex', // Enable flex layout
          alignItems: 'center', // Center text vertically
        }}
        style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}
      >
        {title}
      </Typography>
      <Typography
        variant="h6"
        component="div"
        color="textPrimary"
        sx={{
          fontWeight: 'bold',
        }}
        style={{ position: 'absolute', top: '58%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}
      >
        {Math.round(value)}
      </Typography>
    </div>
  );
};

function ReportContent({ item, scenario }: { item: ConversationDTO; scenario: ScenarioDTO | null }) {
  let scoreString = 'N/A';
  if (item.skills && item.responses && item.responses.length > 0) {
    if (item.skills.length > 0) {
      let totalScore = 0;
      for (const skill of item.skills) {
        totalScore += skill.score;
      }
      const averageScore = totalScore / item.skills.length;
      scoreString = averageScore.toString();
    } else {
      console.error('No skills available to calculate average score with.');
    }
  }
  let timeString: string = 'N/A';
  if (item.responses && item.responses.length > 0) {
    const lastItem = item.responses[item.responses.length - 1];
    const lastItemTimestamp = new Date(lastItem.createdAt);
    // Calculate the difference in milliseconds
    const differenceInMilliseconds = lastItemTimestamp.getTime() - item.createdAt.getTime();
    // Convert the difference from milliseconds to minutes
    if (differenceInMilliseconds < (1000 * 60)) { // If less than one minute
      // Convert the difference from milliseconds to seconds
      const differenceInSeconds = Math.ceil(differenceInMilliseconds / 1000);
      timeString = `${differenceInSeconds} seconds`;
    } else {
      // Convert the difference from milliseconds to minutes
      const differenceInMinutes = Math.ceil(differenceInMilliseconds / (1000 * 60));
      timeString = `${differenceInMinutes} minutes`;
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', px: { md: 4, sm: 1, xs: 1 }, pt: { md: 4, sm: 1, xs: 1 }, pb: 4 }}>
        <Avatar alt={`Avatar ${item.botName}`} src={item.botAvatar} sx={{ width: 55, height: 55, marginRight: 2 }} />
        <Box sx={{ flex: 1 }}>

          <Grid container>
            <Grid item lg={2} md={12} xs={12}>
              <Typography variant="h6" component="div">{item.botName}</Typography>
              {/*<Typography variant="body2" color="textSecondary">{scenario?.candidateJobTitle}</Typography>*/}
              <Typography variant="body2" color="textSecondary">{"Employee"}</Typography>
            </Grid>
            <Grid item lg={2} md={4} xs={4}>
              <Typography variant="overline" display="block">Score</Typography>
              <Typography variant="body2">{scoreString}</Typography>
            </Grid>
            <Grid item lg={2} md={4} xs={4}>
              <Typography variant="overline" display="block">Time</Typography>
              <Typography variant="body2">{timeString}</Typography>
            </Grid>
            <Grid item lg={2} md={4} xs={4}>
              <Typography variant="overline" display="block">Emotion</Typography>
              <Typography variant="body2">{item.botEmotion}</Typography>
            </Grid>
            <Grid item lg={2} md={6}>
              <Box sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '8px',
                padding: '8px',
                display: 'inline-block',
                mt: 1,
              }}><Typography variant="body2">{toDateString(item.createdAt)}</Typography></Box>
            </Grid>
            <Grid item lg={2}>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ px: { md: 4, sm: 1, xs: 1 } }}>
        <Typography variant="body1" fontWeight="bold" sx={{ pb: 1 }}>Backstory</Typography>
        <Typography variant="body2">{item.brief}</Typography>
      </Box>
      {item.prologue ? (
        <Box sx={{ px: { md: 4, sm: 1, xs: 1 }, py: 1 }}>
          <Typography variant="body1" fontWeight="bold" sx={{ pb: 1 }}>Prologue</Typography>
          <Typography variant="body2">{item.prologue}</Typography>
        </Box>
      ) : null}

      {item.relationship ? (
        <Box sx={{ px: { md: 4, sm: 1, xs: 1 }, py: 1 }}>
          <Typography variant="body1" fontWeight="bold" sx={{ pb: 1 }}>Relationship</Typography>
          <Typography variant="body2">{item.relationship}</Typography>
        </Box>
      ) : null}

      <Box sx={{ px: { md: 4, sm: 1, xs: 1 }, py: 2 }}>
        <Chart label1={item.scoringMetrics[0]} color1={SkillColors[item.scoringMetrics[0]].dark}
               label2={item.scoringMetrics[1]} color2={SkillColors[item.scoringMetrics[1]].dark}
               rawdata={item}
        />
      </Box>
      <Grid container>
        {item.responses && item.responses.length > 0 ? (
          <>
            {item.skills && item.skills[0] && (
              <Grid item lg={6} sx={{ pt: 4 }}>
                <Grid container>
                  <Grid container item lg={3} justifyContent="center" alignItems="flex-start">
                    <CircularProgressWithText value={item.skills[0].score} title={item.skills[0].skill} />
                  </Grid>
                  <Grid item lg={9}>
                    <Typography variant="overline" display="block" gutterBottom>
                      Strengths
                    </Typography>
                    <Typography variant="body2">
                      {item.skills[0].strengths}
                    </Typography>
                    <Typography variant="overline" display="block" sx={{ pt: 4 }} gutterBottom>
                      Areas for improvement
                    </Typography>
                    <Typography variant="body2">
                      {item.skills[0].weaknesses}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {item.skills && item.skills[1] && (
              <Grid item lg={6} sx={{ pt: 4 }}>
                <Grid container>
                  <Grid container item lg={3} justifyContent="center" alignItems="flex-start">
                    <CircularProgressWithText value={item.skills[1].score} title={item.skills[1].skill} />
                  </Grid>
                  <Grid item lg={9}>
                    <Typography variant="overline" display="block" gutterBottom>
                      Strengths
                    </Typography>
                    <Typography variant="body2">
                      {item.skills[1].strengths}
                    </Typography>
                    <Typography variant="overline" display="block" sx={{ pt: 4 }} gutterBottom>
                      Areas for improvement
                    </Typography>
                    <Typography variant="body2">
                      {item.skills[1].weaknesses}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Grid container item lg={12} justifyContent="center" alignItems="flex-start" sx={{ mb: 4 }}>
            <Typography variant="body2">
              Insufficient conversation for scoring
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}

function Report() {
  const navigate = useNavigate();
  const [convWithReport, setConvWithReport] = React.useState<ConversationDTO | null>(null);
  const [scenario, setScenario] = React.useState<ScenarioDTO | null>(null);
  const { uuid = '' } = useParams();
  React.useEffect(() => {
    async function fetchData() {
      try {
        const convWithReport = await api.getConversation(uuid);
        
        // remove any seed conversations from display
        if (convWithReport.responses) {
          convWithReport.responses = convWithReport.responses.filter(response => !response.isSeed);
        }

        if (convWithReport.scenario) {
          const scenario = await api.getScenario(convWithReport.scenario);
          setScenario(scenario);
        }
        setConvWithReport(convWithReport);
      } catch (error) {
        console.error('Error fetching conversation:', error);
        navigate('/scenarios');
      }
    }

    if (uuid) {
      fetchData();
    }
  }, [uuid, navigate]);

  const mainContent = (
    <>
      {convWithReport && <ReportConversationDrawer report={convWithReport} reportContent={<ReportContent item={convWithReport} scenario={scenario} />} />}
    </>
  );

  return (
    <AppDrawer pageName="Report" mainContent={mainContent} />
  );
}

export default Report;
