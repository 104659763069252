import React from 'react';
import { Container, Typography, Grid, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../../lib/tracking';

const GoodManager = () => {
  const navigate = useNavigate();

  const handleTryForFree = () => {
    trackEvent('Button', 'Click', 'Landing Good Manager Try for Free');
    navigate('/scenarios');
  };
  return (
    <Container maxWidth="lg" sx={{ pt: 4, mt: 8, mb: 20, textAlign: 'center' }}>
      <Box
        sx={{
          p: 2,
          background: 'linear-gradient(to right, #3F2592, #6B3FF8)',
          borderRadius: 8,
          border: '1px solid #7351B0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} md={12} sx={{ mt: 5, mb: 4 }}>
            <Typography variant="h4" component="h2" gutterBottom color="common.white">
              Do you think you are a good manager?
            </Typography>
            <Typography variant="body1" color="#CEB0FA" component="h2" gutterBottom>
              Test your skills here
            </Typography>
            <Button
              sx={{
                backgroundColor: 'white',
                color: 'primary.main',
                mt: 3,
                mb: 2,
                ':hover': {
                  bgcolor: '#CEB0FA',
                  color: 'primary.dark',
                },
              }}
              variant="contained"
              onClick={handleTryForFree}
            >
              Try for Free
            </Button>

            <Typography variant="caption" color="#EFE6FD" component="h2" gutterBottom>
              No credit card required
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default GoodManager;
