import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CharactersIcon from '../components/icons/CharactersIcon';
import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AppDrawer from '../components/AppDrawer';
import StartConversationDialog from '../components/StartConversationDialog';
import EmotionChip from '../components/EmotionChip';
import SkillWithIcon from '../components/SkillWithIcon';
import ReportTable from '../components/ReportTable';

import api from '../api';
import { SkillDTO } from '../api/types/skill';
import { GeneratedScenarioDTO, ScenarioDTO, ScenarioGroupDTO, ScenarioPrivilege, ScenarioSummaryDTO } from '../api/types/scenario';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { trackEvent } from '../lib/tracking';
import { sortArray } from '../lib/utilities';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { GenerateCustomScenario } from '../components/GenerateCustomScenario';
import { ReviewCustomScenario } from '../components/ReviewCustomScenario';
import { ScenarioCard } from '../components/ScenarioCard';
import { ScenarioDetails } from '../components/ScenarioDetails';
import { Feature, hasFeature } from '../lib/features';
import { EditCustomScenario } from '../components/EditCustomScenario';
import Cookies from 'js-cookie';

const DEFAULT_CANDIDATE_PERSONALITY = 'Extrovert';

function formatString(str: String) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase().replace(/_/g, ' ');
  }
  return str;
}

interface CharacterCardProps {
  item: ScenarioSummaryDTO;
  isSmallScreen: boolean;
}

const CharacterCard: React.FC<CharacterCardProps> = ({ item, isSmallScreen }) => {
  const loggedIn = Cookies.get('isAuthenticated') === 'true';
  const isPublicScenario = item.privilege === ScenarioPrivilege.PUBLIC;
  const auth = useSelector((state: RootState) => state.auth);
  const isEditSupported = hasFeature(Feature.EDIT_DYNAMIC_SCENARIOS, auth.features);

  return (
    <>
      <Box sx={{ p: 2 }}>
        <ScenarioCard scenario={item} />
        <StartConversationDialog scenario={item} />
        <ScenarioDetails scenario={item} userName={auth.profile?.firstName + ' ' + auth.profile?.lastName} showBackstory={isPublicScenario || isEditSupported} />
        {loggedIn && !isSmallScreen ? (
          <Box sx={{ p: 2 }}>
            <ReportTable scenarioUuid={item.uuid} />
          </Box>
        ) : null}
      </Box>
    </>
  );
};

function Scenarios() {
  const navigate = useNavigate();
  const appBarHeight = 64;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const auth = useSelector((state: RootState) => state.auth);

  const [showList, setShowList] = React.useState(!isSmallScreen);
  const [allowDynamicScenarios,setAllowDynamicScenarios] = React.useState(false);
  const [allowDynamicScenarioEditing, setAllowDynamicScenarioEditing] =  React.useState(false);
  const [publicSkills, setPublicSkills] = React.useState<SkillDTO[]>([]);
  const [publicScenarios, setPublicScenarios] = React.useState<ScenarioDTO[]>([]);
  const [selectedItem, setSelectedItem] = React.useState<ScenarioSummaryDTO | null>(null);
  const [myScenarios, setMyScenarios] = React.useState<ScenarioSummaryDTO[]>([]);
  const [groupScenarios, setGroupScenarios] = React.useState<ScenarioGroupDTO[]>([]);

  const loggedIn = Cookies.get('isAuthenticated') === 'true';
  const [generateOpen, setGenerateOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [generatedScenario, setGeneratedScenario] = React.useState<GeneratedScenarioDTO | null>(null);

  const generateKey = (pre: string, index1: number, index2?: number) => {
    return (index2 !== undefined) ? `${pre}_${index1}_${index2}` : `${pre}_${index1}`;
  }

  const fetchMyScenarios = async (): Promise<ScenarioSummaryDTO[]> => {
    return api.getMyScenarios().then((myScenarios) => {
      const scenarios = sortArray(myScenarios, 'name');
      setMyScenarios(scenarios);
      return scenarios;
    }).catch((error) => {
      console.error('Error fetching my scenarios:', error);
      setMyScenarios([]);
      return [];
    });
  };

  const fetchGroupScenarios = async (): Promise<ScenarioGroupDTO[]> => {
    return api.getGroupScenarios().then((groupScenarios) => {
      const scenarios = groupScenarios.map((group) => {
        return {
          uuid: group.uuid,
          label: group.label,
          scenarios: sortArray(group.scenarios, 'name')
        };
      });
      setGroupScenarios(scenarios);
      return scenarios;
    }).catch((error) => {
      console.error('Error fetching my scenarios:', error);
      setGroupScenarios([]);
      return [];
    });
  };


  const fetchPublicScenarios = async (): Promise<ScenarioDTO[]> => {
    return api.getPublicScenarios().then((fetchedScenarios) => {
      const scenarios = sortArray(fetchedScenarios, 'name');
      setPublicScenarios(scenarios);
      return scenarios;
    }).catch((error) => {
      console.error('Error fetching scenarios:', error);
      setPublicScenarios([]);
      return [];
    });
  };


  const fetchPublicSkills = async (): Promise<SkillDTO[]> => {
    return api.getPublicSkills().then((skills) => {
      setPublicSkills(skills);
      return skills;
    }).catch((error) => {
      console.error('Error fetching skills:', error);
      setPublicSkills([]);
      return [];
    });
  };

  const fetchPublicScenariosAndSkills = async () => {
    return Promise.all([fetchPublicScenarios(), fetchPublicSkills()]).then(([scenarios, _]) => {
      if (scenarios.length > 0) {
        const index = Math.floor(Math.random() * scenarios.length);
        setSelectedItem(scenarios[index]);
//        updateSelectedScenario(scenarios[0].name);
      }
    });
  };

  React.useEffect(() => {
    (async () => {
      try {
        await fetchPublicScenariosAndSkills();
        if (loggedIn) {
          await fetchMyScenarios();
          await fetchGroupScenarios();
        }
        setAllowDynamicScenarios(hasFeature(Feature.DYNAMIC_SCENARIOS, auth.features));
        setAllowDynamicScenarioEditing(hasFeature(Feature.EDIT_DYNAMIC_SCENARIOS, auth.features));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    })();
  }, [auth.features, loggedIn]);

  const mapMetricsToSkills = (metrics: string[]): string[] => {
    return metrics.map(metric => publicSkills.find(skill => skill.name === metric))
      .map(skill => skill?.uuid)
      .filter(uuid => uuid !== undefined) as string[];
  };

  const createScenario = async (gs: GeneratedScenarioDTO): Promise<ScenarioDTO> => {
    return api.createScenario({
      name: gs.name,
      objective: gs.objective,
      scoringMetrics: gs.scoringMetrics,
      botEmotion: gs.botEmotion,
      botPersonality: gs.botPersonality,
      botGender: gs.botGender,
      botEthnicity: gs.botEthnicity,
      botEducation: gs.botEducation,
      botTenureYears: gs.botTenureYears,
      botJobTitle: gs.botJobTitle,
      botAge: gs.botAge,
      botAvatar: gs.botAvatar,
      botVoice: gs.botVoice,
      candidateGender: gs.candidateGender,
      candidateEthnicity: gs.candidateEthnicity,
      candidateName: '@CANDIDATE_NAME@',
      brief: gs.brief,
      botName: gs.botName,
      skills: mapMetricsToSkills(gs.scoringMetrics),
      seedResponses: gs.seedResponses,
      privilege: ScenarioPrivilege.USER,
      group: 'U',
      candidateEducation: gs.candidateEducation,
      candidateTenureYears: gs.candidateTenureYears,
      candidateJobTitle: gs.candidateJobTitle,
      candidateAge: gs.candidateAge
    }).then(async (scenario) => {
      await fetchMyScenarios();
      return scenario;
    });
  };

  const updateScenario = async (s: ScenarioDTO): Promise<ScenarioDTO> => {
    return api.updateScenario(s.uuid, {
      name: s.name,
      objective: s.objective,
      scoringMetrics: s.scoringMetrics,
      botEmotion: s.botEmotion,
      botPersonality: s.botPersonality,
      botGender: s.botGender,
      botEthnicity: s.botEthnicity,
      botEducation: s.botEducation,
      botTenureYears: s.botTenureYears,
      botJobTitle: s.botJobTitle,
      botAge: s.botAge,
      botAvatar: s.botAvatar,
      botVoice: s.botVoice,
      candidateGender: s.candidateGender,
      candidateEthnicity: s.candidateEthnicity,
      candidateName: '@CANDIDATE_NAME@',
      brief: s.brief,
      botName: s.botName,
      skills: mapMetricsToSkills(s.scoringMetrics),
      seedResponses: s.seedResponses,
      privilege: ScenarioPrivilege.USER,
      group: 'U',
      candidateEducation: s.candidateEducation,
      candidateTenureYears: s.candidateTenureYears,
      candidateJobTitle: s.candidateJobTitle,
      candidateAge: s.candidateAge
    }).then(async (scenario) => {
      await fetchMyScenarios();
      return scenario;
    });
  };

  const openScenarioGenerationModal = () => {
    setGenerateOpen(true);
    setGeneratedScenario(null);
  }

  const closeScenarioGenerationModal = () => setGenerateOpen(false);

  const handleCloseList = () => {
    setShowList(false);
  };

  const handleOpenList = () => {
    setShowList(true);
  };

  const handleItemClick = (item: ScenarioSummaryDTO) => {
    setSelectedItem(item);
    trackEvent('List', 'Select', `Scenario ${item.uuid}`);
  };

  const handleScenarioCreate = async (gs: GeneratedScenarioDTO) => {
    if (gs) {
      createScenario(gs).then((scenario) => {
        closeScenarioGenerationModal();
        navigate('/scenarios');
      }).catch((error) => {
        console.error('Error creating scenario:', error);
      });
    }
  };

  const handleScenarioUpdate = async (s: ScenarioDTO) => {
    if (s) {
      updateScenario(s).then((scenario) => {
        setEditOpen(false);
        navigate('/scenarios');
      }).catch((error) => {
        console.error('Error updating scenario:', error);
      });
    }
  };


  const handleStartConversation = async (gs: GeneratedScenarioDTO) => {
    if (gs) {
      createScenario(gs).then(async (scenario) => {
        api.createConversationFromGeneratedScenario(gs).then((conversation) => {
          navigate(`/conversation/${conversation.uuid}/`);

        }).catch((error) => {
          console.error('Error creating conversation:', error);
        });
      }).catch((error) => {
        console.error('Error saving scenario:', error);
      });
    }
  };

  const handleEditClick = (scenario: ScenarioSummaryDTO) => {
    setSelectedItem(scenario);
    setEditOpen(true);
  };

  const handleDeleteClick = (scenario: ScenarioSummaryDTO) => {
    setSelectedItem(scenario);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteConfirm = async () => {
    if (selectedItem) {
      try {
        await api.deleteScenario(selectedItem.uuid);
        const updatedScenarios = myScenarios.filter(scenario => scenario.uuid !== selectedItem.uuid);
        setMyScenarios(updatedScenarios);
        setDeleteOpen(false);
        if (updatedScenarios.length > 0) {
          setSelectedItem(updatedScenarios[0]);
        } else {
          /* if there are no self created scenarios, select the first public scenario */
          if (publicScenarios.length > 0) {
            setSelectedItem(publicScenarios[0]);
          } else {
            setSelectedItem(null);
          }
        }
      } catch (error) {
        console.error('Error deleting scenario:', error);
        setDeleteOpen(false);
      }
    }
  };

  function truncateSentence(sentence: string) {
    const words = sentence.split(' ');

    // Check if the sentence has more than three words, truncate to three words and add ellipsis
    if (words.length > 3) {
      const truncatedWords = words.slice(0, 5);
      // Capitalize the first word and join with the rest
      return `${capitalizeFirstWord(truncatedWords.join(' '))} ...`;
    } else {
      // If sentence has 3 or fewer words, capitalize the first word and return
      return capitalizeFirstWord(sentence);
    }
  }

  function capitalizeFirstWord(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const createScenarioContent = (loggedIn && allowDynamicScenarios) ? (
      <>
        <Button sx={{ml: 2}} variant="outlined" onClick={openScenarioGenerationModal}>+ Create Scenario</Button>
      </>
    ) : null

  const mainContent = (
    <>
      <Grid container spacing={0}>
        <Grid
          item
          md={3}
          sm={12}
          xs={12}
          sx={{
            borderRight: '1px solid #ECEDEE',
            backgroundColor: 'background.paper',
            height: {
              xs: 'auto',
              sm: 'auto',
              md: `calc(100vh - ${appBarHeight}px)`,
            },
            overflow: {
              md: 'auto',
            },
          }}
        >
          {isSmallScreen && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 2 }}>
              {!showList && (
                <Button
                  variant="outlined"
                  startIcon={<CharactersIcon />}
                  sx={{
                    color: '#323232',
                    borderColor: '#BFBFBF',
                    backgroundColor: '#F1F1F1',
                  }}
                  onClick={handleOpenList}
                >
                  Characters
                </Button>
              )}
              {showList && (
                <Button
                  variant="outlined"
                  startIcon={<CloseIcon />}
                  sx={{
                    color: '#323232',
                    borderColor: '#BFBFBF',
                    backgroundColor: '#F1F1F1',
                  }}
                  onClick={handleCloseList}
                >
                  Close
                </Button>
              )}
            </Box>
          )}
          {(showList || !isSmallScreen) && (
            <>
              {myScenarios && allowDynamicScenarios && myScenarios.length > 0 && (
                <>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: '18px',
                  mb: 1,
                  ml: 2,
                }}>
                  <Typography variant="body1" fontWeight="bold">
                    My Scenarios
                  </Typography>
                </Box>
                <List sx={{ width: '100%' }}>
                  <>
                    {myScenarios && myScenarios.map((scenario: ScenarioSummaryDTO, scenarioIndex) => (
                      <React.Fragment key={ generateKey('my', scenarioIndex) }>
                        <ListItem
                          alignItems="flex-start"
                          onClick={() => {
                            handleItemClick(scenario);
                            handleCloseList();
                          }}
                          sx={{ backgroundColor: selectedItem === scenario ? '#F8F3FF' : 'inherit' }}
                        >
                          <ListItemAvatar>
                            <Avatar alt={scenario.botName} src={scenario.botAvatar ? scenario.botAvatar : ''}
                                    sx={{ width: 45, height: 45 }} />
                          </ListItemAvatar>
                          <Box sx={{ flexGrow: 1 }}>
                            <ListItemText
                              primary={scenario.name}
                              secondary={scenario.botName}
                            />
                            <Box style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                              <Typography
                                sx={{ display: 'inline' }}
                                variant="body2"
                                color="text.secondary"
                              >
                                Age: {scenario.botAge}
                              </Typography>
                              <Divider orientation="vertical" flexItem
                                       sx={{
                                         borderWidth: '1px',
                                         margin: '0 0.3rem',
                                       }} />
                              <Typography
                                sx={{ display: 'inline' }}
                                variant="body2"
                                color="text.secondary"
                              >
                                Ethnicity: {formatString(scenario.botEthnicity)}
                              </Typography>
                            </Box>
                            <Box display="flex" marginTop={0.5}>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ marginRight: '0.3rem' }}>
                                Emotion:
                              </Typography><EmotionChip emotion={scenario.botEmotion} />
                            </Box>
                          </Box>
                          {allowDynamicScenarioEditing && (
                            <IconButton edge="end" aria-label="edit" onClick={() => handleEditClick(scenario)} sx={{ ml: 'auto' }}>
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteClick(scenario)} sx={{ ml: 'auto' }}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    ))}
                  </>
                </List>
                </>
              )}

              {groupScenarios && groupScenarios.length > 0 && (
                <>
                  {groupScenarios.map((sg: ScenarioGroupDTO, sgIndex) => (
                      <div key={ generateKey('group', sgIndex) }>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mt: '18px',
                          mb: 1,
                          ml: 2,
                        }}>
                          <Typography variant="body1" fontWeight="bold">
                            {sg.label} Scenarios
                          </Typography>
                        </Box>
                        <List sx={{ width: '100%' }}>
                          <>
                            {sg.scenarios && sg.scenarios.map((scenario: ScenarioSummaryDTO, scenarioIndex) => (
                              <React.Fragment key={ generateKey('groupScenario', sgIndex, scenarioIndex) }>
                                <ListItem
                                  alignItems="flex-start"
                                  onClick={() => {
                                    handleItemClick(scenario);
                                    handleCloseList();
                                  }}
                                  sx={{ backgroundColor: selectedItem === scenario ? '#F8F3FF' : 'inherit' }}
                                >
                                  <ListItemAvatar>
                                    <Avatar alt={scenario.botName} src={scenario.botAvatar ? scenario.botAvatar : ''}
                                            sx={{ width: 45, height: 45 }} />
                                  </ListItemAvatar>
                                  <Box sx={{ flexGrow: 1 }}>
                                    <ListItemText
                                      primary={scenario.name}
                                      secondary={scenario.botName}
                                    />
                                    <Box style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                      <Typography
                                        sx={{ display: 'inline' }}
                                        variant="body2"
                                        color="text.secondary"
                                      >
                                        Age: {scenario.botAge}
                                      </Typography>
                                      <Divider orientation="vertical" flexItem
                                               sx={{
                                                 borderWidth: '1px',
                                                 margin: '0 0.3rem',
                                               }} />
                                      <Typography
                                        sx={{ display: 'inline' }}
                                        variant="body2"
                                        color="text.secondary"
                                      >
                                        Ethnicity: {formatString(scenario.botEthnicity)}
                                      </Typography>
                                    </Box>
                                    <Box display="flex" marginTop={0.5}>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ marginRight: '0.3rem' }}>
                                        Emotion:
                                      </Typography><EmotionChip emotion={scenario.botEmotion} />
                                    </Box>
                                  </Box>
                                </ListItem>
                                <Divider component="li" />
                              </React.Fragment>
                            ))}
                          </>
                        </List>
                      </div>
                  ))}
                </>
              )}

              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: '18px',
                mb: 1,
                ml: 2,
              }}>
                <Typography variant="body1" fontWeight="bold">
                  Public Scenarios
                </Typography>
              </Box>

              <List sx={{ width: '100%'  }}>
                {publicScenarios.map((scenario, index) => {
                  return (
                    <React.Fragment key={ generateKey('public', index) }>
                      <ListItem
                        alignItems="flex-start"
                        onClick={() => {
                          handleItemClick(scenario);
                          handleCloseList();
                        }}
                        sx={{ backgroundColor: selectedItem === scenario ? '#F8F3FF' : 'inherit',  cursor: 'pointer' }}
                      >
                        <ListItemAvatar>
                          <Avatar alt={scenario.botName} src={scenario.botAvatar ? scenario.botAvatar : ''}
                                  sx={{ width: 45, height: 45 }} />
                        </ListItemAvatar>
                        <Box>
                          <ListItemText
                            primary={scenario.name}
                            secondary={scenario.botName}
                          />
                          <Box style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                            <Typography
                              sx={{ display: 'inline' }}
                              variant="body2"
                              color="text.secondary"
                            >
                              Age: {scenario.botAge}
                            </Typography>
                            <Divider orientation="vertical" flexItem
                                     sx={{
                                       borderWidth: '1px',
                                       margin: '0 0.3rem',
                                     }} />
                            <Typography
                              sx={{ display: 'inline' }}
                              variant="body2"
                              color="text.secondary"
                            >
                              Ethnicity: {formatString(scenario.botEthnicity)}
                            </Typography>
                          </Box>
                          <Box display="flex" marginTop={0.5}>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ marginRight: '0.3rem' }}>
                              Emotion:
                            </Typography><EmotionChip emotion={scenario.botEmotion} />
                          </Box>
                        </Box>
                      </ListItem>
                      <Divider component="li" />
                    </React.Fragment>
                  );
                })}
              </List>

              <Dialog open={deleteOpen} onClose={handleDeleteClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this scenario?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDeleteClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Grid>

        <Grid
          item
          md={6}
          sm={12}
          sx={{
            borderRight: '1px solid #ECEDEE',
            backgroundColor: 'background.paper',
            height: {
              xs: 'auto',
              sm: 'auto',
              md: `calc(100vh - ${appBarHeight}px)`,
            },
            overflow: {
              md: 'auto',
            },
          }}
        >
          {selectedItem &&
            <CharacterCard item={selectedItem} isSmallScreen={isSmallScreen} />}
        </Grid>

        <Grid
          item
          md={3}
          sm={12}
          sx={{
            height: {
              xs: 'auto',
              sm: 'auto',
              md: `calc(100vh - ${appBarHeight}px)`,
            },
            overflow: {
              md: 'auto',
            },
          }}
        >
          <Box sx={{ p: 4 }}>
            <div style={{ display: 'flex', alignItems: 'center', paddingTop: '8px', paddingBottom: '12px' }}>
              <img src="/about.svg" alt="About" style={{ width: '22px', height: '22px' }} />
              <Typography variant="body1" fontWeight="bold" sx={{ pl: 1 }}>
                About this Scenario
              </Typography>
            </div>

            <Typography variant="body1" fontWeight="bold" sx={{ pt: 3 }}>Skills to concentrate on</Typography>
            {selectedItem?.skills.map((uuid, index) => {
                const skill = publicSkills.find(skill => skill.uuid === uuid);
                return skill ? (
                  <React.Fragment key={ generateKey("skill", index) }>
                    <Box sx={{ pt: 3, pb: 1 }}>
                      <SkillWithIcon skillName={skill.name} />
                    </Box>
                    <Typography variant="body2">{skill.description}</Typography>
                  </React.Fragment>
                ) : null;
              })
            }
          </Box>
        </Grid>
      </Grid>

      <Modal
        open={generateOpen}
        onClose={closeScenarioGenerationModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          // border: '2px solid #000',
          p: 4,
          borderRadius: '16px',
          maxHeight: '80vh',
          overflowY: 'auto',
        }}>
          {!generatedScenario && selectedItem && (
            <GenerateCustomScenario item={selectedItem}
                                    onGenerated={(scenario) => setGeneratedScenario(scenario)}
                                    onError={(context, errMessage) => {
                                      console.error(`Error generating scenario: ${context}: ${errMessage}`);
                                    }} />
          )}

          {generatedScenario && (
            <ReviewCustomScenario scenario={generatedScenario}
                                  onStart={handleStartConversation}
                                  onSave={handleScenarioCreate}
                                  onBack={() => setGeneratedScenario(null)} />
          )}
        </Box>
      </Modal>

      <Modal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          // border: '2px solid #000',
          p: 4,
          borderRadius: '16px',
          maxHeight: '80vh',
          overflowY: 'auto',
        }}>

          {selectedItem && selectedItem.uuid && (
            <EditCustomScenario scenarioUuid={selectedItem?.uuid}
                                onSave={handleScenarioUpdate}
                                onCancel={() => setEditOpen(false)} />
          )}
        </Box>
      </Modal>
    </>
  );

  return (
    <AppDrawer pageName="Scenarios" customBarContent={createScenarioContent} mainContent={mainContent} />
  );
}

export default Scenarios;
