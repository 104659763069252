import React from 'react';
import { Container, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  const typographyStyles = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    color: '#555555',
    fontSize: '23px',
    marginBottom: '1rem'
  };

  const typographyStylesBody = {
        fontFamily: 'Inter, sans-serif',
        letterSpacing: '0.008em',
        fontSize: '15px',
        lineHeight: '20px'
  }

  return (
    <Container>
      <Paper elevation={0} sx={{ p: 3, mt: 3, fontFamily: 'Inter, sans-serif'  }}>
        <Typography variant="caption" gutterBottom sx={{ fontFamily: 'inherit' }}>
          V1.0
        </Typography>
        <Typography variant="h4" gutterBottom sx={typographyStyles}>
          PRIVACY POLICY
        </Typography>

        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          This privacy policy sets out how Mindbloom AI Limited uses and protects your personal data.
        </Typography>

        <Typography variant="h5" gutterBottom mt={2} sx={typographyStyles}>
          1. Important information and who we are
        </Typography>
        <Typography variant="h6" gutterBottom sx={typographyStyles}>
          Privacy policy
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          This privacy policy gives you information about how Mindbloom AI Limited collects and uses your personal data through your use of this website, including any data you may provide when you
          register with us, participate in a survey or trial or purchase a product or service.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          This website is not intended for children and we do not knowingly collect data relating to children.
        </Typography>
        <Typography variant="h6" gutterBottom sx={typographyStyles}>
          Controller
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          Mindbloom AI Limited is the controller and responsible for your personal data (collectively referred to as "we", "us" or "our" in this privacy policy). We are registered in England and Wales
          under company number 14896549 and have our registered office at 82a James Carter Road, Mildenhall, Bury St. Edmunds, Suffolk, England, IP28 7DE.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          If you have any questions about this privacy policy, including any requests to exercise your legal rights
          (paragraph 9), please contact us using the information set out in the contact details section (paragraph 10).
        </Typography>

        <Typography variant="h5" gutterBottom sx={typographyStyles}>
          2. The types of personal data we collect about you
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          Personal data means any information about an individual from which that person can be identified.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We may collect, use, store and transfer different kinds of personal data about you which we have grouped
          together as follows:
          <ul>
            <li>Identity Data includes first name, last name, any previous names, username or similar identifier, marital status, title, date of birth, gender and recordings of your voice and other
              audio conversation data.
            </li>
            <li>Contact Data includes billing address, delivery address, email address and telephone numbers.</li>
            <li>Financial Data includes bank account and payment card details</li>
            <li>Transaction Data includes details about payments to and from you and other details of products and
              services you have purchased from us.
            </li>
            <li>Technical Data includes internet protocol (IP) address, your login data, browser type and version, time
              zone setting and location, browser plug-in types and versions, operating system and platform, device ID
              and other technology on the devices you use to access this website.
            </li>
            <li>Profile Data includes your username and password, purchases or orders made by you, your interests,
              preferences, feedback and survey responses.
            </li>
            <li>Usage Data includes information about how you interact with and use our website, products and
              services.
            </li>
            <li>Marketing and Communications Data includes your preferences in receiving marketing from us and our third
              parties and your communication preferences.
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We also collect, use and share aggregated data such as statistical or demographic data or the results of
          processing recordings of your voice and other audio conversation data which are not personal data as they do
          not directly (or indirectly) reveal your identity. For example, we may:
          <ul>
            <li>Record your voice and transcribe to a text.</li>
            <li>Transmit your text statement to our systems where we build and store the conversation.</li>
            <li>Transmit the conversation to our self-hosted language models to generate an appropriate bot response.</li>
            <li>The bot text may be transmitted to an external provider to perform text to speech, but your statements are not.</li>
            <li>During (and on completion) of the conversation, the conversation is analysed using self-hosted models and potentially models provided by openai.</li>
            <li>Perform offline analyses of anonymised conversations to improve our services.</li>
          </ul>
          While we use audio or video, we do not transmit to or store this data in our own systems or any other provider engaged by us to deliver our services.
        </Typography>

        <Typography variant="h5" gutterBottom sx={typographyStyles}>
          3. How is your personal data collected?
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We use different methods to collect data from and about you including through:
          Your interactions with us. You may give us your personal data by filling in online forms or by corresponding
          with us by post, phone, email or otherwise. This includes personal data you provide when you:
          <ul>
            <li>apply for our products or services;</li>
            <li>create an account on our website;</li>
            <li>subscribe to our service or publications;</li>
            <li>request marketing to be sent to you;</li>
            <li>participate in a survey or trial;</li>
            <li>upload recordings of your voice and other audio conversation data to our website; or</li>
            <li>give us feedback or contact us.</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          Automated technologies or interactions. As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this
          personal data by using cookies, server logs and other similar technologies. Please see our cookie policy below for further details.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          Third parties or publicly available sources. We may receive personal data about you from various third parties and public sources based inside or outside the UK, such as Technical Data from
          analytics providers and search information providers and Contact, Financial and Transaction Data from providers of technical, payment and delivery services.
        </Typography>

        <Typography variant="h5" gutterBottom sx={typographyStyles}>
          4. How we use your personal data
        </Typography>
        <Typography variant="h6" gutterBottom sx={typographyStyles}>
          Legal basis
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          The law requires us to have a legal basis for collecting and using your personal data. We rely on one or more
          of the following legal bases:
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          <b>Performance of a contract with you</b>: Where we need to perform the contract we are about to enter into or have
          entered into with you.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          <b>Legitimate interests</b>: We may use your personal data where it is necessary to conduct our business and pursue
          our legitimate interests, for example to prevent fraud and enable us to give you the best and most secure user
          and customer experience. We make sure we consider and balance any potential impact on you and your rights
          (both positive and negative) before we process your personal data for our legitimate interests. We do not use
          your personal data for activities where our interests are overridden by the impact on you (unless we have your
          consent or are otherwise required or permitted to by law).
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          <b>Legal obligation</b>: We may use your personal data where it is necessary for compliance with a legal obligation
          that we are subject to. We will identify the relevant legal obligation when we rely on this legal basis.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          <b>Consent</b>: We rely on consent only where we have obtained your active agreement to use your personal data for a
          specified purpose, for example if you subscribe to an email newsletter.
        </Typography>
        <Typography variant="h6" gutterBottom sx={typographyStyles}>
          Purposes for which we will use your personal data
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We have set out below, in a table format, a description of all the ways we plan to use the various categories of your personal data, and which of the legal bases we rely on to do so. We have
          also identified what our legitimate interests are where appropriate.
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontFamily: 'inherit', fontWeight: 500, fontSize: '14px' }}>Purpose/Use</TableCell>
                <TableCell sx={{ fontFamily: 'inherit', fontWeight: 500, fontSize: '14px' }}>Type of data</TableCell>
                <TableCell sx={{ fontFamily: 'inherit', fontWeight: 500, fontSize: '14px' }}>Legal basis and retention period</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>To register you as a new user or customer, we require your name, email, password and acceptance of Terms and Conditions and Privacy Policy.</p>
                  <p>Additional (optional) information such as ethnicity, personality type and gender maybe requested to refine our analyses.</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>(a) Identity</p>
                  <p>(b) Contact Performance of a contract with you.</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>User details will be stored in the application until completion of a user requested delete, or within a period of 1 month after a user has been notified their account has been
                    inactive (for at least 1 year) or the user has violated the terms of service.</p>
                  <p>Once removed from the system, data will be securely retained for the required legal retention period, before being disposed of.</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>For sending it to the Mindbloom open artificial intelligence (open AI) system which uses a large language model to generate conversation and analyse the content of the
                    conversations and
                    analysing that data and presenting feedback back to the user.</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>(a) Identity including audio conversation data</p>
                  <p>(b) Contact Performance of a contract with you.</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>User data will be stored in the application until completion of a user requested delete, or within a period of 1 month after a user has been notified their account has been
                    inactive (for at
                    least 1 year) or the user has violated the terms of service.</p>
                  <p>Once removed from the system, data will be securely retained for the required legal retention period, before being disposed of.</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>To process and deliver your order including:</p>
                  <p>(a) Manage payments, fees and charges</p>
                  <p>(b) Collect and recover money owed to us</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>(a) Identity</p>
                  <p>(b) Contact</p>
                  <p>(c) Financial</p>
                  <p>(d) Transaction</p>
                  <p>(e) Marketing and Communications</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>(a) Performance of a contract with you</p>
                  <p>(b) Necessary for our legitimate interests (to recover debts due to us)</p>
                  <p>User data will be stored in the application until completion of a user requested delete, or within a period of 1 month after a user has been notified their account has been
                    inactive (for at
                    least 1 year) or the user has violated the terms of service.</p>
                  <p>Once removed from the system, data will be securely retained for the required legal retention period, before being disposed of.</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>To manage our relationship with you which will include:</p>
                  <p>(a) Notifying you about changes to our terms or privacy policy</p>
                  <p>(b) Dealing with your requests, complaints and queries</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>(a) Identity</p>
                  <p>(b) Contact</p>
                  <p>(c) Profile</p>
                  <p>(d) Marketing and Communications</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>(a) Performance of a contract with you</p>
                  <p>(b) Necessary to comply with a legal obligation</p>
                  <p>(c) Necessary for our legitimate interests (to keep our records updated and manage our relationship with you</p>
                  <p>User data will be stored in the application until completion of a user requested delete, or within a period of 1 month after a user has been notified their account has been
                    inactive
                    (for at least 1 year) or the user has violated the terms of service.</p>
                  <p>Once removed from the system, data will be securely retained for the required legal retention period, before being disposed of.</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>To enable you to participate in a survey or trial</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>(a) Identity</p>
                  <p>(b) Contact</p>
                  <p>(c) Profile</p>
                  <p>(d) Usage</p>
                  <p>(e) Marketing and Communications</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>(a) Performance of a contract with you</p>
                  <p>(b) Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)</p>
                  <p>User data will be stored in the application until completion of a user requested delete, or within a period of 1 month after a user has been notified their account has been
                    inactive
                    (for at least 1 year) or the user has violated the terms of service.</p>
                  <p>Once removed from the system, data will be securely retained for the required legal retention period, before being disposed of.</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>(a) Identity</p>
                  <p>(b) Contact</p>
                  <p>(c) Technical</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a
                    business reorganisation or group restructuring exercise)</p>
                  <p>(b) Necessary to comply with a legal obligation</p>
                  <p>User data will be stored in the application until completion of a user requested delete, or within a period of 1 month after a user has been notified their account has been
                    inactive
                    (for at least 1 year) or the user has violated the terms of service.</p>
                  <p>Once removed from the system, data will be securely retained for the required legal retention period, before being disposed of.</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>To deliver relevant website content and online advertisements to you and measure or understand the effectiveness of the advertising we serve to you</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>(a) Identity</p>
                  <p>(b) Contact</p>
                  <p>(c) Profile</p>
                  <p>(d) Usage</p>
                  <p>(e) Marketing and Communications</p>
                  <p>(f) Technical</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)</p>
                  <p>User data will be stored in the application until completion of a user requested delete, or within a period of 1 month after a user has been notified their account has been
                    inactive
                    (for at least 1 year) or the user has violated the terms of service.</p>
                  <p>Once removed from the system, data will be securely retained for the required legal retention period, before being disposed of.</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>To use data analytics to improve our website, products/services, user and customer relationships and experiences and to measure the effectiveness of our communications and
                    marketing</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>(a) Technical</p>
                  <p>(b) Usage Necessary for our legitimate interests (to define types of users and customers for our products and services, to keep our website updated and relevant, to develop our
                    business and to inform our marketing strategy)</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>User data will be stored in the application until completion of a user requested delete, or within a period of 1 month after a user has been notified their account has been
                    inactive
                    (for at least 1 year) or the user has violated the terms of service.</p>
                  <p>Once removed from the system, data will be securely retained for the required legal retention period, before being disposed of.</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>To send you relevant marketing communications and make personalised suggestions and recommendations to you about goods or services that may be of interest to you based on your
                    Profile Data</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>(a) Identity</p>
                  <p>(b) Contact</p>
                  <p>(c) Technical</p>
                  <p>(d) Usage</p>
                  <p>(e) Profile</p>
                  <p>(f) Marketing and Communications Consent, having obtained your prior consent to receiving direct marketing communications</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>User data will be stored in the application until completion of a user requested delete, or within a period of 1 month after a user has been notified their account has been
                    inactive
                    (for at least 1 year) or the user has violated the terms of service.</p>
                  <p>Once removed from the system, data will be securely retained for the required legal retention period, before being disposed of.</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>To carry out market research through your voluntary participation in surveys Necessary for our legitimate interests (to study how users and customers use our products/services and
                    to
                    help us improve and develop our products and services).</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>User data will be stored in the application until completion of a user requested delete, or within a period of 1 month after a user has been notified their account has been
                    inactive
                    (for at least 1 year) or the user has violated the terms of service.</p>
                  <p>Once removed from the system, data will be securely retained for the required legal retention period, before being disposed of.</p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="h6" gutterBottom sx={typographyStyles}>
          Direct marketing
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          During the registration process on our website when your personal data is collected, you will be asked to indicate your preferences for receiving direct marketing communications from us via
          email.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We may also analyse your Identity, Contact, Technical, Usage and Profile Data to form a view which products, services and offers may be of interest to you so that we can then send you
          relevant
          marketing communications.
        </Typography>
        <Typography variant="h6" gutterBottom sx={typographyStyles}>
          Third-party marketing
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We will get your express consent before we share your personal data with any third party for their own direct marketing purposes.
        </Typography>
        <Typography variant="h6" gutterBottom sx={typographyStyles}>
          Opting out of marketing
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          You can ask to stop sending you marketing communications at any time by logging into the website and checking or unchecking relevant boxes to adjust your marketing preferences or by
          following
          the opt-out links within any marketing communication sent to you or by contacting us at <Link href="mailto:admin@mindbloom.ai">admin@mindbloom.ai</Link>.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          If you opt out of receiving marketing communications, you will still receive service-related communications that are essential for administrative or customer service purposes.
        </Typography>
        <Typography variant="h6" gutterBottom sx={typographyStyles}>
          Cookies
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our
          site. A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your
          computer's hard drive. We use the following cookies:
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          <b>Strictly necessary cookies</b>: These are cookies that are required for the operation of our website. These essential cookies are always enabled because our website won’t work properly
          without
          them. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services. You can switch off these cookies in
          your browser settings but you may then not be able to access all or parts of our website.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          <b>Analytical or performance cookies</b>: These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us
          to improve
          the way our website works, for example, by ensuring that users are finding what they are looking for easily.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          <b>Functionality cookies</b>: These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your
          preferences (for
          example, your choice of language or region).
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          <b>Targeting cookies</b>: These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the
          advertising displayed on it more relevant to your interests.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          You can find more information about the individual cookies we use and the purposes for which we use them in the table below:
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontFamily: 'inherit', fontWeight: 500, fontSize: '14px'}}>Cookie Title / Name</TableCell>
                <TableCell sx={{ fontFamily: 'inherit', fontWeight: 500, fontSize: '14px'}}>Purpose</TableCell>
                <TableCell sx={{ fontFamily: 'inherit', fontWeight: 500, fontSize: '14px'}}>More information</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  authToken
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  Manages the authentication session so that the user does not have to login in each time they return to the application or change pages.
                  This cookie is essential for our site to function correctly.
                  The cookie expires within 30 days and needs to be refreshed through re-login.
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  correlationId
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  Created when a user starts a conversation but is not logged in. This retains a unique identifier which allows the conversation to be associated with an account once a user
                  creates an account or logs in.
                  This cookie is essential for our site to function correctly.
                  The cookie is only applicable until the user leaves the page.
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  Google Analytics (v4)
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>Google Analytics are used to help us to improve the usability of our application and to better understand how users found the site.</p>
                  <p>The cookies are essential to our business and are valid for 2 years.</p>
                  <p>There are two cookies used by GA:</p>
                  <ul>
                    <li>_ga - User to distinguish users</li>
                    <li>_ga_g-de0mxs9nx8 - Used to persist session state</li>
                  </ul>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <Link href="https://support.google.com/analytics/answer/11397207?hl=en">Google Analytics Website</Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  Meta Pixel
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>Meta Pixel is used to help us to improve the usability of our application and to better understand how users found the site.</p>
                  <p>The cookies are essential to our business and are valid for 2 years.</p>
                  <ul>
                    <li>_fbq - User to distinguish users</li>
                  </ul>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  Consent Cookies
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                  <p>To track your consent to using cookies we will store the following cookies in your browser (should you consent):</p>
                  <ul>
                    <li>rcl_consent_given – Consent for necessary cookies.</li>
                    <li>rcl_marketing_consent – Consent to use in marketing cookies.</li>
                    <li>rcl_preferences_consent – Consent to use functional cookies.</li>
                    <li>rcl_statistics_consent – Consent to collect data analytics to improve the site.</li>
                  </ul>
                  <p>The cookies valid for 1 year.</p>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', fontFamily: 'inherit', fontSize: '14px' }}>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <p></p>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We do not share the information collected by the cookies with any third parties, beyond Google for the purpose of collecting user analytics.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          You can choose which analytical, functionality and marketing cookies we can set by clicking on the button(s):
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          <ul>
            <li>Strictly necessary cookies ALWAYS ACTIVE</li>
            <li>Analytical or performance cookies ON BY DEFAULT</li>
            <li>Functionality cookies N/A</li>
            <li>Marketing cookies OFF</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          You can also choose to “Accept Selected” or “Accept All” cookies in the cookie banner.
          However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our website.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          Except for essential cookies, all cookies will expire after 2 years.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          If you have any questions or concerns about our use of cookies, please send us an email at <Link href="mailto:admin@mindbloom.ai">admin@mindbloom.ai</Link>.
        </Typography>

        <Typography variant="h5" gutterBottom mt={2} sx={typographyStyles}>
          5. Disclosures of your personal data
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}
        >
          We may share your personal data where necessary with the parties set out below for the purposes set out in the table Purposes for which we will use your personal data above.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          All personal data collected by us is stored within a system under our the direct control of us and/or our contractors. We host our applications and services on AWS managed infrastructure.
          Other than conversational related information exchange with OpenAI for the purpose of conversational analysis and Google Analytics to support improvement of our services, no personal data is
          provided to any other service provider.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We may also share personal data with third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other
          businesses
          or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal
          data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.
        </Typography>

        <Typography variant="h5" gutterBottom mt={2} sx={typographyStyles}>
          6. International transfers
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We may transfer your personal data to service providers that carry out certain functions on our behalf. This may involve transferring personal data outside the UK. We will only transfer your
          personal data to countries that have been deemed by the UK to provide an adequate level of protection for personal data.
        </Typography>

        <Typography variant="h5" gutterBottom mt={2} sx={typographyStyles}>
          7. Data security
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we
          limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our
          instructions and they are subject to a duty of confidentiality.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
        </Typography>

        <Typography variant="h5" gutterBottom mt={2} sx={typographyStyles}>
          8. Data retention
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax,
          accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in
          respect
          to our relationship with you.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or
          disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory,
          tax,
          accounting or other requirements.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          By law we have to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for six years after they cease being customers for tax purposes.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          In some circumstances you can ask us to delete your data: see paragraph 9 below for further information.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information
          indefinitely without further notice to you.
        </Typography>

        <Typography variant="h5" gutterBottom mt={2} sx={typographyStyles}>
          9. Your legal rights
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          You have a number of rights under data protection laws in relation to your personal data.
          You have the right to:
          <ul>
            <li>Request access to your personal data (commonly known as a "subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are
              lawfully processing it.
            </li>
            <li>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the
              accuracy of the new data you provide to us.
            </li>
            <li>Request erasure of your personal data in certain circumstances. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process
              it.
              You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed
              your
              information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure
              for
              specific legal reasons which will be notified to you, if applicable, at the time of your request.
            </li>
            <li>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) as the legal basis for that particular use of your data (including
              carrying out profiling based on our legitimate interests). In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your
              right to
              object.
            </li>
            <li>You also have the absolute right to object any time to the processing of your personal data for direct marketing purposes (see OPTING OUT OF MARKETING in paragraph 4 for details of how
              to
              object to receiving direct marketing communications).
            </li>
            <li>Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used,
              machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a
              contract
              with you.
            </li>
            <li>Withdraw consent at any time where we are relying on consent to process your personal data (see the table in section 4 for details of when we rely on your consent as the legal basis
              for
              using your data). However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide
              certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
            </li>
            <li>Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in one of the following scenarios:
              <ul>
                <li>If you want us to establish the data's accuracy;</li>
                <li>Where our use of the data is unlawful but you do not want us to erase it;</li>
                <li>Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or</li>
                <li>You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
              </ul>
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          If you wish to exercise any of the rights set out above, please contact us (see Contact details (paragraph 10)).
        </Typography>
        <Typography variant="h6" gutterBottom sx={typographyStyles}>
          No fee usually required
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive
          or excessive. Alternatively, we could refuse to comply with your request in these circumstances.
        </Typography>
        <Typography variant="h6" gutterBottom sx={typographyStyles}>
          What we may need from you
        </Typography>
        <Typography variant="body1" paragraph>
        </Typography>
        <Typography variant="h6" gutterBottom sx={typographyStyles}
        >
          Privacy policy
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}
        >
          We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a
          security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your
          request to speed up our response.
        </Typography>
        <Typography variant="h6" gutterBottom sx={typographyStyles}>
          Time limit to respond
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of
          requests.
          In this case, we will notify you and keep you updated.
        </Typography>

        <Typography variant="h5" gutterBottom mt={2} sx={typographyStyles}>
          10. Contact details
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          If you have any questions about this privacy policy or about the use of your personal data or you want to exercise your privacy rights, please contact us in the following ways:
          <ul>
            <li>Email address: <Link href="mailto:admin@mindbloom.ai">admin@mindbloom.ai</Link></li>
            <li>Postal address: 82a James Carter Road, Mildenhall, Bury St. Edmunds, Suffolk, England, IP28 7DE</li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom mt={2} sx={typographyStyles}>
          11. Complaints
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          You have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the UK regulator for data protection issues (www.ico.org.uk). We would, however, appreciate
          the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.
        </Typography>

        <Typography variant="h5" gutterBottom mt={2} sx={typographyStyles}>
          12. Changes to the privacy policy and your duty to inform us of changes
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          We keep our privacy policy under regular review. This version was last updated on 14 June 2024.
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us, for example a new
          address or email address.
        </Typography>

        <Typography variant="h5" gutterBottom mt={2} sx={typographyStyles}>
          13. Third-party links
        </Typography>
        <Typography variant="body1" paragraph sx={typographyStylesBody}>
          This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about
          you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website
          you visit.
        </Typography>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
