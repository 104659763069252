import { Box, Typography } from '@mui/material';
import React from 'react';

export type MessageRendererProps = {
  isBot: boolean;
  content: string;
  name?: string;
}

const MessageRenderer = ({ isBot, content, name }: MessageRendererProps) => {
  return (
    <>
      {name && (<Box mb={0.5} textAlign={isBot? 'left' : 'right'}>
        <Typography variant="body2"> {name} </Typography>
      </Box>)}
      <Box display="flex" justifyContent={isBot ? 'flex-start' : 'flex-end'} mb={2}>
        <Box bgcolor={isBot ? '#F0F1F3' : '#226EDC'}
             borderRadius={isBot ? '0 10px 10px 10px' : '10px 0 10px 10px'}
             color={isBot ? '#000000' : '#ffffff'}
             p={2}
             maxWidth="90%"
             textAlign={isBot ? 'left' : 'right'}
        >
          <Typography variant="body2">
            {content}
          </Typography>
        </Box>
      </Box>
    </>

  );
};

export default MessageRenderer;