import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DashboardIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1531_1716)">
      <path
        d="M21.2933 13.2933L19.96 14.6267"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M16 10.6667V12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.7065 13.2933L12.0399 14.6267"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M23.9334 18.6H22.6001"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8.06689 18.6H9.40023"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M25.976 24C27.4933 21.7333 28.2613 18.924 27.9187 15.9226C27.2933 10.448 22.832 6.00798 17.3547 5.40798C10.1213 4.61464 4 10.2586 4 17.3333C4 19.8013 4.74667 22.0933 6.024 24"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M13.3335 21.3333C13.3335 22.8027 14.5255 23.9947 15.9975 23.9947H16.0042C17.4748 23.9933 18.6668 22.8027 18.6668 21.3333L18.6655 21.328H18.6668C18.6668 19.84 17.2188 18.248 16.4575 17.5133C16.2002 17.2653 15.8015 17.2653 15.5442 17.5133C14.7815 18.248 13.3335 19.84 13.3335 21.3267"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_1531_1716">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default DashboardIcon;
