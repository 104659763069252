import React from 'react';
import { Container, Typography } from '@mui/material';
import TermsContent from '../components/TermsContent';

const TermsOfService = () => {
  return (
    <Container>
      <Typography variant="caption" gutterBottom sx={{ fontFamily: 'Inter, sans-serif' }}>
        V1.0.0
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Inter, sans-serif' }}>
        Website Terms of Use
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Inter, sans-serif' }}>
        PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS SITE
      </Typography>
      <TermsContent />
    </Container>
  );
};

export default TermsOfService;
