import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Link } from '@mui/material';

const FAQContent = () => {
  return (
    <Box sx={{ padding: 2 }}>
      {/*<Typography variant="h4" gutterBottom>*/}
      {/*  Frequently Asked Questions (FAQ)*/}
      {/*</Typography>*/}

      <Typography variant="h6" gutterBottom>
        Mindbloom isn’t picking up my voice, how can I fix this?
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="1. Check mic and speaker selection:" secondary="Ensure that the microphone and speaker you intend to use are selected in your operating system and Mindbloom settings. Sometimes these settings change without notification." />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. Be patient:" secondary="Some devices may take 1-2 seconds before the audio input is activated." />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. Use wired headset where possible:" secondary="Try using wired headphones or your computer’s built-in speakers instead. Bluetooth headphones can have speech detection software that conflicts with your browser and Mindbloom." />
        </ListItem>
        <ListItem>
          <ListItemText primary="4. Disable speech output:" secondary="On some devices, disabling speech output can improve audio input functionality." />
        </ListItem>
        <ListItem>
          <ListItemText primary="5. Stay on the Mindbloom window:" secondary="If you switch to another window or browser tab during a conversation, the audio might stall. Mute and unmute to restore functionality." />
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom sx={{ mt:2 }}>
        What browsers are supported?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Use the latest version of Chrome, Safari, or Edge.
      </Typography>

      <Typography variant="h6" gutterBottom sx={{ mt:2 }}>
        I’m having issues and have followed the above tips, what else can I do?
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="1. Avoid AirPods and Bluetooth headphones:" secondary="Use wired headphones or built-in speakers instead." />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. Single active tab:" secondary="Ensure only one tab is running the Mindbloom app." />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. Quiet environment:" secondary="Conduct conversations in a quiet place with no background noise." />
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom sx={{ mt:2 }}>
        My report page is taking a long time to load
      </Typography>
      <Typography variant="body1" gutterBottom>
        Report Generation Time: Mindbloom generates each report individually, which can take 20-60 seconds. If the report doesn’t generate at all, please contact us at{' '}
        <Link href="mailto:admin@mindbloom.ai">admin@mindbloom.ai</Link>.
      </Typography>

      <Typography variant="h6" gutterBottom sx={{ mt:2 }}>
        I’ve got an issue not mentioned here
      </Typography>
      <Typography variant="body1" gutterBottom>
        Beta Feedback: We’re in beta and appreciate your feedback! Please provide detailed information about your issue, including browser, screenshots, and hardware used, and send it to{' '}
        <Link href="mailto:admin@mindbloom.ai">admin@mindbloom.ai</Link>.
      </Typography>
    </Box>
  );
};

export default FAQContent;
