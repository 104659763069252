import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ReportsIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32" fill="none">
    <path
      d="M18.6668 2.66669H8.00016C7.29292 2.66669 6.61464 2.94764 6.11454 3.44774C5.61445 3.94783 5.3335 4.62611 5.3335 5.33335V26.6667C5.3335 27.3739 5.61445 28.0522 6.11454 28.5523C6.61464 29.0524 7.29292 29.3334 8.00016 29.3334H24.0002C24.7074 29.3334 25.3857 29.0524 25.8858 28.5523C26.3859 28.0522 26.6668 27.3739 26.6668 26.6667V10.6667L18.6668 2.66669Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M18.6665 2.66669V10.6667H26.6665"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M21.3332 17.3333H10.6665"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M21.3332 22.6667H10.6665"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.3332 12H11.9998H10.6665"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default ReportsIcon;
