import Cookies from 'js-cookie';
import { SpeechToTextType } from '../audio/speech-to-text';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { Feature, FeatureMap, hasFeature } from '../lib/features';
import { FormControl, MenuItem, Select, TextField, InputLabel } from '@mui/material';

const DEFAULT_SST_MODEL_KEY = 'whisper';
export type SSTModel = {
  key: string;
  name: string;
  code: SpeechToTextType;
};

export type SSTModelSelectorProps = {
  model: SSTModel;
  onSelectModel: (model: SSTModel) => void;
  disabled?: boolean;
};

const findSSTModels = (): { [key: string]: SSTModel } => {
  return {
    'webkit': {
      key: 'webkit',
      name: 'Webkit',
      code: SpeechToTextType.WEBKIT,
    },
    'whisper': {
      key: 'whisper',
      name: 'Whisper',
      code: SpeechToTextType.WHISPER,
    },
  };
};

export const retrieveSSTModel = (features: FeatureMap): SSTModel => {
  const models = findSSTModels();
  if (hasFeature(Feature.STT_MODEL_SELECTION, features)) {
    const modelKey = (Cookies.get('sttModelKey') || DEFAULT_SST_MODEL_KEY).toLowerCase();
    return models[modelKey] || models[DEFAULT_SST_MODEL_KEY];
  } else {
    return models[DEFAULT_SST_MODEL_KEY];
  }
};

export const saveSSTModel = (model: SSTModel): void => {
  Cookies.set('sttModelKey', model.key);
};

export const SSTModelSelector = ({ model, onSelectModel, disabled }: SSTModelSelectorProps) => {
  const features = useSelector((state: RootState) => state.auth.features);

  if (hasFeature(Feature.STT_MODEL_SELECTION, features)) {
    return (
      <FormControl disabled={disabled === true}>
        <TextField label="Audio Input"
          value={model.key}
          variant="outlined"
          onChange={(e) => onSelectModel(findSSTModels()[e.target.value])}
        select>
          {Object.values(findSSTModels()).map((m) => (
            <MenuItem key={m.key} value={m.key}>&nbsp;{m.name}&nbsp;</MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  }

  return (<></>);
};
