import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import { LineChart } from '@mui/x-charts/LineChart';
import { ConversationDTO } from '../api/types/conversation';
import { toTimeString } from '../lib/utilities';

type DataPoint = {
  score1: number;
  score2: number;
  timestamp: number;
  bot: string;
  you: string;
  feedback1: string;
  feedback2: string;
};

let dataset: DataPoint[];

let keyToLabel: { [key: string]: string } = {};

const SkillColors: Record<string, { dark: string; light: string }> = {
  Empathy: {
    dark: "#F16BC9",
    light: "#FAC6EA",
  },
  Clarity: {
    dark: "#45C188",
    light: "#B7E7D1",
  },
  'Active Listening': {
    dark: "#F59E0B",
    light: "#FCE1B3",
  },
  Leadership: {
    dark: "#2970FF",
    light: "#BDD3FF",
  },
  Tolerance: {
    dark: "#AE7008",
    light: "#F8BE5C",
  },
  Patience: {
    dark: "#6038E1",
    light: "#D1C3FD",
  },
  Negotiation: {
    dark: "#03CEC0",
    light: "#B1F0EB",
  }
}

interface ChartProps {
  label1: string;
  color1: string;
  label2: string;
  color2: string;
}

const DoubleCircle = ({ color }: { color: string }) => {
  return (
    <Box
      width={14}
      height={14}
      borderRadius="50%"
      bgcolor="transparent"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ pr: 2 }}
    >
      {/* Outer circle */}
      <Box
        width={14}
        height={14}
        borderRadius="50%"
        border={`1px solid ${color}`}
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
      >
        {/* Inner circle */}
        <Box
          width={8}
          height={8}
          borderRadius="50%"
          bgcolor={color}
          display="flex"
          justifyContent="center"
          alignItems="center"
        />
      </Box>
    </Box>
  );
};

const LegendItem = ({ label1, color1, label2, color2 }: ChartProps) => {
  return (
    <Box display="flex" sx={{ pt: 1, pr: 6 }}>

      <DoubleCircle color={color1} />
      <Typography variant="caption" sx={{ mr: 4 }}>{label1}</Typography>

      <DoubleCircle color={color2} />
      <Typography variant="caption">{label2}</Typography>
    </Box>
  );
};

interface DataElement {
  score1: number;
  score2: number;
  bot: string;
  you: string;
  feedback1: string;
  feedback2: string;
}


interface TooltipContentProps {
  itemData: {
    id: string;
    dataIndex: number;
  };
  series: {
    label: string;
    data: DataElement[]
  };
}

const SkillIcons: Record<string, string> = {
  Empathy: '/skills/empathy.svg',
  Clarity: '/skills/clarity.svg',
  'Active Listening': '/skills/active_listening.svg',
  Leadership: '/skills/leadership.svg',
  Tolerance: '/skills/tolerance.svg',
  Patience: '/skills/patience.svg',
  Negotiation: '/skills/negotiation.svg',
};

const CustomItemTooltipContent = (props: TooltipContentProps) => {
  const { itemData, series } = props;

  const hoveredElement = dataset[itemData.dataIndex];

  if (hoveredElement) {
    const label = series.label as keyof DataPoint; // Type assertion

    return (
      <Paper sx={{ p: 2, width: '250px' }}>

        <Box sx={{ display: 'flex', alignItems: 'top', fontWeight: 'bold' }}>
          <img src={SkillIcons[series.label]} alt={series.label}
               style={{ marginTop: '1px', width: '20px', height: '20px' }} />
          <Typography variant="body2" color="textSecondary" sx={{ paddingLeft: '5px' }}>
            <Typography variant="body2" component="span" sx={{ fontWeight: 'bold' }}>
              {label}: {label === keyToLabel['score2'] ? hoveredElement['score2'] : hoveredElement['score1']}
            </Typography>
          </Typography>
        </Box>

        <Typography variant="body2" sx={{ fontSize: 'small', pt: 2 }}>
          {label === keyToLabel['score2'] ? hoveredElement.feedback2 : hoveredElement.feedback1}
        </Typography>

        <Divider sx={{ mt: 2, mb: 2 }}/>
        <Chip
          label={toTimeString(hoveredElement.timestamp)}
          size="small"
          sx={{ mb: 2, height: '20px', fontSize: '0.7rem' }}
        />
        {hoveredElement.bot &&
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', pb: 1 }}>
          <Typography variant="body2" sx={{ fontSize: 'small', width: '40px', flexShrink: 0, fontWeight: 'bold' }}>Bot:</Typography>
          <Typography variant="body2" sx={{ fontSize: 'small', flex: 1 }}>{hoveredElement.bot}</Typography>
        </Box>
        }
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <Typography variant="body2" sx={{ fontSize: 'small', width: '40px', flexShrink: 0, fontWeight: 'bold' }}>You:</Typography>
          <Typography variant="body2" sx={{ fontSize: 'small', flex: 1 }}>{hoveredElement.you}</Typography>
        </Box>
      </Paper>
    );
  }

  // If the hoveredElement is not found, return null or a message
  return null;
};


export default function Chart({ label1, color1, label2, color2, rawdata }: ChartProps & { rawdata: ConversationDTO }) {
  const timestamps: number[] = [];
  keyToLabel = {
    score1: rawdata.scoringMetrics[0],
    score2: rawdata.scoringMetrics[1],
  };
  dataset = rawdata.responses?.filter(item => !item.isBot)?.map((item, index) => {
    const timestamp = item.createdAt.getTime();
    timestamps.push(timestamp); // Add timestamp to timestamps array
    const botMessageIndex = index*2 - 1; // Calculate the index for the bot message, assumes user -> bot -> user -> bot
    let botMessage = '';
    if (rawdata.responses?.[botMessageIndex]?.isBot) { // Double check it is a bot message
      botMessage = rawdata.responses?.[botMessageIndex]?.statement;
    }
    let score1 = 0;
    let score2 = 0;
    let feedback1 = '';
    let feedback2 = '';

    // Generate keyToLabel dynamically based on ScoringMetrics
    keyToLabel = {};

    const scores = [0,0];
    const feedbacks = ['',''];
    rawdata.scoringMetrics.forEach((skill, i) => {
      const scoreKey = `score${i + 1}`;
      keyToLabel[scoreKey] = skill;

      if (skill === 'Empathy') {
        scores[i] = item.empathy ?? 0;
        feedbacks[i] = item.empathyReasoning ?? '';
      } else if (skill === 'Clarity') {
        scores[i] = item.clarity ?? 0;
        feedbacks[i] = item.clarityReasoning ?? '';
      } else if (skill === 'Active Listening') {
        scores[i] = item.activeListening ?? 0;
        feedbacks[i] = item.activeListeningReasoning ?? '';
      }
    });

    return {
      score1: scores[0],
      score2: scores[1],
      timestamp,
      bot: botMessage,
      you: item.statement,
      feedback1: feedbacks[0],
      feedback2: feedbacks[1],
    };
  }) ?? [];
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body1" fontWeight="bold">Conversation Timeline</Typography>
        <LegendItem label1={label1} color1={color1} label2={label2} color2={color2} />
      </Box>

      <LineChart
        xAxis={[
          {
            id: 'Timestamp',
            data: timestamps,
            scaleType: 'time',
            // dataKey: 'timestamp',
            valueFormatter: toTimeString
          },
        ]}
        skipAnimation
        series={Object.keys(keyToLabel).map((key) => {
          return {
            dataKey: key,
            label: keyToLabel[key],
            color: SkillColors[keyToLabel[key]].dark,
            showMark: true,
          };
        })}
        dataset={dataset}
        grid={{ vertical: false, horizontal: true }}
        height={300}
        sx={{
          width: '100%',
        }}
        slotProps={{
          legend: { hidden: true },
        }}
        tooltip={{ trigger: "item", itemContent: CustomItemTooltipContent }}
        yAxis={[{ min: 0, max: 100 }]}
      />

    </>

  );
}
