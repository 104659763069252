import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { trackEvent } from '../../lib/tracking';
import Cookies from 'js-cookie';

const TopBar = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const scrollToSection = (id: string) => {
    const tabName = 'Landing ' + id + ' tab';
    trackEvent('Button', 'Click', tabName);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleLoginClick = () => {
    trackEvent('Button', 'Click', 'Landing Log In');
    navigate('/scenarios');
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (id: string) => {
    scrollToSection(id);
    handleMenuClose();
  };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar>
        <Box
          component="img"
          src="/logo_blacktext.svg"
          alt="Mindbloom"
          sx={{ height: 40, marginRight: 4 }}
        />
        <Box sx={{ flexGrow: 1 }} />
        {!isSmallScreen ? (
          <>
            <Tabs value={selectedTab} onChange={handleChange} textColor="inherit">
              <Tab label="Product" onClick={() => scrollToSection('product')} />
              <Tab label="Science" onClick={() => scrollToSection('science')} />
              <Tab label="FAQ" onClick={() => scrollToSection('faq')} />
              <Tab label="Contact" onClick={() => scrollToSection('contact')} />
            </Tabs>
            <Button color="primary" variant="contained" sx={{ marginLeft: 2 }} onClick={handleLoginClick}>
              Log In
            </Button>
          </>
        ) : (
          <>
            <IconButton
              edge="start"
              color="primary"
              aria-label="menu"
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMenuItemClick('product')}>Product</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('science')}>Science</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('faq')}>FAQ</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('contact')}>Contact</MenuItem>
              <MenuItem onClick={handleLoginClick}>Log In</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
