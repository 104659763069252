import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Stats = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, textAlign: 'center', pb: 8 }}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={4} sx={{ borderRight: { md: '1px solid #e0e0e0' }, p: { md: 2, xs: 3 }, pr: { md: 2 } }}>
          <Typography variant="h3" component="div" sx={{ fontWeight: 'bold' }}>
            50%
          </Typography>
          <Typography variant="h6" component="div" sx={{ pb: 1, fontWeight: 'bold' }}>
            performance gain
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Emotionally intelligent companies outperform competitors by 50%
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} sx={{ borderRight: { md: '1px solid #e0e0e0' }, p: { md: 2, xs: 3}, pr: { md: 2 }, pl: { md: 2 } }}>
          <Typography variant="h3" component="div" sx={{ fontWeight: 'bold' }}>
            80%
          </Typography>
          <Typography variant="h6" component="div" sx={{ pb: 1, fontWeight: 'bold' }}>
            difference between top/bottom leaders
          </Typography>
          <Typography variant="body2" color="text.secondary">
            80% of the difference between top leaders and bottom leaders is their emotional competence
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} sx={{ p: { md: 2, xs: 3 }, pl: { md: 2 } }}>
          <Typography variant="h3" component="div" sx={{ fontWeight: 'bold' }}>
            50%
          </Typography>
          <Typography variant="h6" component="div" sx={{ pb: 1, fontWeight: 'bold' }}>
            increase employee retention
          </Typography>
          <Typography variant="body2" color="text.secondary">
            50% increased employee retention in high EI companies
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Stats;
