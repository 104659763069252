import * as React from 'react';
import Typography from '@mui/material/Typography';

const SkillIcons: Record<string, string> = {
  Empathy: "/skills/empathy.svg",
  Clarity: "/skills/clarity.svg",
  'Active Listening': "/skills/active_listening.svg",
  Leadership: "/skills/leadership.svg",
  Tolerance: "/skills/tolerance.svg",
  Patience: "/skills/patience.svg",
  Negotiation: "/skills/negotiation.svg",
}
interface SkillWithIconProps {
  skillName: string;
}
const SkillWithIcon: React.FC<SkillWithIconProps> = ({ skillName }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={SkillIcons[skillName]} alt={skillName} style={{ width: '20px', height: '20px' }} />
      <Typography variant="body2" fontWeight="bold" sx={{ pl: 1 }}>
        {skillName}
      </Typography>
    </div>
  );
};

export default SkillWithIcon;
