import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ResourcesIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1531_3066)">
      <path d="M20 18.3426C21.2813 17.9173 22.5947 17.6466 24 17.4893" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 17.4907C9.40533 17.6467 10.7187 17.9187 12 18.3441" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24 12.1572C22.5947 12.3132 21.2813 12.5852 20 13.0106" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 13.0093C10.7187 12.584 9.40533 12.3133 8 12.156" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 8.17478C12.5413 6.23745 9.45067 5.46678 5.356 5.33478C4.612 5.30945 4 5.92278 4 6.66811V22.4721C4 23.1934 4.57733 23.7921 5.29867 23.8028C9.42133 23.8641 12.5253 24.6241 16 26.6668C19.4747 24.6241 22.5787 23.8641 26.7013 23.8028C27.4227 23.7921 28 23.1934 28 22.4708V6.66678C28 5.92145 27.388 5.30945 26.644 5.33345C22.5493 5.46678 19.4587 6.23745 16 8.17478Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M16 8.17334V26.6667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
    </g>
    <defs>
      <clipPath id="clip0_1531_3066">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ResourcesIcon;
