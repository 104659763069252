import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import authReducer from './slices/authSlice';
import conversationsSlice from './slices/conversationsSlice';
import conversationReducer from './slices/conversationSlice'; // Import conversation reducer

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    conversations: conversationsSlice,
    conversation: conversationReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
