import * as React from 'react';

import AppDrawer from '../components/AppDrawer';


function Dashboard() {

  const mainContent = (
    <>
    </>
  );

  return (
    <AppDrawer pageName="Dashboard" mainContent={mainContent} />
  );
}

export default Dashboard;