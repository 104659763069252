import { WebkitSpeechToText, WebkitSpeechToTextConfig } from './impl/webkit-speech-to-text';
import { WhisperSpeechToText, WhisperSpeechToTextConfig } from './impl/whisper-speech-to-text';

export type OnTranscriptEvent = (transcription: string, isFinal: boolean) => void;

const DEFAULT_WHISPER_TIMESLICE = 400;
const DEFAULT_WHISPER_MIN_DECIBELS = -85;
const DEFAULT_WHISPER_SILENCE_THRESHOLD = -50;
const DEFAULT_SILENCE_DURATION = 2500;
const SHORT_SENTENCE_LENGTH = 10;
const SHORT_SENTENCE_SILENCE_FACTOR = 2;

export enum Language {
  English = 'en',
  French = 'fr',
  Spanish = 'es',
  German = 'de',
  Italian = 'it',
  Dutch = 'nl',
  Russian = 'ru',
  Mandarin = 'zh',
  Japanese = 'ja',
  Korean = 'ko',
  Arabic = 'ar',
  Hindi = 'hi',
  Portuguese = 'pt',
  Bengali = 'bn',
  Persian = 'fa',
  Turkish = 'tr',
  Polish = 'pl',
  Romanian = 'ro',
  Swedish = 'sv',
  Czech = 'cs',
  Greek = 'el',
  Danish = 'da',
  Finnish = 'fi',
  Hungarian = 'hu',
  Indonesian = 'id',
  Norwegian = 'no',
  Thai = 'th',
  Hebrew = 'he',
  Ukrainian = 'uk',
  Filipino = 'tl',
  Vietnamese = 'vi',
  Urdu = 'ur',
  Malaysian = 'ms',
  Bulgarian = 'bg',
  Croatian = 'hr',
  Estonian = 'et',
  Icelandic = 'is',
  Latvian = 'lv',
  Lithuanian = 'lt',
  Maltese = 'mt',
  Slovak = 'sk',
  Slovenian = 'sl',
  Welsh = 'cy',
  Serbian = 'sr',
  Albanian = 'sq',
  Armenian = 'hy',
  Basque = 'eu',
  Georgian = 'ka',
  Yiddish = 'yi'
};

export enum SpeechToTextType {
  WEBKIT = 0,
  WHISPER = 2,
};

export enum RecordingState {
  NOT_RECORDING,
  PAUSED,
  RECORDING,
  UNAVAILABLE
};

export interface SpeechToText {

  /**
   * Start the recording and transcription from speech to text.
   */
  start(): Promise<void>;

  /**
   * Pause the recording.
   */
  pause(): Promise<void>;

  /**
   * Resume a paused recording.
   */
  resume(): Promise<void>;

  /**
   * Stop the recording.
   */
  stop(): Promise<void>;

  /**
   * Get the current status of recording.
   */
  getState(): Promise<RecordingState>;

  onTranscript(transcriptionHandler: OnTranscriptEvent): void;
};


const getStringOrElse = (primary: string | undefined, defValue: string): string => {
  return (primary) ? primary : defValue;
};

const getNumberOrElse = (primary: string | undefined, defValue: number): number => {
  return (primary) ? parseInt(primary) : defValue;
};

const getBooleanOrElse = (primary: string | undefined, defValue: boolean): boolean => {
  return (primary) ? primary === 'true' : defValue;
};

const getDefaultASRUrl = (): string => {
  switch(window.location.hostname) {
    case `mindbloom.ai`:
    case `app.mindbloom.ai`:
    case `www.mindbloom.ai`:
      return `https://mindbloom.ai/asr`;

    case `ng-test.mindbloom.ai`:
      return `https://ng-test.mindbloom.ai/asr`;

    default:
      return 'http://localhost:9000/asr';
  }
};

/* Prepare the whisper configuration based on provided settings, environment settings or defaults.
 *
 * @param onTranscript The callback for when a transcript is received.
 * @param optProperties The optional properties to use for the WhisperSpeechToText instance.
 *
 * @returns The WhisperSpeechToTextConfig
 */
const createWhisperConfig = (onTranscript: OnTranscriptEvent, optProperties?: { [key: string]: string }): WhisperSpeechToTextConfig => {
  return {
    onTranscript: onTranscript,
    whisperEndpointUrl: getStringOrElse(optProperties?.whisperEndpointUrl, getDefaultASRUrl()),
    minDecibels: getNumberOrElse(optProperties?.whisperMinDecibels, DEFAULT_WHISPER_MIN_DECIBELS),
    silenceThreshold: getNumberOrElse(optProperties?.silenceThreshold, DEFAULT_WHISPER_SILENCE_THRESHOLD),
    silenceDuration: getNumberOrElse(optProperties?.silenceDuration, DEFAULT_SILENCE_DURATION),
    shortSentenceLength: getNumberOrElse(optProperties?.shortSentenceLength, SHORT_SENTENCE_LENGTH),
    shortSentenceSilenceFactor: getNumberOrElse(optProperties?.shortSentenceSilenceFactor, SHORT_SENTENCE_SILENCE_FACTOR),
    timeSlice: getNumberOrElse(optProperties?.timeSlice, DEFAULT_WHISPER_TIMESLICE),
    language: getStringOrElse(optProperties?.language, Language.English) as Language,
    wordTimestamps: getBooleanOrElse(optProperties?.wordTimestamps, false),
    deviceId: optProperties?.deviceId,
  };
};

/*
 * Prepare the webkit configuration based on provided settings, environment settings or defaults.
 *
 * @param onTranscript The callback for when a transcript is received.
 * @param optProperties The optional properties to use for the WebkitSpeechToText instance.
 *
 * @returns The WebkitSpeechToTextConfig
 */
const createWebkitConfig = (onTranscript: OnTranscriptEvent, optProperties?: { [key: string]: string }): WebkitSpeechToTextConfig => {
  return {
    onTranscript: onTranscript,
    silenceDuration: getNumberOrElse(optProperties?.silenceDuration, DEFAULT_SILENCE_DURATION),
    shortSentenceLength: getNumberOrElse(optProperties?.shortSentenceLength, SHORT_SENTENCE_LENGTH),
    shortSentenceSilenceFactor: getNumberOrElse(optProperties?.shortSentenceSilenceFactor, SHORT_SENTENCE_SILENCE_FACTOR),
    deviceId: optProperties?.deviceId,
  };
};

/**
 * Create a new SpeechToText instance based on the configuration.
 *
 * @param s2tType The type of SpeechToText to create (webkit or whisper)
 * @param onTranscript The callback for when a transcript is received.
 * @param optProperties The optional properties to use for the SpeechToText instance.
 *
 * @returns The SpeechToText instance.
 */
export const newSpeechToText = (s2tType: SpeechToTextType, onTranscript: OnTranscriptEvent, properties?: { [key: string]: string }): SpeechToText => {
  switch (s2tType) {
    case SpeechToTextType.WEBKIT:
      return new WebkitSpeechToText(createWebkitConfig(onTranscript, properties));

    case SpeechToTextType.WHISPER:
    default:
      return new WhisperSpeechToText(createWhisperConfig(onTranscript, properties));
  }
};

/**
 * Convert a string form of s2t type to SpeechToTextType. Defaults to WebKit
 *
 * @param configType The string form of the SpeechToTextType
 */
export const toSpeechToTextType = (configType?: string): SpeechToTextType => {
  switch ((configType || '').toLowerCase()) {
    case 'webkit':
      return SpeechToTextType.WEBKIT;

    case 'whisper':
    default:
      return SpeechToTextType.WHISPER;
  }
};

/**
 * Check whether speech to text is supported in this browser.
 *
 * @returns True if speech to text is supported, false otherwise.
 */
export const isSpeechToTextSupported = (configType?: string): boolean => {
  switch (toSpeechToTextType()) {
    case SpeechToTextType.WEBKIT:
      return (window.SpeechRecognition || window.webkitSpeechRecognition) !== undefined;

    case SpeechToTextType.WHISPER:
      return true;

    default:
      return false;
  }
};
