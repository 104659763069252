import React, { useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { GeneratedScenarioDTO } from '../api/types/scenario';
import { ScenarioCard, ScenarioCardEditFields } from './ScenarioCard';
import { ScenarioDetails, ScenarioDetailsEditFields } from './ScenarioDetails';
import { Feature, hasFeature } from '../lib/features';
import { RootState } from '../store';
import { useSelector } from 'react-redux';

export const ReviewCustomScenario = ({ scenario, onStart, onSave, onBack }: {
  scenario: GeneratedScenarioDTO,
  onStart: (s: GeneratedScenarioDTO) => void,
  onSave: (s: GeneratedScenarioDTO) => void,
  onBack: () => void
}) => {
  const [editScenario, setEditScenario] = useState(scenario);
  const auth = useSelector((state: RootState) => state.auth);
  const editSupported = hasFeature(Feature.EDIT_DYNAMIC_SCENARIOS, auth.features);

  const onEditCardMerge = (editSupported) ? (data: ScenarioCardEditFields) => {
    setEditScenario({
      ...editScenario,
      botAvatar: data.botAvatar
    });
  } : undefined;

  const onEditDetailsMerge = (editSupported) ? (data: ScenarioDetailsEditFields) => {
    setEditScenario({
      ...editScenario,
      name: data.title,
      objective: data.objective,
      brief: data.brief
    });
  } : undefined;

  return (
    <>
      <Typography id="modal-title" variant="h6" component="h2">
        Your Custom Generated Scenario
      </Typography>

      <ScenarioCard scenario={editScenario} onEdit={onEditCardMerge} />
      <Paper style={{maxHeight: 500, overflow: 'auto'}} variant="outlined">
        <ScenarioDetails scenario={editScenario} userName={auth.profile?.firstName + ' ' + auth.profile?.lastName} onEdit={onEditDetailsMerge} showBackstory={editSupported} showSeedTranscript={true} />
      </Paper>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="flex-start">
            <Button
              onClick={() => onBack()}
              variant="outlined"
              color="primary"
            >
              Back
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => onSave(editScenario)}
              variant="outlined"
              color="primary"
              sx={{ mr: 2 }}
            >
              Save
            </Button>

            <Button
              onClick={() => onStart(editScenario)}
              variant="contained"
              color="primary"
            >
              Start Conversation
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Navigator;
