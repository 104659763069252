import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SettingsIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32" fill="none">
    <path
      d="M8 25V18M8 14V7M16 25V16M16 12V7M24 25V20M24 16V7M5 18H11M13 12H19M21 20H27"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default SettingsIcon;
