import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MessageRenderer from './MessageRenderer';

export type Message = {
  content: string;
  isBot: boolean;
}

export type TranscriptProps = {
  pastTranscripts: Message[];
  liveTranscript: string;
  messagePending: boolean;
  botName: string;
  scrollable?: boolean
}

export const listStyles = { listStyleType: 'none', padding: 0, marginBlockStart: '0.4rem', marginBlockEnd: '1.4rem' }

const Transcript = ({pastTranscripts, liveTranscript, messagePending, botName, scrollable=true} : TranscriptProps) => {
  const scrollRef = useRef<HTMLLIElement>(null);
  useEffect(() => {
    if (scrollRef.current && scrollable) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [pastTranscripts, liveTranscript, scrollable]);

  return (
    <Box height={scrollable ? '100%' : 'auto'} overflow={scrollable ? 'auto' : 'inherit'} p={1}>
      <ul style={listStyles}>
        {pastTranscripts.map(({ isBot, content }, index) => {
          return (
            <li key={index}>
              <MessageRenderer isBot={isBot} content={content} name={ isBot ? botName : 'You'}/>
            </li>
          );
        })}

        {(liveTranscript && liveTranscript.length > 0) && (
          <li>
            <MessageRenderer isBot={false} content={liveTranscript} name="You"/>
          </li>
        )}
        {messagePending && (
          <li>
            <Box display="flex">
              <Box bgcolor="#F0F1F3" borderRadius="0 10px 10px 10px" color="#000" px={2} pt={2} pb={1}>
                <MoreHorizIcon />
              </Box>
            </Box>
          </li>
        )}
        <li ref={scrollRef} />
      </ul>
    </Box>
  )
}

export default Transcript