import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import SpeechIndicator from './SpeechIndicator';

const VideoPane = ({ videoStream }: { videoStream: MediaStream | null }) => {

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = videoStream;
    }
  }, [videoStream, videoRef.current]);

  return (
    <video ref={videoRef} width="100%" height="100%" autoPlay style={{
      objectFit: 'cover',
      position: 'absolute',
      borderRadius: 32,
      transform: 'rotateY(180deg)',
    }} />
  );
};

const micOffStyles = css({
  color: '#fff',
  background: 'rgba(0,0,0, 0.5)',
  borderRadius: 16,
  padding: '0.25rem 0.5rem',
  fontSize: '14px',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: 10,
  left: 10,
});

export type VideoProps = {
  isCameraOn: boolean;
  isListening: boolean;
  botAvatar?: string;
  botSpectrum: number[];
}

export const Video = ({ isCameraOn, isListening, botAvatar, botSpectrum }: VideoProps) => {
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    if (isCameraOn) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(videoStream => {
          setVideoStream(videoStream);
        })
        .catch(error => {
          console.error('Error accessing the camera:', error);
        });
    } else {
      if (videoStream != null) {
        videoStream.getTracks().forEach(track => track.stop());
      }
      setVideoStream(null);
    }
  }, [isCameraOn]);

  return (
    <Box flexGrow={1} p={2}>
      <Box bgcolor="#8f8f8f"
           borderRadius={4}
           position="relative"
           overflow="hidden"
           display="flex" justifyContent="center" alignItems="center"
           height="100%"
      >
        <Avatar alt="Avatar" src="/noimage.svg" sx={{ width: 120, height: 120 }} />
        {isCameraOn && <VideoPane videoStream={videoStream} />}
        <Box position="absolute" bottom={20} right={25}>
          {
            botAvatar ?
              <img src={botAvatar} alt="Bot Avatar" height={100} width={100} style={{
                border: '4px solid #E9E9E9', borderRadius: 8,
                objectFit: 'cover',
              }} /> :
              <Avatar alt="Bot Avatar" src={botAvatar} sx={{ width: 60, height: 60 }} />
          }
          {botAvatar && <span style={{
            position: 'absolute',
            bottom: 15,
            right: 10,
          }}>
            {botSpectrum.length > 0 && <SpeechIndicator spectrum={botSpectrum} barsCount={3} />}
          </span>}

        </Box>

        {!isListening && (
          <Box css={micOffStyles}>
            <MicOffOutlinedIcon />
            {/*<span>Microphone off</span>*/}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Video;
