// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AppDrawer from '../components/AppDrawer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { Box, Button, Grid, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Video from '../components/conversations/Video';
import { trackEvent } from '../lib/tracking';
import { produce } from 'immer';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import VolumeMute from '@mui/icons-material/VolumeMute';
import VolumeOff from '@mui/icons-material/VolumeOff';

import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import ConversationSummary from '../components/conversations/ConversationSummary';
import Transcript, { Message } from '../components/conversations/Transcript';
import { useSpeechRecognition } from '../components/conversations/speechRecognition';
import api from '../api';
import { ConversationDTO } from '../api/types/conversation';
import { findLastIndex, last } from 'lodash';
import { setConversation } from '../slices/conversationSlice';
import { retrieveSSTModel, saveSSTModel, SSTModel, SSTModelSelector } from '../components/STTModelSelector';
import Cookies from 'js-cookie';

// @ts-expect-error
import outputProcessorUrl from '../audio/worklet/output-processor?url';

const APP_BAR_HEIGHT = 64;
const ERROR_INVALID_CONVERSATION = 'Invalid conversation: No user is logged and conversation is not publicly accessible.';
const buttonStyles = {
  borderRadius: 20,
  padding: '10px 20px',
  margin: '0px 5px',
  height: '40px',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#140048',
  },
  '&.Mui-disabled': {
    backgroundColor: '#ccc',
    color: '#7f7f7f',
  },
};

type ConversationStatus = 'NOT_STARTED' | 'IN_PROGRESS' | 'FINISHED';
type BotStatus = 'IDLE' | 'RESPONDING' | 'RESPONDED' | 'TALKING';

//let audioContext.current: AudioContext | null = null;
let analyser: AnalyserNode | null = null;



// Get the audio stream for the text for UUID.
const generateAudioStream = async (loggedIn: boolean, uuid: string, text: string, voice: string, correlationId?: string): Promise<ArrayBuffer> => {
  if (loggedIn) {
    return api.generateAudioStream(uuid, {
      text: text,
      voice: voice,
    });
  } else if (correlationId) {
    return api.publicGenerateAudioStream(uuid, correlationId, {
      text: text,
      voice: voice,
    });
  } else {
    return Promise.reject(ERROR_INVALID_CONVERSATION);
  }
};

// Converse with the bot for given UUID
const converseWithBot = (loggedIn: boolean, uuid: string, transcript: string, correlationId?: string): Promise<ConversationDTO> => {
  if (loggedIn) {
    return api.converse(uuid, transcript);
  } else if (correlationId) {
    return api.publicConverse(uuid, correlationId, transcript);
  } else {
    return Promise.reject(ERROR_INVALID_CONVERSATION);
  }
};

// Retreive the conversation by UUID
const getConversation = (loggedIn: boolean, uuid: string, correlationId?: string): Promise<ConversationDTO> => {
  if (loggedIn) {
    return api.getConversation(uuid);
  } else if (correlationId) {
    return api.getPublicConversation(uuid, correlationId);
  } else {
    return Promise.reject(ERROR_INVALID_CONVERSATION);
  }
};

function Conversation() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const theme = useTheme();
  const features = useSelector((state: RootState) => state.auth.features);
  const conversation = useSelector((state: RootState) => state.conversation.conversation);
  const { uuid = '', correlationUuid = '' } = useParams();
  const loggedIn = Cookies.get('isAuthenticated') === 'true';

  const [callStatus, setCallStatus] = useState<ConversationStatus>('NOT_STARTED');
  const [isMicEnabled, setMicEnabled] = React.useState(false);
  const [isCameraOn, setIsCameraOn] = React.useState(false);
  const [isSpeakerOn, setSpeakerOn] = React.useState(true);
  const [selectedTab, setSelectedTab] = useState('summary');

  const [pastTranscript, setPastTranscript] = useState<Message[]>([]);
  const [liveTranscript, setLiveTranscript] = useState<string>('');
  const [botResponse, setBotResponse] = useState<ConversationDTO | null>(null);
  const [botStatus, setBotStatus] = useState<BotStatus>('IDLE');

  const [botSpectrum, setBotSpectrum] = useState<number[]>([]);
  const [sttModel, setSTTModel] = useState<SSTModel>(retrieveSSTModel(features));

  const audioContext = useRef<AudioContext | null>(null);
  const audioElement = useRef<AudioElement | null>(null);

  const speechRecognition = useSpeechRecognition(sttModel.code, (transcript: string, isFinal: boolean) => {
    if (isFinal && transcript.length > 0) {
      setPastTranscript(produce(pastTranscript, draft => {
        draft.push({ content: transcript, isBot: false });
      }));
      setLiveTranscript('');
      setBotStatus('RESPONDING');
      converseWithBot(loggedIn, uuid, transcript, correlationUuid).then((conversation: ConversationDTO) => {
        setBotResponse(conversation);
        setBotStatus('RESPONDED');
      });
    } else {
      setLiveTranscript(transcript);
    }
  });


  const playAudioStream = async (loggedIn, uuid, text, voice, correlationId) => {
    try {
      const audioStream = await api.generateLiveAudioStream(uuid, { text, voice });
      if (audioStream) {
        return new Promise(async (resolve) => {
          try {
            audioElement.current = new Audio();


            const mediaSource = new MediaSource();
            audioElement.current.src = URL.createObjectURL(mediaSource);
            audioElement.current.autoplay = true;

            mediaSource.addEventListener('sourceopen', () => {
              const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
              sourceBuffer.mode = 'sequence';

              let queue = [];
              let isAppending = false;
              let isEnded = false;

              sourceBuffer.addEventListener('updateend', () => {
                isAppending = false;

                if (queue.length > 0) {
                  appendToSourceBuffer(queue.shift());
                } else if (isEnded) {
                  if (!sourceBuffer.updating && mediaSource.readyState === 'open') {
                    mediaSource.endOfStream();
                  }
                }
              });

              sourceBuffer.addEventListener('error', (e) => {
                console.error('SourceBuffer error:', e);
                setBotStatus('IDLE');
                resolve(null);
              });

              mediaSource.addEventListener('error', (e) => {
                console.error('MediaSource error:', e);
                setBotStatus('IDLE');
                resolve(null);
              });

              const appendToSourceBuffer = (audioData) => {
                if (isAppending || sourceBuffer.updating) {
                  queue.push(audioData);
                } else {
                  isAppending = true;
                  sourceBuffer.appendBuffer(audioData);
                }
              };

              const reader = audioStream.getReader();

              const processChunk = async () => {
                const { value, done } = await reader.read();
                if (done) {
                  isEnded = true;

                  if (!sourceBuffer.updating && queue.length === 0 && mediaSource.readyState === 'open') {
                    mediaSource.endOfStream();
                  }
                  return;
                }
                if (value) {
                  appendToSourceBuffer(value.buffer);
                  setBotStatus('TALKING');

                  audioElement.current.play();
                }

                await processChunk();
              };

              processChunk();
            });

            audioElement.current.addEventListener('ended', () => {
              setBotStatus('IDLE');
              resolve(null);
            });

          } catch (error) {
            console.error('Error playing audio stream', error);
            setBotStatus('IDLE');
            resolve(null);
          }
        });
      } else {
        setBotStatus('IDLE');
        return Promise.resolve();
      }
    } catch (error) {
      console.error('Error in playAudioStream', error);
      setBotStatus('IDLE');
      return Promise.resolve();
    }
  };

  useEffect(() => {
    if (uuid) {
      getConversation(loggedIn, uuid, correlationUuid).then(conversation => {
        const serializedConversation = {
          ...conversation,
          createdAt: conversation.createdAt.toISOString(),
          responses: conversation.responses?.map(response => ({
            ...response,
            createdAt: response.createdAt.toISOString(), // Serialize createdAt within responses
          })),
        };
        dispatch(setConversation(serializedConversation));
      }).catch((err) => {
        // Soft catch because it takes time to for the system to determine the user is actually logged in.
      });
    }
  }, [uuid, correlationUuid]);

  useEffect(() => {
    if (botResponse != null) {
      const lastResponse = last(botResponse.responses);
      if (lastResponse) {
        setPastTranscript(produce(pastTranscript, draft => {
          draft.push({ content: lastResponse.statement, isBot: true });
        }));

        if (isSpeakerOn) {
          playAudioStream(loggedIn, uuid, lastResponse.statement, botResponse.botVoice, correlationUuid);
        } else {
          setBotStatus('IDLE');
        }
      } else {
        setBotStatus('IDLE');
        throw new Error('No response from bot.');
      }
    }
  }, [botResponse]);

  // This is the logic to control whether we have transcript.
  useEffect(() => {
    const speechRecognitionEnabled = botStatus === 'IDLE' && isMicEnabled;

    speechRecognition.setEnabled(speechRecognitionEnabled);
  }, [botStatus, isMicEnabled]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (analyser != null) {
        const binCount = analyser.frequencyBinCount;
        const array = new Uint8Array(binCount);
        analyser.getByteFrequencyData(array);
        setBotSpectrum(Array.from(array).slice(25, 290)); // 300Hz - 3400 Hz for voice
      }
    }, 100);
    return () => {
      clearInterval(intervalId);
    };
  }, [analyser]);

  const seedConversation = useMemo(() => conversation?.responses?.filter(r => r.isSeed) ?? [], [conversation]);
  const botName = useMemo(() => (conversation?.botName ?? '').split(' ')[0], [conversation]);

  const stopAudioContext = () => {
    if (audioElement.current !== null) {
      audioElement.current.pause();
    }

    if (audioContext.current !== null) {
      audioContext.current.close();
    }
  }

  const handleStartCall = useCallback(async () => {
    setCallStatus('IN_PROGRESS');
    setSelectedTab('transcript');
    const lastBotResponseIndex = findLastIndex(seedConversation, conv => conv.isBot);
    const lastBotResponse = seedConversation[lastBotResponseIndex];
    if (lastBotResponse) {
      setPastTranscript(produce(pastTranscript, draft => {
        draft.push({ content: lastBotResponse.statement, isBot: true });
      }));

      if (isSpeakerOn) {
        await playAudioStream(loggedIn, uuid, lastBotResponse.statement, conversation!.botVoice, correlationUuid);
      }
    }
    speechRecognition.start();
    setMicEnabled(true);
  }, [seedConversation, pastTranscript, isSpeakerOn]);

  // If the user navigates away from the page, we should stop the recorder.
  useEffect(() => {
    return () => {
      setIsCameraOn(false);
      speechRecognition.stop().then(stopAudioContext);
    };
  }, []);

  const handleEndCall = useCallback(() => {
    setCallStatus('FINISHED');
    setIsCameraOn(false);
    speechRecognition.stop()
      .then(stopAudioContext)
      .then(() => {
        if (correlationUuid && correlationUuid.length > 0) {
          navigate(`/conversation-end/${uuid}/${correlationUuid}`);
        } else {
          navigate(`/conversation-end/${uuid}`);
        }
      });
  }, [navigate]);

  const mainContent = (
    <Grid container height={`calc(100dvh - ${APP_BAR_HEIGHT}px)`} p={2}>
      <Grid item xs={12} md={9} pr={2} minHeight={300}>
        <Box display="flex" flexDirection="column" height="100%">
          <Video isCameraOn={isCameraOn} isListening={callStatus === 'NOT_STARTED' || speechRecognition.listening}
            botAvatar={conversation?.botAvatar} botSpectrum={botSpectrum} />
          <Box display="flex" justifyContent="center" alignItems="center" flexGrow={0}>
            <SSTModelSelector model={sttModel} disabled={callStatus !== 'NOT_STARTED'} onSelectModel={(model: SSTModel) => {
              setSTTModel(model);
              saveSSTModel(model);
            }} />

            <IconButton
              sx={{
                ...buttonStyles,
                backgroundColor: !isMicEnabled ? '#EA4335' : theme.palette.primary.main,
              }}
              disabled={callStatus !== 'IN_PROGRESS'}
              onClick={() => setMicEnabled(!isMicEnabled)}
            >
              {!isMicEnabled ? <MicOffOutlinedIcon /> : <MicNoneOutlinedIcon />}
            </IconButton>

            <IconButton
              sx={{
                ...buttonStyles,
                backgroundColor: !isSpeakerOn ? '#EA4335' : theme.palette.primary.main,
              }}
              onClick={() => setSpeakerOn(!isSpeakerOn)}
            >
              {!isSpeakerOn ? <VolumeOff /> : <VolumeMute />}
            </IconButton>
            <IconButton
              sx={{
                ...buttonStyles,
                backgroundColor: isCameraOn ? theme.palette.secondary.main : '#EA4335',

              }}
              disabled={callStatus !== 'IN_PROGRESS'}
              onClick={() => setIsCameraOn(!isCameraOn)}
            >
              {isCameraOn ? <VideocamOutlinedIcon /> : <VideocamOffOutlinedIcon />}
            </IconButton>
            <Button
              startIcon={<PhoneOutlinedIcon />}
              sx={{
                ...buttonStyles,
                backgroundColor: callStatus === 'NOT_STARTED' ? theme.palette.primary.main : '#EA4335',
              }}
              disabled={callStatus === 'FINISHED'}
              onClick={() => {
                switch (callStatus) {
                  case 'NOT_STARTED':
                    if (!loggedIn) {
                      api.ping('trial');
                    }
                    trackEvent('Button', 'Click', 'Start Call');
                    handleStartCall();
                    break;
                  case 'IN_PROGRESS':
                    trackEvent('Button', 'Click', 'End Call');
                    handleEndCall();
                    break;
                }
              }}
            >
              {callStatus === 'NOT_STARTED' ? 'Continue' : 'End Call and Get Analysis'}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={3} pr={2} pt={2} height="100%" overflow="hidden">
        <Box
          height="100%"
          borderRadius={4}
          bgcolor="#ffffff"
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <ToggleButtonGroup
              value={selectedTab}
              exclusive
              onChange={(event, value) => value && setSelectedTab(value)}
              sx={{
                py: 1,
                '& .MuiToggleButton-root': {
                  textTransform: 'capitalize',
                  fontSize: '0.75rem',
                  padding: '4px 8px',
                },
              }}
            >
              <ToggleButton value="summary">
                Summary
              </ToggleButton>
              <ToggleButton value="transcript">
                Live Transcription
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box height="calc(100% - 48px)" overflow="auto">
            {selectedTab === 'summary' &&
              <ConversationSummary seedConversation={seedConversation} conversationBrief={conversation?.brief}
                botName={conversation?.botName} />}
            {selectedTab === 'transcript' &&
              <Transcript pastTranscripts={pastTranscript} liveTranscript={liveTranscript}
                messagePending={botStatus === 'RESPONDING'} botName={botName} />}
          </Box>
        </Box>

      </Grid>
    </Grid>
  );

  return (
    <AppDrawer pageName="Conversation" mainContent={mainContent} loginRedirect={location.pathname} />
  );
}

export default Conversation;
