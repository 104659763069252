import { useEffect, useState } from "react";
import { Alert, Box, Button, FormControl, Link, Paper, TextField, Typography, useTheme } from "@mui/material";
import AppDrawer from "../components/AppDrawer";
import api from '../api';
import HelpOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";

export const ForgottenPassword = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [username, setUsername] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const doPasswordReset = async (username: string) => {
      try {
        await api.resetPassword(username);
        setSuccess(true);
      } catch (err) {
        setErrorMessage('Could not reset password. Please contact support@mindbloom.ai')
      }
    }
    if (username) {
      doPasswordReset(username);
    }
  }, [username])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);

    const username = form.get('username')?.toString();
    if (username && username.length) {
      setUsername(username);
    } else {
      setErrorMessage('Please enter a username or email address');
    }
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const mainContent = (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: '20%',
          left: '50%',
          transform: 'translate(-50%, 0%)',
          width: '400px',
          height: 'auto'
        }}
      >
        <Paper sx={{ p: 3, borderRadius: '8px' }}>
          {!success && (
            <>
              {errorMessage && (
                <Alert severity="error" sx={{ my: '1rem' }}>{errorMessage}</Alert>
              )}
              <Typography variant="h6" sx={{ pb: 1 }}>
                Forgotten Password
              </Typography>
              <Typography variant="body2">
                Forgotten your password? We'll send you an email to create a new password.
              </Typography>
              <Box sx={{ textAlign: 'center' }}>
                <form onSubmit={handleSubmit}>
                  <FormControl fullWidth sx={{ textAlign: 'center', mt: '1.5rem' }}>
                    <TextField
                      autoFocus
                      id="username-input"
                      name="username"
                      label="Username/Email"
                      error={!!errorMessage}
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    type="submit"
                    sx={{
                      margin: "1rem 0"
                    }}

                  >Reset Password</Button>
                </form>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="body2"> Already have an account? {' '}
                  <Link
                    component="span"
                    onClick={handleLoginClick}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    variant="body2"
                  >
                    Sign in
                  </Link>
                </Typography>
              </Box>
            </>
          )}
          {success && (
            <Box sx={{ textAlign: 'center' }}>
              <HelpOutlineIcon sx={{ fontSize: '40px', mb: '0.5rem' }} color="primary" />
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: '0.5rem' }}>Check your Email</Typography>
              <Typography sx={{ p: 1, color: `${theme.palette.grey}` }}>
                Please check your email for instructions on how to reset your password.
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );

  return (
    <AppDrawer pageName="" mainContent={mainContent} />
  );
}
