import React, { FormEvent, useState } from 'react';
import { Box, Button, FormControl, Paper, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import { css } from '@emotion/react';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../api';
import { AppDispatch } from '../store';
import { useDispatch } from 'react-redux';
import { setUser } from '../slices/authSlice';
import { setPages } from '../appSlice';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { trackEvent } from '../lib/tracking';
import { buildConvPassthruUrl } from '../lib/utilities';

const buttonStyles = css({
  textTransform: 'revert',
  minHeight: '48px',
  borderRadius: '8px'
});

const LoginForm = () => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [usernameHelperText, setUsernameHelperText] = useState<string>('');
  const [passwordHelperText, setPasswordHelperText] = useState<string>('');
  const [loginResponse, setLoginResponse] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { conversationUuid = '', correlationUuid = undefined } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    switch (event.target.name) {
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;
    }
  };

  const validate = () => {
    if (isEmpty(username)) {
      setUsernameHelperText('Please enter your username');
    }
    if (isEmpty(password)) {
      setPasswordHelperText('Please enter your password');
    }
    return !isEmpty(username) && !isEmpty(password);
  };

  const handleSignUpClick = () => {
    navigate(buildConvPassthruUrl('/signup', conversationUuid, correlationUuid), { state: { next: `/report/${conversationUuid}` } });
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    trackEvent('Button', 'Click', 'Login');
    const valid = validate();
    if (valid) {
      const publicConvDetails = (conversationUuid && correlationUuid) ? { conversationUuid, correlationUuid } : undefined;
      api.authenticate(username, password, publicConvDetails).then((auth) => {
        const { token, profile, features, pages } = auth;
        dispatch(
          setUser({
            profile: profile,
            features: features
          }),
        );
        dispatch(setPages(pages));
        setLoginResponse('');
        // const navPages = pages.filter((page: Page) => page.nav);
        // if (navPages.length > 0) {
        //   navigate(navPages[0].path);
        // }
        const nextRoute = location.state?.next;
        if (nextRoute) {
          navigate(nextRoute);
        } else {
          navigate('/scenarios');
        }

        return auth;
      }).catch((error) => {
        const message = (error as Error).message ?? 'Login failed';
        setLoginResponse(message);
      });
    }
  };

  const handleForgottenPasswordClick = () => {
    navigate('/forgotten-password');
  }

  return (
    <>
      <Box sx={{ mt: 3, mb: 2 }}>
        <Box>
          <Box component="form" onSubmit={handleSubmit}>
            <Box mb={2}>
              <FormControl fullWidth>
                <TextField
                  autoFocus
                  id="username-input"
                  name="username"
                  label="Username"
                  value={username}
                  onChange={handleInputChange}
                  helperText={usernameHelperText}
                  error={!!usernameHelperText}
                />
              </FormControl>
            </Box>
            <Box mb={2}>
              <FormControl fullWidth>
                <TextField
                  type="password"
                  id="password-input"
                  name="password"
                  label="Password"
                  value={password}
                  variant="outlined"
                  onChange={handleInputChange}
                  helperText={passwordHelperText}
                  error={!!passwordHelperText}
                />
              </FormControl>
              <Link
                component="div"
                onClick={handleForgottenPasswordClick}
                style={{ cursor: 'pointer', textDecoration: 'underline', margin: '0.5rem 0' }}
                variant="body2"
              >
                Forgot Password?
              </Link>
            </Box>
            <Box
              display="flex"
              flexDirection="row-reverse"
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                fullWidth
                color="primary"
                type="submit"
                css={buttonStyles}
              >
                Login
              </Button>
              {/*<Button size="small" variant={'text'} css={buttonStyles}>*/}
              {/*  Forgot password*/}
              {/*</Button>*/}
            </Box>
          </Box>
          <Snackbar
            open={loading}
            message="Login successful. Generating report..."
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
          <Snackbar
            open={loginResponse.length > 0}
            autoHideDuration={6000}
            onClose={() => {
              setLoginResponse('');
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Box maxWidth={300}>
              <Paper square>
                <Box borderLeft={`solid 4px ${theme.palette.error.main}`} p={1.5}>
                  <Typography>{loginResponse}</Typography>
                </Box>
              </Paper>
            </Box>
          </Snackbar>
        </Box>
      </Box>
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="body2"> Don't have an account yet? {' '}
            <Link
              component="span"
              onClick={handleSignUpClick}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              variant="body2"
            >
          Sign up
            </Link>
            </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginForm;
