import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildFeatureMap, FeatureMap } from '../lib/features';

export type UserProfile = {
  uuid: string;
  firstName: string;
  lastName: string;
};

export type FeatureAttr = {
  id: number;
  attribute: string;
  value: string;
};

export type AuthState = {
  profile?: UserProfile;
  // authToken?: string;
  permissions: string[];
  features: FeatureMap;
};

export const initialState: AuthState = {
  profile: undefined,
  // authToken: undefined,
  permissions: [],
  features: {}
};

export const toDisplayInitials = (userProfile?: UserProfile): string => {
  if (userProfile) {
    const firstInitial = userProfile.firstName.substring(0, 1).toUpperCase();
    const lastInitial = userProfile.lastName.substring(0, 1).toUpperCase();
    return `${firstInitial}${lastInitial}`;
  } else {
    return '?';
  }
};

export const toDisplayName = (userProfile?: UserProfile): string => {
  return (userProfile) ? `${userProfile.firstName} ${userProfile.lastName}` : '?';
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ profile?: UserProfile, features?: FeatureAttr[] }>
    ) => {
      const { profile, features} = action.payload;
      state.profile = profile;
      state.features = buildFeatureMap(features);

      // TODO: extract permissions from token.
    },
    clearUser: (state) => {
      state.profile = undefined;
      state.permissions = [];
      state.features = { };
    },
  },
});

export const { setUser, clearUser } = authSlice.actions;
export default authSlice.reducer;
