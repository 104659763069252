import React from 'react';
import Box from '@mui/material/Box';

import TopBar from '../components/landing/TopBar';
import Hero from '../components/landing/Hero';
import TrainTalkLearn from '../components/landing/TrainTalkLearn';
import Stats from '../components/landing/Stats';
import HowItWorks from '../components/landing/HowItWorks';
import Footer from '../components/landing/Footer';
import FAQ from '../components/landing/FAQ';
import Science from '../components/landing/Science';
import Contact from '../components/landing/Contact';
import GoodManager from '../components/landing/GoodManager';

const LandingPage = () => {
  return (
    <>
      <Box sx={{ background: 'linear-gradient(to right, #F5F9FF, #EEF4FF 13%, #E7EDF9 26%, #ffffff 100%)' }}>
        <TopBar />
        <Hero />
        <TrainTalkLearn />
        <Science />
        <Stats />
        <HowItWorks />
        <FAQ />
        <GoodManager />
        <Contact />
        <Footer />
      </Box>
    </>
  );
};

export default LandingPage;
