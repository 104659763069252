import { passwordStrength } from "check-password-strength";

export type PasswordComplexity = {
  isSufficientlyComplex: boolean;
  complexity: string;
  contains: string[];
  length: number;
};

export const isComplexPassword = (password: string): PasswordComplexity => {
  const c = passwordStrength(password);
  return {
    isSufficientlyComplex: c.value === 'Medium' || c.value === 'Strong',
    complexity: c.value,
    contains: c.contains,
    length: c.length
  };
};

export const buildConvPassthruUrl = (headParam: string, conversationUuid?: string, correlationUuid?: string): string => {
  if (conversationUuid) {
    if (correlationUuid) {
      return `${headParam}/${conversationUuid}/${correlationUuid}`;
    } else {
      return `${headParam}/${conversationUuid}`;
    }
  }

  return headParam;
};

export const toDateString = (timestamp: any): string => {
  const datetime = new Date(timestamp);
  return datetime.toLocaleDateString(undefined, { day: '2-digit', month: 'short', year: 'numeric'});
};

export const toDateTimeString = (timestamp: any): string => {
  const datetime = new Date(timestamp);
  return datetime.toLocaleDateString(undefined, { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit'});
};

export const toTimeString = (timestamp: any): string => {
  const datetime = new Date(timestamp);
  return datetime.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit', second: '2-digit'});
};

/**
 * An unbiased array shuffle.
 * https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 * @param array
 */
export function shuffleArray<T>(source: T[]): T[] {
  const array = [...source];
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
};

export function sortArray<T>(source: T[], name: string): T[] {
  return source.sort((a: any, b: any) => {
    if (a[name] < b[name]) {
      return -1;
    }
    if (a[name] > b[name]) {
      return 1;
    }
    return 0;
  });

};
