import { FeatureAttr } from '../slices/authSlice';

export enum Feature {
  VIEW_REPORT_SUMMARY = 'view-report-summary',
  VIEW_REPORT_DETAILS = 'view-report-details',
  ANONYMOUS_CONVERSATIONS = 'anonymous-conversations',
  DYNAMIC_SCENARIOS = 'dynamic-scenarios',
  EDIT_DYNAMIC_SCENARIOS = 'edit-dynamic-scenarios',
  STT_MODEL_SELECTION = 'stt-model-selection',
  GENERATE_CONVERSATION = 'generate-conversation',
  USE_STREAMING_AUDIO = 'use-streaming-audio'
};

export type FeatureMap = {[key:string]: boolean};

const DEFAULT_FEATURES = {
  [Feature.VIEW_REPORT_SUMMARY]: true,
  [Feature.VIEW_REPORT_DETAILS]: true,
  [Feature.ANONYMOUS_CONVERSATIONS]: true,
  [Feature.DYNAMIC_SCENARIOS]: false,
  [Feature.EDIT_DYNAMIC_SCENARIOS]: false,
  [Feature.STT_MODEL_SELECTION]: false,
  [Feature.GENERATE_CONVERSATION]: false,
  [Feature.USE_STREAMING_AUDIO]: false
};

export const buildFeatureMap = (featureAttrs?: FeatureAttr[]): FeatureMap => {
  const features: FeatureMap = {
    ...DEFAULT_FEATURES
  };

  (featureAttrs || []).forEach((f) => {
    const feature = f.attribute.toLowerCase().trim();
    const enabled = f.value.toLowerCase().trim() === 'true';
    switch (feature) {
      case Feature.VIEW_REPORT_SUMMARY:
      case Feature.VIEW_REPORT_DETAILS:
      case Feature.ANONYMOUS_CONVERSATIONS:
      case Feature.DYNAMIC_SCENARIOS:
      case Feature.EDIT_DYNAMIC_SCENARIOS:
      case Feature.STT_MODEL_SELECTION:
      case Feature.GENERATE_CONVERSATION:
      case Feature.USE_STREAMING_AUDIO:
        features[feature] = enabled;
        break;

      default:
        // Handled by the defaults.
        break;
    }
  });

  return features;
};

export const hasFeature = (feature: Feature, features: FeatureMap): boolean => {
  // Not necessary because defaults are already set3131, but thought I'd add it for completeness.
  return (features[feature] !== undefined) ? features[feature] : DEFAULT_FEATURES[feature];
};
