import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: ['Outfit', 'sans-serif'].join(','),
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#6A3EF7', //'#6a31fc',
      dark: '#140048',
      // contrastText: '#989FAD',
    },
    secondary: {
      light: '#F1F1F1',
      main: '#8F8F8F',
      dark: '#737373',
    },
    background: {
      default: '#f8f8f9',
    },
    action: {
      disabledBackground: '#aaa',
      disabled: '#3f33f'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    }
  },
});
export default theme;
