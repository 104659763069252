import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import api from '../api';
import { ConversationSortBy, ConversationSummaryDTO } from '../api/types/conversation';
import { MasterDataDTO } from '../api/types/master-data';
import { CourseDTO } from '../api/types/course';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { MetricScoreHeader, MetricScoreValue, ReportNameValue } from './ReportUtils';
import { toDateTimeString } from '../lib/utilities';

function filterReportsByScenario(courses: CourseDTO[], reports: ConversationSummaryDTO[], courseName: string): ConversationSummaryDTO[] {
  // Find the scenario UUIDs for the specified courseName
  const course = courses.find(course => course.name === courseName);
  if (!course) {
    return [];
  }
  const scenarioUUIDs = course.scenarios || [];

  // Filter reports based on the scenario UUIDs
  return reports.filter(report => scenarioUUIDs?.includes(report.scenario ?? ""));
}

const ReportTable = ({ courseName, scenarioUuid }: { courseName?: string; scenarioUuid?: string; }) => {
  const [reports, setReports] = React.useState<ConversationSummaryDTO[] | null>(null);
  const features = useSelector((state: RootState) => state.auth.features);

  React.useEffect(() => {
    async function fetchReports() {
      try {
        if (courseName) {
          const reports: ConversationSummaryDTO[] = await api.getConversations({}, ConversationSortBy.CREATED_AT_ASC);
          const masterData: MasterDataDTO = await api.getMasterData();
          // Filter reports for the current course name
          const filteredReports = filterReportsByScenario(masterData.courses, reports, courseName);
          setReports(filteredReports);
        } else if (scenarioUuid) {
          const reports: ConversationSummaryDTO[] = await api.getConversations({scenarios: [scenarioUuid]}, ConversationSortBy.CREATED_AT_ASC);
          setReports(reports);
        }
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    }
    fetchReports();
  }, [courseName, scenarioUuid]);


  return (
    <>
      {reports && reports.length > 0 && (
        <>
          <Typography variant="body1" fontWeight="bold" sx={{ pb: 3 }}>Reports</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="overline">Name</Typography></TableCell>
                  <TableCell><Typography variant="overline">Date</Typography></TableCell>
                  <MetricScoreHeader features={features} metric={reports[0].scoringMetrics[0]} />
                  <MetricScoreHeader features={features} metric={reports[0].scoringMetrics[1]} />
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.map((row) => (
                  <TableRow
                    key={row.uuid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <ReportNameValue features={features} uuid={row.uuid} name={row.botName} />
                    <TableCell>{toDateTimeString(row.createdAt)}</TableCell>
                    <MetricScoreValue features={features} skillScores={row.skillScores} metric={reports[0].scoringMetrics[0]} />
                    <MetricScoreValue features={features} skillScores={row.skillScores} metric={reports[0].scoringMetrics[1]} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

export default ReportTable;
