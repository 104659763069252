import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const ScienceSection = () => {
  return (
    <Container id="science" maxWidth="lg" sx={{ pt: 4, mt: 8, textAlign: 'center' }}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={12} sx={{ marginBottom: '-60px' }}>
          <Typography variant="h4" component="h2" gutterBottom sx={{
            fontSize: {
              xs: 'calc(0.5rem + 1vw)',
              sm: 'calc(0.5rem + 1vw)',
              md: 'calc(0.8rem + 1.5vw)',
              lg: 'calc(1rem + 1.5vw)',
              xl: 'calc(1.2rem + 1.8vw)'
            }
          }}>
            Develop management,<br />leadership and <br />communication skills
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src="/landing/charts.png" alt="Skills Development" style={{ maxWidth: '100%', height: 'auto' }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ScienceSection;
