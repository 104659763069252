import React, { useState, useEffect } from 'react';
import { Link as DomLink, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import Cookies from 'js-cookie';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Avatar, Fab } from '@mui/material';
import FAQContent from './FAQContent';
import { setUser, toDisplayInitials, toDisplayName } from '../slices/authSlice';
import api from '../api';
import { AppDispatch, RootState } from '../store';
import { setPages } from '../appSlice';
import DrawerList from './DrawerList';
import Banner from './Banner';
import { AuthenticationDTO } from '../api/types/auth';
import { red } from '@mui/material/colors';

const drawerWidth = 290;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  padding: "0 24px"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  padding: "12px",
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  paddingTop: "48px"
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth - 60}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.dark,
    color: '#989FAD',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        backgroundColor: theme.palette.primary.dark,
        color: '#989FAD',
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        backgroundColor: theme.palette.primary.dark,
        color: '#989FAD',
      },
    }),
  }),
);

interface MiniDrawerProps {
  pageName: string;
  mainContent: React.ReactNode;
  loginRedirect?: string
  customBarContent?: React.ReactNode | null;
}

function AppDrawer({ pageName, mainContent, loginRedirect = '/scenarios', customBarContent = null }: MiniDrawerProps) {
  const dispatch: AppDispatch = useDispatch();
  const isLoggedIn = Cookies.get('isAuthenticated') === 'true';
  const userProfile = useSelector((state: RootState) => state.auth.profile);
  const { uuid, correlationId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(isLargeScreen);
  const [mobileOpen, setMobileOpen] = useState(false);

  const [faqOpen, setFaqOpen] = useState(false);

  const handleFaqOpen = () => {
    setFaqOpen(true);
  };

  const handleFaqClose = () => {
    setFaqOpen(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      api.reauthenticate().then(({ profile, features, pages }: AuthenticationDTO) => {
        dispatch(setUser({ profile, features }));
        dispatch(setPages(pages));
        if (window.location.pathname.startsWith('/login') || window.location.pathname.startsWith('/signup')) {
          navigate(loginRedirect);
        }
      }).catch(error => {
        console.error('Failed to reauthenticate:', error);
      });
    }
  }, [dispatch, isLoggedIn, navigate]);

  useEffect(() => {
    if (isSmallScreen) {
      setOpen(false); // Minimize the drawer when screen size is small
    } else {
      setOpen(true);
    }
  }, [isSmallScreen]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMobileDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleMobileDrawerClose = () => {
    setMobileOpen(false);
  };

  let content;
  if (pageName === 'Report') {
    content =
      <>
        <IconButton
          component={DomLink} to="/reports"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          sx={{
            ml: 7,
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ ml: 1 }}>
          {pageName}{customBarContent}
        </Typography>
      </>;
  } else if (pageName === 'Conversation') {
    const scenarioName = Cookies.get('scenarioName');
    content =
      <>
        <Typography variant="h6" noWrap component="div" sx={{ ml: 9 }}>
          {pageName}{scenarioName ? `: ${scenarioName}` : ''}{customBarContent}
        </Typography>
      </>;
  } else {
    content = <Typography variant="h6" noWrap component="div" sx={{ ml: 9 }}>
      {pageName}{customBarContent}
    </Typography>;
  }

  const loginSignUpLinks = userProfile == null && (
    <Box sx={{ position: 'absolute', right: 20 }}>
      <Typography variant="body2">
        <Button variant="contained" color="primary"
          component={DomLink} to="/login"
          state={{
            conversationUuid: uuid,
            correlationUuid: correlationId,
            next: loginRedirect
          }}
        >
          Login
        </Button>
        &nbsp;&nbsp;
        <Button component={DomLink} to="/signup" variant="contained" color="secondary"
          state={{
            next: loginRedirect
          }}
        >
          Sign Up
        </Button>
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar elevation={0} position="fixed" open={open} sx={{
        backgroundColor: '#ffffff',
        color: '#101110',
        borderBottom: '1px solid #D0D3D9',
      }}>
        <Toolbar>
          {isSmallScreen && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleMobileDrawerOpen}
              sx={{
                height: { xs: 56, sm: 64 },
                width: { xs: 56, sm: 64 },
                position: 'absolute',
                left: 10,
                bgcolor: theme.palette.primary.dark,
                color: '#FFFFFF',
                borderRadius: 0,
                '&:hover': {
                  bgcolor: theme.palette.primary.main,
                },
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          {content}
          {loginSignUpLinks}
          {userProfile && (
            <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: 20 }}>
              <Avatar sx={{ marginRight: 1 }}>
                {toDisplayInitials(userProfile)}
              </Avatar>
              {!isSmallScreen && (
                <Typography variant="body1" sx={{ marginRight: 2 }}>
                  {toDisplayName(userProfile)}
                </Typography>
              )}
            </div>
          )}
        </Toolbar>

      </AppBar>
      {!isSmallScreen ? (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              sx={{
                marginLeft: 0,
              }}
            >
              {/*{open ? <ChevronLeftIcon /> : <MenuIcon />}*/}
              {open ?
                <img src="/logo.svg" alt="Mindbloom" style={{
                  height: '45px',

                }} /> :
                <img src="/icon.svg" alt="Mindbloom" style={{
                  height: '40px',
                  position: 'absolute',
                  top: '-45px',
                  left: '0px',
                }} />
              }
            </IconButton>
          </DrawerHeader>
          <DrawerList pageName={pageName} open={open} checkIsLoggedIn={isLoggedIn} isSmallScreen={isSmallScreen}
            mobileOpen={mobileOpen} />

        </Drawer>
      ) : (
        <MuiDrawer
          anchor="left"
          open={mobileOpen}
          onClose={handleMobileDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100vw',
              backgroundColor: theme.palette.primary.dark,
              color: '#989FAD',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            },
          }}
        >
          <DrawerHeader>
            <Box sx={{ position: 'absolute', top: 15, right: 15 }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleMobileDrawerClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Box sx={{ mt: '100px' }}>
              <img src="/logo.svg" alt="Mindbloom" style={{ height: '45px' }} />
            </Box>

          </DrawerHeader>
          <DrawerList pageName={pageName} open={open} checkIsLoggedIn={isLoggedIn} isSmallScreen={isSmallScreen}
            mobileOpen={mobileOpen} />
        </MuiDrawer>
      )}
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        {mainContent}
      </Box>
      {/* <Banner>
        We are currently in <b>beta testing</b>. We welcome feedback. Email us at <b><Link href="mailto:admin@mindbloom.ai" color="inherit">admin@mindbloom.ai</Link></b>.
      </Banner> */}
      <Dialog open={faqOpen} onClose={handleFaqClose} maxWidth="md" fullWidth>
        <DialogTitle>Frequently Asked Questions</DialogTitle>
        <DialogContent dividers>
          <FAQContent />
        </DialogContent>
      </Dialog>
      <Box sx={{
        position: 'fixed',
        bottom: 10,
        right: 10,
        zIndex: 100000, // so that this displays on top of everything including the drawer in the report page
      }}>
        <Link href="mailto:support@mindbloom.ai" sx={{
          textDecoration: 'none',
          mr: 2,
          ":hover": {
            textDecoration: 'underline',
          }
        }}>Contact Support</Link>
        <Fab
          color="primary"
          aria-label="help"
          onClick={handleFaqOpen}

        >
          <HelpOutlineIcon />
        </Fab>
      </Box>
    </Box>
  );
}

export default AppDrawer;
