import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const ContactSection = () => {
  return (
    <Container id="contact" maxWidth="lg" sx={{ pt: 4, mt: 8, mb: 20, textAlign: 'center' }}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={12} sx={{ marginBottom: '-60px' }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" color="text.secondary" component="h2" gutterBottom>
            Drop us an email at{' '}
            <a href="mailto:admin@mindbloom.ai">
              admin@mindbloom.ai
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactSection;
