import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../../lib/tracking';

const TrainTalkLearn = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const navigate = useNavigate();

  const handleCoursesClick = () => {
    trackEvent('Button', 'Click', 'Landing Train Talk Learn Start for Free');
    navigate('/scenarios');
  };
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: { md: 16, sm: 10, xs: 10 },
        }}
      >
        <Tabs
          id="product"
          sx={{
            borderRadius: 16,
            backgroundColor: '#DDE6F6',
            padding: '8px',
            display: 'inline-flex',
          }}
          value={selectedTab}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          <Tab
            sx={{
              textTransform: 'none',
              borderRadius: 16,
              backgroundColor: selectedTab === 0 ? '#fff' : 'transparent',
              color: selectedTab === 0 ? '#DDE6F6' : '#3F3F3F',
              border: '1px solid #DDE6F6',
              marginRight: 3,
            }}
            label="Talk"
          />
          <Tab
            sx={{
              textTransform: 'none',
              borderRadius: 16,
              backgroundColor: selectedTab === 1 ? '#fff' : 'transparent',
              color: selectedTab === 1 ? '#DDE6F6' : '#3F3F3F',
              border: '1px solid #DDE6F6',
              marginRight: 3,
            }}
            label="Train"
          />
          <Tab
            sx={{
              textTransform: 'none',
              borderRadius: 16,
              backgroundColor: selectedTab === 2 ? '#fff' : 'transparent',
              color: selectedTab === 2 ? '#DDE6F6' : '#3F3F3F',
              border: '1px solid #219DDE6F66f3',
            }}
            label="Learn"
          />
        </Tabs>
      </Box>
      {selectedTab === 0 && (
        <Container maxWidth="lg" sx={{ marginTop: 8, textAlign: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <CardMedia
                  component="img"
                  height="100%"
                  image="/landing/videocall.png"
                  alt="Coaching"
                  sx={{ objectFit: 'cover' }}
                />
              </Grid>
              <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                <CardContent sx={{ pl: 10 }}>
                  <Typography variant="h5" component="div" sx={{ pb: 2, textAlign: 'left' }}>
                    Instant, realistic speech
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ pb: 2, textAlign: 'left' }}>
                    Constantly evolving audio replication to mimic a real phone call
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ pb: 2, textAlign: 'left' }}>
                    Have a natural conversation in real time, with different characters, voices and emotions each time.
                    Check your own video and expressions as you engage with our AI.
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
      {selectedTab === 1 && (
        <Container maxWidth="lg" sx={{ marginTop: 8, textAlign: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <CardMedia
                  component="img"
                  height="100%"
                  image="/landing/chat.png"
                  alt="Coaching"
                  sx={{ objectFit: 'cover' }}
                />
              </Grid>
              <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                <CardContent
                  sx={{
                    pl: {
                      md: 10,
                      sm: 0,
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      pb: 2,
                      textAlign: {
                        md: 'left',
                        sm: 'center',
                      },
                    }}
                  >
                    Conversational AI
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{
                      pb: 2,
                      textAlign:
                        {
                          md: 'left',
                          sm: 'center',
                        },
                    }}>
                    An emotionally intelligent practice partner, trained on your conversations
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{
                    pb: 2, textAlign: {
                      md: 'left',
                      sm: 'center',
                    },
                  }}>
                    Add details and talk about whatever you want with Mindbloom, in truly open ended conversations that
                    are engaging, surprising and useful. Our AI is trained on conversations just like yours to add
                    realism and weight to your practice scenarios.
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
      {selectedTab === 2 && (
        <Container maxWidth="lg" sx={{ marginTop: 8, textAlign: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <CardMedia
                  component="img"
                  height="100%"
                  image="/landing/feedback.png"
                  alt="Coaching"
                  sx={{ objectFit: 'cover' }}
                />
              </Grid>
              <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                <CardContent sx={{ pl: 10 }}>
                  <Typography variant="h5" component="div" sx={{ pb: 2, textAlign: 'left' }}>
                    Unique, generative feedback
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ pb: 2, textAlign: 'left' }}>
                    Line by line feedback plus overall learning profile tracking and advice
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ pb: 2, textAlign: 'left' }}>
                    Mindbloom listens and pays attention to your every word, so it doesn’t just pick your most memorable
                    3 points when giving you feedback. Track how you’re doing in every sentence, and across multiple
                    conversations with uniquely generated summaries and commentary.
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 6 }}>
        <Button variant="contained" color="primary" onClick={handleCoursesClick}>
          Start for Free
        </Button>
      </Box>
    </>
  );
};

export default TrainTalkLearn;
