import { MindbloomAPI } from "./mb-api";
import {
  toCharacterDTO,
  toConversationDTO,
  toConversationSummariesDTO,
  toCourseDTO,
  toMasterDataDTO,
  toPersonasDTO,
  toScenarioDTO,
  toScenarioGroupsDTO,
  toScenarioSummariesDTO,
  toServerSettingsDTO,
  toSkillDTO,
  toUserDetails,
} from './mb-dto';
import { AudioStreamRequestDTO } from "./types/audio";
import { AuthenticationDTO, LicenseAgreementAcceptance, PublicConversation, RegistrationStatusDTO, UserDetailsDTO, UserProfileDTO, UserRegistration } from "./types/auth";
import { ConversationDTO, ConversationSortBy, ConversationSummaryDTO, ConversationSearchFilterDTO, CreateConversationDTO, CreateConversationFromScenarioDTO, ConversationWithCorrelationDTO, } from "./types/conversation";
import { CourseDTO } from "./types/course";
import { CharacterDTO, CharacterInferRequestDTO, MasterDataDTO, PersonasDTO } from "./types/master-data";
import { ScenarioDTO, ScenarioGenerationParamsDTO, GeneratedScenarioDTO, ScenarioSummaryDTO, ScenarioSearchFilterDTO, CreateScenarioDTO, UpdateScenarioDTO, ScenarioGroupDTO } from './types/scenario';
import { SkillDTO } from "./types/skill";
import { ServerSettingsDTO } from "./types/types";

const AUTHORIZATION_HEADER = 'Authorization';
const MB_CORRELATION_ID = 'x-mb-correlation-id';
const MB_UX_ID = 'x-mb-ux-id';

type FetchFactoryOptions = {
  baseUrl?: string;
  defaultHeaders?: Record<string, string>;
};

/**
 * Generates a UUID v4.
 * @returns string
 */
const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

/**
 * Helper to transform response data to a given DTO.
 * @param response 
 * @param transform 
 * @returns Promise<T>
 */
const handleResponse = async <T>(response: Response, responseType: string, transform?: (data: unknown) => T): Promise<T> => {
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`error: ${response.status}, message: ${errorText}`);
  }

  let data: any;
  switch (responseType) {
    case 'none':
      data = undefined;
      break;
    case 'arrayBuffer':
      try {
      data = await response.arrayBuffer();
      } catch {
        console.error('Error reading array buffer');
      }
      break;
    case 'text':
      data = await response.text();
      break;
    case 'blob':
      data = await response.blob();
      break;
    default:
      data = await response.json();
  }

  if (transform) {
    return transform(data);
  }

  return data;
};

export class BrowserMindbloomAPI implements MindbloomAPI {
  private baseUrl: string;
  private defaultHeaders: Record<string, string>;
  private personasCache: PersonasDTO | undefined;

  constructor({ baseUrl = '', defaultHeaders = {}}: FetchFactoryOptions) {
    this.baseUrl = baseUrl.replace(/\/+$/, '');

    this.defaultHeaders = { ...defaultHeaders };

    this.setDefaultHeader(MB_UX_ID, uuidv4());
  }

  /**
   * Updates or sets a default header (e.g., for auth tokens).
   * @param key - The header key (e.g., 'Authorization').
   * @param value - The header value.
   */
  setDefaultHeader(key: string, value: string): void {
    this.defaultHeaders[key] = value;
  }

  /**
   * Removes a default header.
   * @param key - The header key to remove.
   */
  removeDefaultHeader(key: string): void {
    delete this.defaultHeaders[key];
  }

  /**
   * Performs a fetch request with the current default headers and options.
   * @param url - The request URL path.
   * @param options - Fetch options (e.g., method, body, headers).
   * @returns The fetch response.
   */
  async fetch(url: string, options: RequestInit = {}): Promise<Response> {
    const headers = {
      ...this.defaultHeaders,
      ...options.headers,
    };

    return fetch(new URL(this.baseUrl + url).toString(), {
      ...options,
      headers
    });
  }

  /**
   * This method is used to reauthenticate a user without requiring the user to provide their credentials.
   * Used to refresh the user's token in place of an actual refresh token.
   * @returns Promise<AuthenticationDTO>
   */
  async reauthenticate(): Promise<AuthenticationDTO> {
    const response = await this.fetch('/public/reauthenticate', {
      method: 'PUT',
    });

    if (response.ok) {
      const data = (await response.json()) as AuthenticationDTO;
      this.setDefaultHeader(AUTHORIZATION_HEADER, `Bearer ${data.token}`);

      return data;
    } else {
      if (response.status === 401) {
        throw new Error('invalid credentials');
      }

      throw new Error(`unexpected error: ${response.statusText}`);
    }
  }

  /**
   * Authenticates a user with a username and password.
   * @param username 
   * @param password 
   * @param publicConversation 
   * @returns 
   */
  async authenticate(username: string, password: string, publicConversation?: PublicConversation): Promise<AuthenticationDTO> {
    const url = `/public/authenticate`;

    let response;
    if (publicConversation) {
      const { conversationUuid, correlationUuid } = publicConversation;

      response = await this.fetch('/public/authenticate', {
        method: 'POST',
        body: JSON.stringify({
          username,
          password,
          conversationUuid,
        }),
        headers: {
          [MB_CORRELATION_ID]: correlationUuid,
        }
      });
    } else {
      response = await this.fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          username,
          password
        })
      });
    }

    if (response.ok) {
      const data = (await response.json()) as AuthenticationDTO;
      this.setDefaultHeader(AUTHORIZATION_HEADER, `Bearer ${data.token}`);

      return data;
    } else {
      if (response.status === 401) {
        throw new Error('invalid credentials');
      }

      throw new Error(`unexpected error: ${response.statusText}`);
    }
  }

  /**
   * Register a new user.
   * @param regDetails 
   * @param publicConversation 
   * @returns 
   */
  async register(regDetails: UserRegistration, publicConversation?: PublicConversation): Promise<RegistrationStatusDTO> {
    const url = `/public/register`;

    let response;
    if (publicConversation) {
      const { conversationUuid, correlationUuid } = publicConversation;

      response = await this.fetch(url, {
        method: 'POST',
        body: JSON.stringify(toUserDetails({
          ...regDetails,
          conversationUuid,
        })),
        headers: {
          [MB_CORRELATION_ID]: correlationUuid,
        }
      });
    } else {
      response = await this.fetch(url, {
        method: 'POST',
        body: JSON.stringify(toUserDetails(regDetails))
      });
    }

    if (response.ok) {
      const data = (await response.json()) as RegistrationStatusDTO;

      if (data.authentication) {
        this.setDefaultHeader(AUTHORIZATION_HEADER, `Bearer ${data.authentication.token}`);
      }

      return data;
    } else {
      // TODO: No handling of 400 status code and validation errors.
      throw new Error(`unexpected error: ${response.statusText}`);
    }
  }

  /**
   * Logout the current user.
   * Remove the auth header from the client.
   */
  async logout(): Promise<void> {
    const response = await this.fetch('/public/logout', {
      method: 'POST',
    });

    this.removeDefaultHeader(AUTHORIZATION_HEADER);

    return handleResponse(response, 'json');
  }

  /**
   * Ping! Pong!
   * @param action 
   * @returns Promise<unknown>
   */
  async ping(action?: string): Promise<unknown> {
    const url = new URL('/public/ping', this.baseUrl);

    if (action) {
      url.searchParams.append(action, 'true');
    }

    const response = await this.fetch(`${url.pathname}${url.search}`);
    return handleResponse(response, 'json');
  }

  /**
   * Fetch details for the current user.
   * @returns Promise<UserDetailsDTO>
   */
  async getMyDetails(): Promise<UserDetailsDTO> {
    const response = await this.fetch('/user/own');
    return handleResponse(response, 'json');
  }

  /**
   * Update a users details.
   * @param body 
   * @returns 
   */
  async updateMyDetails(body: Partial<UserDetailsDTO>): Promise<UserDetailsDTO> {
    const response = await this.fetch('/user/own', {
      method: 'POST',
      body: JSON.stringify(toUserDetails(body)),
    });

    return handleResponse(response, 'json');
  }

  /**
   * Retrieves the server settings.
   *
   * @async
   * @returns {Promise<ServerSettingsDTO>} A promise that resolves to the server settings data transfer object.
   * @throws {Error} If the HTTP response is not ok or if there is a network error.
   */
  async getServerSettings(): Promise<ServerSettingsDTO> {
    const response = await this.fetch('/settings');

    return await handleResponse(response, 'json', toServerSettingsDTO);
  }

  /**
   * Retrieves the master data.
   *
   * @async
   * @returns {Promise<MasterDataDTO>} A promise that resolves to the master data data transfer object.
   * @throws {Error} If the HTTP response is not ok or if there is a network error.
   */
  async getMasterData(): Promise<MasterDataDTO> {
    const response = await this.fetch('/masterdata');

    return await handleResponse(response, 'json', toMasterDataDTO);
  }

  /**
   * Infers a character based on the provided request.
   *
   * @async
   * @param {CharacterInferRequestDTO} request - The request data for character inference.
   * @returns {Promise<CharacterDTO>} A promise that resolves to the inferred character data transfer object.
   * @throws {Error} If the HTTP response is not ok or if there is a network error.
   */
  async inferCharacter(request: CharacterInferRequestDTO): Promise<CharacterDTO> {
    const response = await this.fetch('/character/infer', {
      method: 'POST',
      body: JSON.stringify(request),
    });

    return await handleResponse(response, 'json', toCharacterDTO);
  }

  /**
   * Retrieves a specific conversation by UUID.
   *
   * @async
   * @param {string} uuid - The UUID of the conversation to retrieve.
   * @returns {Promise<ConversationDTO>} A promise that resolves to the conversation data transfer object.
   * @throws {Error} If the HTTP response is not ok or if there is a network error.
   */
  async getConversation(uuid: string): Promise<ConversationDTO> {
    const response = await this.fetch(`/conversation/${uuid}`, {
      method: 'GET',
    });

    return handleResponse(response, 'json', toConversationDTO);
  }

  /**
   * Retrieves the user's own conversations, optionally sorted by a specified criterion.
   *
   * @async
   * @param {ConversationSortBy} [sortBy] - Optional parameter to sort conversations by 'date' or 'name'.
   * @returns {Promise<ConversationSummaryDTO[]>} A promise that resolves to an array of conversation summary data transfer objects.
   * @throws {Error} If the HTTP response is not ok or if there is a network error.
   */
  async getMyConversations(sortBy?: ConversationSortBy): Promise<ConversationSummaryDTO[]> {
    const response = await this.fetch(`/conversations/own`, {
      method: 'GET',
    });
    return handleResponse(response, 'json', (data: any) => toConversationSummariesDTO(data, sortBy));
  }

  /**
   * Retrieves conversations based on a search filter and optionally sorted by a specified criterion.
   *
   * @async
   * @param {ConversationSearchFilterDTO} filter - The filter criteria for searching conversations.
   * @param {ConversationSortBy} [sortBy] - Optional parameter to sort conversations by 'date' or 'name'.
   * @returns {Promise<ConversationSummaryDTO[]>} A promise that resolves to an array of conversation summary data transfer objects.
   * @throws {Error} If the HTTP response is not ok or if there is a network error.
   */
  async getConversations(filter: ConversationSearchFilterDTO, sortBy?: ConversationSortBy): Promise<ConversationSummaryDTO[]> {
    const response = await this.fetch(`/conversations`, {
      method: 'POST',
      body: JSON.stringify(filter),
    });
    return handleResponse(response, 'json', (data: any) => toConversationSummariesDTO(data, sortBy));
  }

  /**
   * Creates a new conversation.
   *
   * @async
   * @param {CreateConversationDTO} conversation - The conversation data to be created.
   * @returns {Promise<ConversationDTO>} A promise that resolves to the newly created conversation data transfer object.
   * @throws {Error} If the HTTP response is not ok or if there is a network error.
   */
  async createConversation(conversation: CreateConversationDTO): Promise<ConversationDTO> {
    const response = await this.fetch(`/conversation`, {
      method: 'POST',
      body: JSON.stringify(conversation),
    });
    return handleResponse(response, 'json', toConversationDTO);
  }

  /**
   * Creates a new conversation from a specific scenario.
   *
   * @async
   * @param {CreateConversationFromScenarioDTO} conversation - The conversation data to be created from a scenario.
   * @param {string} scenarioUuid - The UUID of the scenario from which to create the conversation.
   * @returns {Promise<ConversationDTO>} A promise that resolves to the newly created conversation data transfer object.
   * @throws {Error} If the HTTP response is not ok or if there is a network error.
   */
  async createConversationFromScenario(conversation: CreateConversationFromScenarioDTO, scenarioUuid: string): Promise<ConversationDTO> {
    const response = await this.fetch(`/conversation/scenario/${scenarioUuid}`, {
      method: 'POST',
      body: JSON.stringify(conversation),
    });
    return handleResponse(response, 'json', toConversationDTO);
  }

  /**
   * Creates a new conversation from a previously generated scenario.
   *
   * @async
   * @param {CreateConversationFromScenarioDTO} genScenario - The conversation data to be created from a scenario.
   * @param {string} scenarioUuid - The UUID of the scenario from which to create the conversation.
   * @returns {Promise<ConversationDTO>} A promise that resolves to the newly created conversation data transfer object.
   * @throws {Error} If the HTTP response is not ok or if there is a network error.
   */
  async createConversationFromGeneratedScenario(conversation: GeneratedScenarioDTO, scenarioUuid?: string): Promise<ConversationDTO> {
    const response = await this.fetch(`/conversation/generatedScenario/${scenarioUuid}`, {
      method: 'POST',
      body: JSON.stringify(conversation),
    });
    return handleResponse(response, 'json', toConversationDTO);
  }

  /**
   * Sends a message in a specific conversation.
   *
   * @async
   * @param {string} uuid - The UUID of the conversation where the message will be sent.
   * @param {string} message - The message content to send.
   * @returns {Promise<ConversationDTO>} A promise that resolves to the updated conversation data transfer object.
   * @throws {Error} If the HTTP response is not ok or if there is a network error.
   */
  async converse(uuid: string, message: string): Promise<ConversationDTO> {
    const response = await this.fetch(`/conversation/${uuid}/message`, {
      method: 'POST',
      body: JSON.stringify({ message }),
    });
    return handleResponse(response, 'json', toConversationDTO);
  }

  /**
   * Completes a specific conversation.
   *
   * @async
   * @param {string} uuid - The UUID of the conversation to complete.
   * @returns {Promise<ConversationDTO>} A promise that resolves to the completed conversation data transfer object.
   * @throws {Error} If the HTTP response is not ok or if there is a network error.
   */
  async completeConversation(uuid: string): Promise<ConversationDTO> {
    const response = await this.fetch(`/conversation/${uuid}/complete`, {
      method: 'PUT',
    });

    return handleResponse(response, 'json', toConversationDTO);
  }


  /**
   * Buffers a stream of audio data for a conversation.
   * @deprecated
   * @param uuid 
   * @param audioRequest 
   * @returns 
   */
  async generateAudioStream(uuid: string, audioRequest: AudioStreamRequestDTO): Promise<ArrayBuffer> {
    const response = await this.fetch(`/conversation/${uuid}/streamaudio`, {
      method: 'POST',
      body: JSON.stringify(audioRequest)
    });

    return handleResponse(response, 'arrayBuffer');
  }

  /**
   * Generates a live audio stream for a conversation. Replaces generateAudioStream..
   * @param uuid 
   * @param audioRequest 
   * @returns Promise<ReadableStream<Uint8Array>>
   */
  async generateLiveAudioStream(
    uuid: string,
    audioRequest: AudioStreamRequestDTO
  ): Promise<ReadableStream<Uint8Array>> {
    const url = `/conversation/${uuid}/streamaudio`;
  
    const response = await this.fetch(url, {
      method: 'POST',
      body: JSON.stringify(audioRequest),
    });
  
    if (!response.ok) {
      throw new Error(`Server error: ${response.statusText}`);
    }
  
    if (!response.body) {
      throw new Error('readableStream not supported in this environment.');
    }
  
    return response.body;
  }


  /**
   * Retrieves public personas. Uses cached data if available.
   * @returns A Promise resolving to PersonasDTO.
   */
  async getPublicPersonas(): Promise<PersonasDTO> {
    if (this.personasCache) {
      return this.personasCache;
    } else {
      const response = await this.fetch(`/public/personas`, {
        method: 'GET',
      });
      this.personasCache = await handleResponse(response, 'json', toPersonasDTO);
      return this.personasCache;
    }
  }

  /**
   * Retrieves public skills.
   * @returns A Promise resolving to an array of SkillDTO.
   */
  async getPublicSkills(): Promise<SkillDTO[]> {
    const response = await this.fetch(`/public/skills`, {
      method: 'GET',
    });
    return handleResponse(response, 'json', (data: any) => data.map(toSkillDTO));
  }

  /**
   * Retrieves public scenarios.
   * @returns A Promise resolving to an array of ScenarioDTO.
   */
  async getPublicScenarios(): Promise<ScenarioDTO[]> {
    const response = await this.fetch(`/public/scenarios`, {
      method: 'GET',
    });
    return handleResponse(response, 'json', (data: any) => data.map(toScenarioDTO));
  }

  /**
   * Retrieves public courses.
   * @returns A Promise resolving to an array of CourseDTO.
   */
  async getPublicCourses(): Promise<CourseDTO[]> {
    const response = await this.fetch(`/public/courses`, {
      method: 'GET',
    });
    return handleResponse(response, 'json', (data: any) => data.map(toCourseDTO));
  }

  /**
   * Generates a scenario based on the provided parameters.
   * @param scenario - The scenario generation parameters.
   * @returns A Promise resolving to GeneratedScenarioDTO.
   */
  async generateScenario(scenario: ScenarioGenerationParamsDTO): Promise<GeneratedScenarioDTO> {
    const response = await this.fetch(`/scenario/generate`, {
      method: 'POST',
      body: JSON.stringify(scenario),
    });
    return handleResponse(response, 'json', (data: any) => data as GeneratedScenarioDTO);
  }

  /**
   * Retrieves a specific scenario by UUID.
   * @param uuid - The UUID of the scenario.
   * @returns A Promise resolving to ScenarioDTO.
   */
  async getScenario(uuid: string): Promise<ScenarioDTO> {
    const response = await this.fetch(`/scenario/${uuid}`, {
      method: 'GET',
    });
    return handleResponse(response, 'json', toScenarioDTO);
  }

  /**
   * Retrieves the user's own scenarios.
   * @returns A Promise resolving to an array of ScenarioSummaryDTO.
   */
  async getMyScenarios(): Promise<ScenarioSummaryDTO[]> {
    const response = await this.fetch(`/scenarios/own`, {
      method: 'GET',
    });
    return handleResponse(response, 'json', (data: unknown) => toScenarioSummariesDTO(data as any[]));
  }

  /**
   * Retrieves the the greoups of scenarios the user has access to.
   * @returns A Promise resolving to an array of ScenarioGroupDTOs.
   */
  async getGroupScenarios(): Promise<ScenarioGroupDTO[]> {
    const response = await this.fetch(`/scenarios/groups`, {
      method: 'GET',
    });
    return handleResponse(response, 'json', (data: unknown) => toScenarioGroupsDTO(data as any[]));
  }

  /**
   * Retrieves scenarios based on the provided filter.
   * @param filter - The scenario search filter.
   * @returns A Promise resolving to an array of ScenarioSummaryDTO.
   */
  async getScenarios(filter: ScenarioSearchFilterDTO): Promise<ScenarioSummaryDTO[]> {
    const response = await this.fetch(`/scenarios`, {
      method: 'POST',
      body: JSON.stringify(filter),
    });
    return handleResponse(response, 'json', (data: unknown) => toScenarioSummariesDTO(data as any[]));
  }

  /**
   * Creates a new scenario.
   * @param scenario - The scenario data to create.
   * @returns A Promise resolving to ScenarioDTO.
   */
  async createScenario(scenario: CreateScenarioDTO): Promise<ScenarioDTO> {
    const response = await this.fetch(`/scenario`, {
      method: 'POST',
      body: JSON.stringify(scenario),
    });
    return handleResponse(response, 'json', toScenarioDTO);
  }

  /**
   * Updates an existing scenario.
   * @param uuid - The UUID of the scenario to update.
   * @param scenario - The updated scenario data.
   * @returns A Promise resolving to ScenarioDTO.
   */
  async updateScenario(uuid: string, scenario: UpdateScenarioDTO): Promise<ScenarioDTO> {
    const response = await this.fetch(`/scenario/${uuid}`, {
      method: 'POST',
      body: JSON.stringify(scenario),
    });
    return handleResponse(response, 'json', toScenarioDTO);
  }

  /**
   * Deletes a scenario by UUID.
   * @param uuid - The UUID of the scenario to delete.
   * @returns A Promise resolving to void.
   */
  async deleteScenario(uuid: string): Promise<void> {

    const response = await this.fetch(`/scenario/${uuid}`, {
      method: 'DELETE',
    });
    return handleResponse(response, 'json', () => { });
  }

  /**
   * Accepts the license agreement.
   * @param firstName - The first name of the user (optional).
   * @param lastName - The last name of the user (optional).
   * @param email - The email of the user.
   * @returns A Promise resolving to LicenseAgreementAcceptance.
   */
  async acceptLicenseAgreement(
    firstName: string | null,
    lastName: string | null,
    email: string
  ): Promise<LicenseAgreementAcceptance> {
    const response = await this.fetch(`/public/acceptAgreement`, {
      method: 'POST',
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        accepted: true,
      }),
    });
    return handleResponse(response, 'json', (data: any) => data as LicenseAgreementAcceptance);
  }

  /**
   * Sends UX diagnostics data.
   * @param type - The type of diagnostic data.
   * @param data - The diagnostic data to send.
   * @returns A Promise resolving to void.
   */
  async sendUXDiagnostics(type: string, data: any): Promise<void> {
    const response = await this.fetch(`/public/ux`, {
      method: 'POST',
      body: JSON.stringify({
        type,
        data,
      }),
    });
    await handleResponse(response, 'json', () => { });
  }

  /**
   * Generates a skill summary for a conversation.
   * @param uuid - The UUID of the conversation.
   * @returns A Promise resolving to ConversationDTO.
   */
  async generateSkillSummary(uuid: string): Promise<ConversationDTO> {
    const response = await this.fetch(`/conversation/${uuid}/generate/skills`, {
      method: 'PUT',
    });
    return handleResponse(response, 'json', toConversationDTO);
  }

  /**
   * Deletes a conversation by UUID.
   * @param uuid - The UUID of the conversation to delete.
   * @returns A Promise resolving to void.
   */
  async deleteConversation(uuid: string): Promise<void> {
    const response = await this.fetch(`/conversation/${uuid}`, {
      method: 'DELETE',
    });
    await handleResponse(response, 'json', () => { });
  }

  /**
   * Creates a public conversation.
   * @param conversation - The conversation data to create.
   * @returns A Promise resolving to ConversationWithCorrelationDTO.
   */
  async createPublicConversation(conversation: CreateConversationDTO): Promise<ConversationWithCorrelationDTO> {
    const response = await this.fetch(`/public/conversation`, {
      method: 'POST',
      body: JSON.stringify(conversation),
    });

    const conversationDTO = await handleResponse(response, 'json', toConversationDTO);
    const correlationUuid = response.headers.get(MB_CORRELATION_ID) || '';

    return {
      ...conversationDTO,
      correlationUuid,
    };
  }

  /**
   * Creates a public conversation from a scenario.
   * @param conversation - The conversation data to create.
   * @param scenarioUuid - The UUID of the scenario.
   * @returns A Promise resolving to ConversationWithCorrelationDTO.
   */
  async createPublicConversationFromScenario(
    conversation: CreateConversationFromScenarioDTO,
    scenarioUuid: string
  ): Promise<ConversationWithCorrelationDTO> {
    const response = await this.fetch(`/public/conversation/scenario/${scenarioUuid}`, {
      method: 'POST',
      body: JSON.stringify(conversation),
    });

    const conversationDTO = await handleResponse(response, 'json', toConversationDTO);
    const correlationUuid = response.headers.get(MB_CORRELATION_ID) || '';

    return {
      ...conversationDTO,
      correlationUuid,
    };
  }

  /**
   * Retrieves a public conversation by UUID with a correlation ID.
   * @param uuid - The UUID of the public conversation.
   * @param correlationId - The correlation ID for tracking.
   * @returns A Promise resolving to ConversationDTO.
   */
  async getPublicConversation(uuid: string, correlationId: string): Promise<ConversationDTO> {
    const response = await this.fetch(`/public/conversation/${uuid}`, {
      method: 'GET',
      headers: {
        [MB_CORRELATION_ID]: correlationId,
      },
    });
    return handleResponse(response, 'json', toConversationDTO);
  }

  /**
   * Sends a message in a public conversation with a correlation ID.
   * @param uuid - The UUID of the public conversation.
   * @param correlationId - The correlation ID for tracking.
   * @param message - The message to send.
   * @returns A Promise resolving to ConversationDTO.
   */
  async publicConverse(uuid: string, correlationId: string, message: string): Promise<ConversationDTO> {
    const response = await this.fetch(`/public/conversation/${uuid}/message`, {
      method: 'POST',
      headers: {
        [MB_CORRELATION_ID]: correlationId,
      },
      body: JSON.stringify({ message }),
    });
    return handleResponse(response, 'json', toConversationDTO);
  }

  /**
   * Completes a public conversation with a correlation ID.
   * @param uuid - The UUID of the public conversation.
   * @param correlationId - The correlation ID for tracking.
   * @returns A Promise resolving to void.
   */
  async publicCompleteConversation(uuid: string, correlationId: string): Promise<void> {
    const response = await this.fetch(`/public/conversation/${uuid}/complete`, {
      method: 'PUT',
      headers: {
        [MB_CORRELATION_ID]: correlationId,
      },
    });

    await handleResponse(response, 'none');
  }

  /**
   * Generates an audio stream for a public conversation with a correlation ID.
   * @deprecated
   * @param uuid - The UUID of the public conversation.
   * @param correlationId - The correlation ID for tracking.
   * @param audioRequest - The audio stream request parameters.
   * @returns A Promise resolving to ArrayBuffer.
   */
  async publicGenerateAudioStream(
    uuid: string,
    correlationId: string,
    audioRequest: AudioStreamRequestDTO
  ): Promise<ArrayBuffer> {
    const response = await this.fetch(`/public/conversation/${uuid}/streamaudio`, {
      method: 'POST',
      headers: {
        [MB_CORRELATION_ID]: correlationId,
      },
      body: JSON.stringify(audioRequest),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`error: ${response.status}, message: ${errorText}`);
    }

    return await response.arrayBuffer();
  }

  /**
   * Start a password reset request.
   * @param user 
   */
  async resetPassword(user: string) {
    const response = await this.fetch('/public/password/reset', {
      method: 'POST',
      body: JSON.stringify({ user })
    });

    return await handleResponse(response, 'none');
  }

  async validatePasswordReset(user: string, token: string, newPassword: string) {
    const response = await this.fetch('/public/password/reset/validate', {
      method: 'POST',
      body: JSON.stringify({ user, token, newPassword })
    });

    return await handleResponse(response, 'none');
  }
}
