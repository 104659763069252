import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import EmotionChip from '../components/EmotionChip';
import { ConversationSortBy, ConversationSummaryDTO } from '../api/types/conversation';
import api from '../api';
import { MasterDataDTO } from '../api/types/master-data';
import { CourseDTO } from '../api/types/course';
import { MetricScoreHeader, MetricScoreValue, ReportNameValue } from './ReportUtils';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { toDateTimeString } from '../lib/utilities';
import { ScenarioDTO, ScenarioPrivilege, ScenarioSummaryDTO } from '../api/types/scenario';
import { Feature, hasFeature } from '../lib/features';

const findCustomReports = (reports: ConversationSummaryDTO[], myScenarios: ScenarioSummaryDTO[]): ConversationSummaryDTO[] => {
  const scenarios = myScenarios.filter(scenario => scenario.privilege === ScenarioPrivilege.USER);
  const scenarioUUIDs = scenarios?.map(scenario => scenario.uuid);
  return reports.filter(report => scenarioUUIDs?.includes(report.scenario ?? ""));
};

interface ReportGroup {
  title: string;
  reports: ConversationSummaryDTO[];
}
export default function ReportDetailedTable() {
  const [reportGroups, setReportGroups] = React.useState<ReportGroup[] | null>(null);
  const features = useSelector((state: RootState) => state.auth.features);

  React.useEffect(() => {
    async function fetchReports() {
      try {
        const reportList: ReportGroup[] = [];
        const reports: ConversationSummaryDTO[] = await api.getConversations({}, ConversationSortBy.CREATED_AT_ASC);
        const masterData: MasterDataDTO = await api.getMasterData();
        const myScenarios = await api.getMyScenarios();

        // Build custom reports
        if (hasFeature(Feature.DYNAMIC_SCENARIOS, features)) {
          const customReports = findCustomReports(reports, myScenarios);
          if (customReports.length > 0) {
            reportList.push({
              title: 'My Custom Reports',
              reports: customReports
            })
          }
        }

        // Build course reports
        masterData.scenarios.forEach(scenario => {
          // Filter reports for the current course name
          const filteredReports = reports.filter(report => report.scenario === scenario.uuid);
          if (filteredReports.length > 0) {
            reportList.push({
              title: scenario.name,
              reports: filteredReports
            });
          }
        });

        setReportGroups(reportList);
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    }
    fetchReports();
  }, []);

  return (
    <>
      {reportGroups && reportGroups.map((courseReport) => (
        courseReport.reports && courseReport.reports.length > 0 && (
          <div key={courseReport.title}>
            <Typography variant="body2" fontWeight="bold" sx={{ pt: 4, pb: 3 }}>
              {courseReport.title}
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ pt: 4, minWidth: 650 }} size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell><Typography variant="overline">Name</Typography></TableCell>
                    <TableCell><Typography variant="overline">Date</Typography></TableCell>
                    <MetricScoreHeader features={features} metric={courseReport.reports[0].scoringMetrics[0]} />
                    <MetricScoreHeader features={features} metric={courseReport.reports[0].scoringMetrics[1]} />
                    {/*<TableCell align="right"><Typography variant="overline">Overall</Typography></TableCell>*/}
                    <TableCell><Typography variant="overline">Emotion</Typography></TableCell>
                    <TableCell><Typography variant="overline">Personality</Typography></TableCell>
                    <TableCell><Typography variant="overline">Bot</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {courseReport.reports.map((row) => (
                    <TableRow
                      key={row.uuid}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <ReportNameValue features={features} uuid={row.uuid} name={`Conversation with ${row.botName}`} />
                      <TableCell>{toDateTimeString(row.createdAt)}</TableCell>
                      <MetricScoreValue features={features} skillScores={row.skillScores} metric={courseReport.reports[0].scoringMetrics[0]} />
                      <MetricScoreValue features={features} skillScores={row.skillScores} metric={courseReport.reports[0].scoringMetrics[1]} />

                      {/*<TableCell align="right">/!* Add content here *!/</TableCell>*/}
                      <TableCell> <EmotionChip emotion={row.botEmotion} /></TableCell>
                      <TableCell> {row.botPersonality}</TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Avatar alt={row.botName} src={row.botAvatar} sx={{ width: 40, height: 40, m: 1 }} />
                          {row.botName}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )
      ))}
    </>
  );


}
