import React from 'react';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { Page } from '../api/types/types';

type NavigatorItemProps = {
  page: Page;
  selected: boolean;
  handlePageSelection: (pageName: string) => void;
};

const NavigatorItemStyles = css({
  cursor: 'pointer',
});

const NavigatorItem = ({ page, selected, handlePageSelection }: NavigatorItemProps) => {
  return (
    <Box color={selected ? '#fff' : '#8b929d'}>
      <ListItem onClick={() => handlePageSelection(page.name)} css={NavigatorItemStyles}>
        <ListItemText primary={page.title ?? page.name} />
      </ListItem>
    </Box>
  );
};

const Navigator = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pages = useSelector((state: RootState) => state.app.pages).filter((page) => page.nav);

  const handlePageSelection = () => (pageName: string) => {
    const selectedPage = pages.find((page) => page.name === pageName);
    if (selectedPage) {
      navigate(selectedPage.path ?? `/${selectedPage.name}`);
    }
  };

  return (
    <List>
      {pages.map((page) => (
        <NavigatorItem
          key={page.name}
          page={page}
          selected={location.pathname.startsWith(page.path)}
          handlePageSelection={handlePageSelection()}
        />
      ))}
    </List>
  );
};

export default Navigator;
