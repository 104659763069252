import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Divider from '@mui/material/Divider';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ConversationDTO, ConversationResponseDTO } from '../api/types/conversation';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const drawerWidth = 400;
const smallScreenDrawerWidth = '90vw';

// const GetDrawerWidth = () => {
//   const drawerWidth = 400;
//   const smallScreenDrawerWidth = 450;
//   const theme = useTheme();
//   if (theme.breakpoints.down('sm')) {
//     return smallScreenDrawerWidth;
//   }
//   return drawerWidth;
// };

const GetDrawerWidth = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return isSmallScreen ? smallScreenDrawerWidth : drawerWidth;
};

const GetNegativeDrawerWidth = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return isSmallScreen ? `-${smallScreenDrawerWidth}` : `-${drawerWidth}px`;
};

const MainRightDrawer = styled('main', { shouldForwardProp: (prop) => prop !== 'openright' })<{
  openright?: boolean;
}>(({ theme, openright }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: GetNegativeDrawerWidth(),
  ...(openright && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: 'relative',
}));


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

interface DrawerRightProps {
  reportContent: React.ReactNode;
}

interface Feedback {
  skill: string;
  score: number;
  explanation: string;
}

interface Message {
  content: string;
  isBot: boolean;
  feedback?: Feedback;
}

const SkillIcons: Record<string, string> = {
  Empathy: '/skills/empathy.svg',
  Clarity: '/skills/clarity.svg',
  'Active Listening': '/skills/active_listening.svg',
  Leadership: '/skills/leadership.svg',
  Tolerance: '/skills/tolerance.svg',
  Patience: '/skills/patience.svg',
  Negotiation: '/skills/negotiation.svg',
};
const ChatBoxComponent = ({ messages }: { messages: Message[] }) => (
  <Box
    sx={{ backgroundColor: 'background.paper', height: '100%', overflow: 'auto', pl: 2, pr: 2, borderRadius: '8px' }}>
    {messages.map((message, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: message.isBot ? 'flex-start' : 'flex-end',
          marginBottom: '15px',
        }}
      >
        <Box
          sx={{
            background: message.isBot ? '#F0F1F3' : '#226EDC',
            borderRadius: message.isBot ? '0 10px 10px 10px' : '10px 0 10px 10px',
            color: message.isBot ? '#000000' : '#ffffff',
            padding: '15px 25px',
            maxWidth: '90%',
            textAlign: 'left',
          }}
        >
          <Typography variant="body2">
            {message.content}
          </Typography>
        </Box>
        {message.feedback && (
          <Box sx={{ ml: 4, textAlign: 'left', marginTop: '14px' }}>
            <Box sx={{ display: 'flex', alignItems: 'top', fontWeight: 'bold' }}>
              <img src={SkillIcons[message.feedback.skill]} alt={message.feedback.skill}
                   style={{ marginTop: '1px', width: '20px', height: '20px' }} />
              <Typography variant="body2" color="textSecondary" sx={{ paddingLeft: '10px' }}>
                <Typography variant="body2" component="span" sx={{ fontWeight: 'bold' }}>
                  {message.feedback.skill}: {message.feedback.score}.
                </Typography>
                {' '}{message.feedback.explanation}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    ))}
  </Box>
);

const removeSeeds = (message: ConversationResponseDTO) => !message.isSeed;

const convertClarityMessages = (rawMessage: ConversationResponseDTO): Message => {
  const message: Message = {
    content: rawMessage.statement,
    isBot: rawMessage.isBot,
  };

  if (rawMessage.clarity !== null && rawMessage.clarityReasoning !== null) {
    message.feedback = {
      skill: 'Clarity',
      score: rawMessage.clarity ?? 0,
      explanation: rawMessage.clarityReasoning ?? '',
    };
  }

  return message;
};

const convertEmpathyMessages = (rawMessage: ConversationResponseDTO): Message => {
  const message: Message = {
    content: rawMessage.statement,
    isBot: rawMessage.isBot,
  };

  if (rawMessage.empathy !== null && rawMessage.empathyReasoning !== null) {
    message.feedback = {
      skill: 'Empathy',
      score: rawMessage.empathy ?? 0,
      explanation: rawMessage.empathyReasoning ?? '',
    };
  }

  return message;
};

const convertActiveListeningMessages = (rawMessage: ConversationResponseDTO): Message => {
  const message: Message = {
    content: rawMessage.statement,
    isBot: rawMessage.isBot,
  };

  if (rawMessage.empathy !== null && rawMessage.empathyReasoning !== null) {
    message.feedback = {
      skill: 'Active Listening',
      score: rawMessage.activeListening ?? 0,
      explanation: rawMessage.activeListeningReasoning ?? '',
    };
  }

  return message;
};

export default function ReportConversationDrawer({ reportContent, report }: DrawerRightProps & { report: ConversationDTO }) {
  const [open, setOpen] = React.useState(false);
  const [selectedToggle, setSelectedToggle] = React.useState<keyof typeof messagesMap>(report.scoringMetrics[0] as keyof typeof messagesMap); // Default to the first metric
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newToggle: string) => {
    if (newToggle !== null) {
      setSelectedToggle(newToggle as keyof typeof messagesMap);
    }
  };

  // Process messages based on scoring metrics
  const messagesMap: { [key: string]: Message[] } = {};
  if (report.scoringMetrics.includes('Clarity')) {
    messagesMap['Clarity'] = report.responses ? report.responses.filter(removeSeeds).map(convertClarityMessages) : [];
  }
  if (report.scoringMetrics.includes('Empathy')) {
    messagesMap['Empathy'] = report.responses ? report.responses.filter(removeSeeds).map(convertEmpathyMessages) : [];
  }
  if (report.scoringMetrics.includes('Active Listening')) {
    messagesMap['Active Listening'] = report.responses ? report.responses.filter(removeSeeds).map(convertActiveListeningMessages) : [];
  }

  return (
    <>
      {!open && (
        <Box
          sx={{
            position: 'absolute',
            top: {
              xl: 100,
              lg: 100,
              md: 100,
              sm: 230,
              xs: 230
            },
            right: {md: 50, sm: 20, xs: 20},
            margin: '20px',
            zIndex: '1001'
          }}
        >
          <Button variant="contained" onClick={handleDrawerOpen}>Show Conversation</Button>
        </Box>
      )}
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <MainRightDrawer openright={open}>
          {reportContent}
        </MainRightDrawer>
        <Drawer
          sx={{
            width: GetDrawerWidth(),
            p: 2,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: GetDrawerWidth(),
              height: `calc(100vh - 110px)`,
              borderLeft: 'none',
              borderRadius: '10px',
              marginRight: '16px',
              marginTop: { md: '80px', sm: '70px', xs: '70px' },
              marginBottom: '100px',
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon />
            </IconButton>
            <Typography variant="body1" fontWeight="bold">Conversation</Typography>
            <ToggleButtonGroup
              value={selectedToggle}
              exclusive
              onChange={handleToggleChange}
              aria-label="text alignment"
              sx={{
                marginLeft: 'auto',
                marginRight: 1,
                '& .MuiToggleButton-root': {
                  textTransform: 'capitalize',
                  fontSize: '0.75rem',
                  padding: '4px 8px',
                },
              }}
            >
              {report.scoringMetrics.map((metric) => (
                <ToggleButton key={metric} value={metric} aria-label={metric.toLowerCase()}>
                  {selectedToggle === metric ? metric[0] : metric[0].toLowerCase()}{metric.slice(1)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </DrawerHeader>
          <Divider sx={{ mb: 2 }} />
          <ChatBoxComponent messages={messagesMap[selectedToggle]} />
        </Drawer>
      </Box>
    </>
  );
}

