import { useEffect, useState } from 'react';
import { newSpeechToText, RecordingState, SpeechToText, SpeechToTextType } from '../../audio/speech-to-text';

let speech2Text: SpeechToText | null = null;

export const useSpeechRecognition = (sttType: SpeechToTextType, onTranscript: (transcript : string, isFinal: boolean) => void) => {
  const [listening, setListening] = useState(false);
  const [lastSTT, setLastSTT] = useState(sttType);

  useEffect(() => {
    setLastSTT(sttType);
  }, []);

  useEffect(() => {
    if (!speech2Text || (lastSTT !== sttType)) {
      setLastSTT(sttType);
      speech2Text = newSpeechToText(sttType, (transcript: string, isFinal: boolean) => {
        onTranscript(transcript, isFinal);
        if (isFinal) {
          setListening(false);
        }
      });
    } else {
      speech2Text.onTranscript(onTranscript);
    }
  }, [listening, sttType, onTranscript]);

  return {
    listening,
    setEnabled: (enabled: boolean) => {
      speech2Text?.getState().then((state) => {
        switch (state) {
          case RecordingState.PAUSED:
            if (enabled) {
              speech2Text?.resume().then(() => setListening(true));
            } else {
              setListening(false);
            }
            break;

          case RecordingState.RECORDING:
            if (!enabled) {
              speech2Text?.pause().then(() => setListening(false));
            } else {
              setListening(true);
            }
            break;

          case RecordingState.NOT_RECORDING:
            if (enabled) {
              speech2Text?.start().then(() => setListening(true));
            }
            break;

          case RecordingState.UNAVAILABLE:
            setListening(enabled);
            break;
        }
      });
    },

    start: () => {
      speech2Text && speech2Text.start().then(() => setListening(true));
      },

    stop: () => {
      if (speech2Text) {
        return speech2Text.stop().then(() => {
            setListening(false);
            speech2Text = null;
          });
      } else {
        setListening(false);
        return Promise.resolve();
      }
    }
  };
};
