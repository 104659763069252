import { TableCell, Typography } from '@mui/material';
import { Feature, hasFeature } from '../lib/features';
import { Link } from 'react-router-dom';

export const ReportNameValue = (props: any) => {
  if (hasFeature(Feature.VIEW_REPORT_SUMMARY, props.features)) {
    return <TableCell component="th">
      <Link to={`/report/${props.uuid}`}>
        {props.name}
      </Link>
    </TableCell>;
    return <Link to={`/report/${props.uuid}`}>{props.botName}</Link>;
  } else {
    return <TableCell component="th">
      {props.name}
    </TableCell>;
  }
};

export const MetricScoreHeader = (props: any) => {
  return <TableCell><Typography variant="overline">{props.metric}</Typography></TableCell>;
};

export const MetricScoreValue = (props: any) => {
  if (hasFeature(Feature.VIEW_REPORT_SUMMARY, props.features)) {
    return <TableCell align="left">
      {props.skillScores && props.metric && props.skillScores[props.metric] || '-'}
    </TableCell>;
  } else {
    return <TableCell align="left">-</TableCell>;
  }
};
