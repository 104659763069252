import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CoursesIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32" fill="none">
    <path d="M20 12H22.6667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path d="M9.3335 22.6667H12.0002" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path d="M16 22.6667H22.6667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path d="M25.3333 5.33331C26.8067 5.33331 28 6.52665 28 7.99998V25.3333C28 26.8066 26.8067 28 25.3333 28H6.66667C5.19333 28 4 26.8066 4 25.3333V7.99998C4 6.52665 5.19333 5.33331 6.66667 5.33331" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path d="M9.3335 17.3333H12.0002" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path d="M16 17.3333H22.6667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path d="M13.3335 4H10.6668C9.93083 4 9.3335 4.59733 9.3335 5.33333V12L12.0002 10.6667L14.6668 12V5.33333C14.6668 4.59733 14.0695 4 13.3335 4Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path d="M6.6665 5.33331H9.33317" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path d="M14.6665 5.33331H25.3332" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
  </SvgIcon>
);

export default CoursesIcon;
