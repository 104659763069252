import { css } from '@emotion/react';
import { useMemo} from 'react';

const indicatorStyle = {
  backgroundColor: '#1371F8',
  padding: 4,
  width: 26,
  height: 26,
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const indicatorContentStyle = {
  display: 'flex',
  height: '100%',
  width: 18,
  justifyContent: 'space-between',
  alignItems: 'center'
};

const bars = (strength: number) => ({
  display: 'inline-block',
  width: 4,
  borderRadius: 2,
  backgroundColor: '#fff',
  height: `${Math.max(0.2, Math.min(1, strength)) * 100}%`,
  transition: 'height 0.02s'
});

export type SpeechIndicatorProps = {
  spectrum: number[];
  barsCount: number;
}

const SpeechIndicator = ({ spectrum, barsCount} : SpeechIndicatorProps) => {
  const strengths = useMemo(() => {
    const strengths : number[] = [];
    for(let i=0; i < barsCount; i++) {
      const binWidth = Math.ceil(spectrum.length / barsCount);
      const strength = spectrum.slice(i * binWidth, (i + 1) * binWidth)
        .reduce((result, element) => result + element, 0) / (binWidth * 196);
      strengths.push(strength);
    }
    return strengths;
  }, [spectrum, barsCount]);

  return (
    <span style={indicatorStyle}>
      <span style={indicatorContentStyle}>
        {strengths.map((strength, index) => (
          <span key={index} style={bars(strength)}/>
        ))}
      </span>
    </span>
  );
}

export default SpeechIndicator;