import * as React from 'react';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Box, Button, Drawer, IconButton, Paper, Snackbar, Toolbar, Typography, useTheme } from '@mui/material';
import Navigator from './Navigator';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { setError, setIsMobile, setPages } from '../appSlice';
import api from '../api';
import { setUser } from '../slices/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import AvatarButton from './AvatarButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { debounce } from 'lodash';
import { Page } from '../api/types/types';
import Cookies from 'js-cookie';

type WorkspaceProps = React.PropsWithChildren<{
  showBackLink?: boolean;
  backLinkUrl?: string;
  sideBarButton?: ReactNode;
}>;

const Workspace = ({ showBackLink, backLinkUrl, sideBarButton, children }: WorkspaceProps) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const errorMessage = useSelector((state: RootState) => state.app.error.message);
  const isLoggedIn = Cookies.get('isAuthenticated') === 'true';
  const isMobile = useSelector((state: RootState) => state.app.isMobile);

  const [showSideNav, setShowSideNav] = useState(true);

  // const [isMobile, setIsMobile] = useState(false);

  const setResponsiveDisplay = debounce(() => {
    setShowSideNav(window.innerWidth >= 900);
    dispatch(setIsMobile(window.innerWidth < 900));
  });

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(location.pathname);
    } else if (!isLoggedIn) {
      api.reauthenticate()
        .then((responseBody) => {
          const { token, profile, features, pages } = responseBody;
          dispatch(
            setUser({
              profile: profile,
              features: features,
            })
          );
          dispatch(setPages(pages));

          const route =
            location.pathname === '/' ? pages.filter((page: Page) => page.nav)[0].path : location.pathname;
          navigate(route);
        })
        .catch((error) => {
          navigate('/login');
        });
    }
  }, [navigate, dispatch, location.pathname]);

  useEffect(() => {
    const screenSizeObserver = new ResizeObserver(() => {
      setResponsiveDisplay();
    });
    screenSizeObserver.observe(document.body);
    return () => {
      screenSizeObserver.unobserve(document.body);
      screenSizeObserver.disconnect();
    };
  }, [setResponsiveDisplay]);

  const handleBackLinkClicked = useCallback(() => {
    if (backLinkUrl) {
      navigate(backLinkUrl);
    } else {
      navigate(-1);
    }
  }, [navigate, backLinkUrl]);

  const mainStyles = isMobile
    ? {
        marginLeft: 0,
        width: '100%',
      }
    : {
        marginLeft: 240,
        width: 'calc(100% - 240px)',
      };

  return (
    <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
      <Box
        flexGrow={1}
        flexBasis={1}
        display="flex"
        width="100%"
        height="100%"
        bgcolor={theme.palette.background.paper}
      >
        <nav>
          <Drawer
            anchor="left"
            variant={isMobile ? 'temporary' : 'permanent'}
            open={showSideNav}
            onClose={() => {
              setShowSideNav(false);
            }}
          >
            <Box pt={2} height="100%" width={240} overflow="auto" bgcolor={theme.palette.primary.dark} position="relative">
              {isMobile && (
                <Box component="span" position="absolute" right={4} top={4}>
                  <IconButton css={{ color: '#fff' }} onClick={() => setShowSideNav(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
              <Box mb={3} pl={2} pr={2} justifyContent="center">
                <img src="/logo.svg" alt="Mindbloom AI" width="100%" height="auto" />
              </Box>
              <Navigator />
            </Box>
          </Drawer>
        </nav>

        <main css={mainStyles}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {isMobile ? (
                <Toolbar variant="dense">
                  <IconButton
                    onClick={() => {
                      setShowSideNav(true);
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Toolbar>
              ) : (
                <Box ml={1} display="flex" justifyContent="space-between">
                  {showBackLink && (
                    <Button variant="text" onClick={handleBackLinkClicked}>
                      <Box display="flex" alignItems="center">
                        <ArrowBackIcon />
                        Back
                      </Box>
                    </Button>
                  )}
                </Box>
              )}
              <Toolbar variant="dense">
                <AvatarButton />
              </Toolbar>
            </Box>

            <Box height="calc(100% - 40px)" p={2}>
              {isMobile && (
                <Box display="flex" justifyContent="space-between">
                  {showBackLink && (
                    <Button variant="text" onClick={handleBackLinkClicked}>
                      <Box display="flex" alignItems="center">
                        <ArrowBackIcon />
                        Back
                      </Box>
                    </Button>
                  )}
                  {sideBarButton}
                </Box>
              )}
              <Box height="100%"> {children}</Box>
            </Box>
          </Box>
        </main>
      </Box>

      <Snackbar
        open={errorMessage.length > 0}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(setError(''));
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box maxWidth={300}>
          <Paper square elevation={7}>
            <Box borderLeft={`solid 4px ${theme.palette.error.main}`} p={1.5}>
              <Typography>{errorMessage}</Typography>
            </Box>
          </Paper>
        </Box>
      </Snackbar>
    </Box>
  );
};

export default Workspace;
