import React from 'react';
import { Button, Link, Paper, Typography } from '@mui/material';

type TermsContentProps = {
  onAgree?: () => void;
};

const TermsContent = ({ onAgree }: TermsContentProps) => {
  const typographyStyles = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    color: '#555555',
    fontSize: '23px',
    marginBottom: '1rem'
  };

  const typographyStylesBody = {
        fontFamily: 'Inter, sans-serif',
        letterSpacing: '0.008em',
        fontSize: '15px',
        lineHeight: '20px'
  }

  return (
    <Paper elevation={0} sx={{ p: 3, pt: 0, fontFamily: 'Inter, sans-serif' }}>
      <Typography variant="h5" gutterBottom sx={typographyStyles }>
        What's in these terms?
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        These terms tell you the rules for using our website <Link href="https://mindbloom.ai">https://mindbloom.ai</Link> (our site).
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        Who we are and how to contact us
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody }>
        <Link href="https://mindbloom.ai">https://mindbloom.ai</Link> is a site operated by Mindbloom AI Limited (We). We are registered in England and Wales under
        company number 14896549 and have our registered office at 82a James Carter Road, Mildenhall, Bury St. Edmunds, Suffolk, England, IP28 7DE. Our VAT number is 453412808.
        <p>We are a limited company.</p>
        <p>To contact us, please email <Link href="mailto:admin@mindbloom.ai">admin@mindbloom.ai</Link>.</p>
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        By using our site you accept these terms
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        By using our site, you confirm that you accept these terms of use and that you agree to comply with them.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        If you do not agree to these terms, you must not use our site.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody }>
        We recommend that you print a copy of these terms for future reference.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        There are other terms that may apply to you
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody }>
        These terms of use refer to our <Link href="/privacy">Privacy Policy</Link>, which explains how we collect,
        use and store your personal data (and also sets out information about the cookies on our site), which also
        apply to your use of our site.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles }>
        We may make changes to these terms
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody }>
        We amend these terms from time to time. Every time you wish to use our site, please check these terms to
        ensure you understand the terms that apply at that time. These terms were most recently updated on 18 June
        2024.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        We may make changes to our site
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        We may update and change our site from time to time to reflect changes to our products and services, our
        users' needs and our business priorities.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        We may suspend or withdraw our site
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        Our site is made available free of charge.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may
        suspend or withdraw or restrict the availability of all or any part of our site for business and operational
        reasons. We will try to give you reasonable notice of any suspension or withdrawal.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        You are also responsible for ensuring that all persons who access our site through your internet connection
        are aware of these terms of use and other applicable terms of use, and that they comply with them.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        We may transfer this agreement to someone else
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        Our site is only for users in the UK
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        Our site is directed to people residing in the United Kingdom. We do not represent that content available on or through our site is appropriate for use or available in other locations.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        You must keep your account details safe
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at <Link
          href="mailto:admin@mindbloom.ai">admin@mindbloom.ai</Link>.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        How you may use material on our site
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged (except where the content is user-generated).
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        If you print off, copy, download, share or repost any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made (except that you are permitted to print off a copy of these terms of use).
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        No text or data mining, or web scraping
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        You shall not conduct, facilitate, authorise or permit any text or data mining or web scraping in relation to our site or any services provided via, or in relation to, our site for any purpose, including the development, training, fine-tuning or validation of AI systems or models. This includes using (or permitting, authorising or attempting the use of):
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        <ul>
          <li>Any "robot", "bot", "spider", "scraper" or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of our site or any data, content, information or services accessed via the same.</li>
          <li>Any automated analytical technique aimed at analysing text and data in digital form to generate information [or develop, train, fine-tune or validate AI systems or models] which includes but is not limited to patterns, trends and correlations.</li>
        </ul>
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        The provisions in this clause should be treated as an express reservation of our rights in this regard, including for the purposes of Article 4(3) of the Digital Copyright Directive ((EU) 2019/790).
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        You shall not use, and we do not consent to the use of, our site, or any data published by, or contained in, or accessible via, our site or any services provided via, or in relation to, our site for the purposes of developing, training, fine-tuning or validating any AI system or model or for any other purposes not explicitly agreed in writing between us and you.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        This clause will not apply insofar as (but only to the extent that) we are unable to exclude or limit text or data mining or web scraping activity by contract under the laws which are applicable to us.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        Our site, its content and any services provided in relation to the same are only targeted to, and intended for use by, individuals located in countries forming part of the United Kingdom
        (each, a Permitted Territory). By continuing to access, view or make use of our site and any related content and services, you hereby warrant and represent to us that you are located in a
        Permitted Territory. If you are not located in a Permitted Territory, you must immediately discontinue use of our site and any related content and services.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        Rules about linking to our site
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        You must not establish a link to our site in any website that is not owned by you.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        We reserve the right to withdraw linking permission without notice.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        If you wish to link to or make any use of content on our site other than that set out above, please contact <Link href="mailto:admin@mindbloom.ai">admin@mindbloom.ai</Link>.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        Our trade marks
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        “Mindbloom AI” and associated marks and logos are trade marks of ours. You are not permitted to use them without our approval, unless they are part of material you are using as permitted under
        How you may use material on our site [INSERT AS LINK].
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        Uploading content to our site
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        Whenever you make use of a feature that allows you to create content directly on our site, upload or share content to our site, or to make contact with other users of our site, you must comply
        with the standards set out below.
        You may not use our site:
        <ul>
          <li>In any way that breaches any local, national or international law or regulation.</li>
          <li>In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect.</li>
          <li>For the purpose of harming or attempting to harm minors in any way.</li>
          <li>To bully, insult, intimidate or humiliate any person.</li>
          <li>To send, knowingly receive, upload, download, share, post, use or re-use any material which does not comply with our content standards.</li>
          <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).</li>
          <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or
            similar computer code designed to adversely affect the operation of any computer software or hardware.
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        User Content must comply with the law applicable in any country from which it is posted and to which the website is targeted and must not:
        <ul>
          <li>Be defamatory of any person.</li>
          <li>Be obscene, offensive, hateful or inflammatory.</li>
          <li>Infringe any copyright, database right or trade mark of any other person.</li>
          <li>Include video content that has been or would be likely to be given an R18 certificate by the British Board of Film Classification (BBFC).</li>
          <li>Include video content not suitable for BBFC classification.</li>
          <li>Include material that might impair the physical, mental or moral development of persons under the age of 18.</li>
          <li>Be likely to deceive any person.</li>
          <li>Breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</li>
          <li>Contain illegal content or promote any illegal content or activity.</li>
          <li>Be in contempt of court.</li>
          <li>Be threatening, abuse or invade another's privacy, or cause annoyance, inconvenience or needless anxiety.</li>
          <li>Be likely to harass, upset, embarrass, alarm or annoy any other person.</li>
          <li>Impersonate any person or misrepresent your identity or affiliation with any person.</li>
          <li>Give the impression that the User Content emanates from us, if this is not the case.</li>
          <li>Advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of example only) copyright infringement or computer misuse.</li>
          <li>Contain a statement which you know or believe, or have reasonable grounds for believing, that members of the public to whom the statement is, or is to be, published are likely to
            understand as a direct or indirect encouragement or other inducement to the commission, preparation or instigation of acts of terrorism.
          </li>
          <li>Be pornographic.</li>
        </ul>
        You warrant that any such contribution complies with those standards, and you are liable to us and indemnify us for any breach of that warranty. This means you will be responsible for any
        loss or damage we suffer as a result of your breach of warranty.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        We will consider any content you upload to our site to be non-confidential and not protected by any trade mark, patent or copyright ("non-proprietary"), that is, in the public domain. You
        own your content, but you are required to grant us a non-exclusive royalty-free limited licence to use, store, copy and share that content in order for us to deliver the services we provide
        to you.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        We also have the right to disclose your identity to anyone who is claiming that any content posted or uploaded by you to our site violates their intellectual property rights or their right
        to privacy.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        We have the right to remove any posting you make on our site if, in our opinion, your post does not comply with the acceptable use standards set out above.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        If you wish to contact us in relation to content you have uploaded to our site and that we have taken down, please contact <Link href="mailto:admin@mindbloom.ai">admin@mindbloom.ai</Link>.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        User-generated content is not approved by us
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        Our site may include information and materials uploaded by other users. This information and these materials have not been verified or approved by us. The views expressed by other users on
        our site do not represent our views or values.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        Do not rely on information on this site
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        The content on our site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before
        taking, or refraining from, any action on the basis of the content on our site.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is
        accurate, complete or up to date.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        We are not responsible for websites we link to
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by
        us of those linked websites or information you may obtain from them.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStyles}>
        We have no control over the contents of those sites or resources.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        We are not responsible for viruses
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        We do not guarantee that our site will be secure or free from bugs or viruses.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        You are responsible for configuring your information technology, computer programs and platform to access our site. You should use your own virus protection software.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        You must not introduce viruses
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful, or otherwise harmfully interacting
        with our site or any part of it. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our
        site or any other equipment or network connected with our site. You must not interfere with, damage or disrupt any software used in the provision of our site or any equipment or network or
        software owned or used by any third party on which this site relies in any way. You must not attack our site via a denial-of-service attack or a distributed denial-of-service attack. By
        breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will
        co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        Our responsibility for loss or damage suffered by you
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        We do not guarantee that our site will be secure or free from bugs or viruses.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        Whether you are a consumer or a business user:
        <ul>
          <li>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the
            negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
          </li>
          <li>Different limitations and exclusions of liability will apply to liability arising as a result of the supply of any products or services to you.</li>
        </ul>
      </Typography>

      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        If you are a business user:
        <ul>
          <li>We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it or reports or other content provided to you by us.</li>
          <li>We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in
            connection with:
            o use of, or inability to use, our site; or
            o use of or reliance on any content displayed on our site or other content provided to you by us.
          </li>
          <li>In particular, we will not be liable for:
            o loss of profits, sales, business, or revenue;
            o business interruption;
            o loss of anticipated savings;
            o loss of business opportunity, goodwill or reputation; or
            o any indirect or consequential loss or damage.
          </li>

          If you are a consumer user:
          <li>We only provide our site and related services for domestic and private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any
            loss of profit, loss of business, business interruption, or loss of business opportunity.</li>
          <li>If defective digital content that we have supplied damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either
            repair the damage or pay you compensation. However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or
            for damage that was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.
          </li>
        </ul>
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        How we may use your personal information
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        We will only use your personal information as set out in our https://app.mindbloom.ai/privacy. The reports and other content and outputs that result from our processing of audio conversation
        data you upload will not contain any personal information.
      </Typography>

      <Typography variant="h5" gutterBottom sx={typographyStyles}>
        Which country's laws apply to any disputes?
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        If you are a consumer, please note that these terms of use, their subject matter and their formation, are governed by English law. We both agree that the courts of England and Wales will
        have exclusive jurisdiction, except that if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are a resident of Scotland, you may also
        bring proceedings in Scotland.
      </Typography>
      <Typography variant="body1" paragraph sx={typographyStylesBody}>
        If you are a business, these terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by English law. We both agree to the exclusive
        jurisdiction of the courts of England and Wales.
      </Typography>
      { onAgree && (
        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
          <Button
            variant="contained"
            onClick={onAgree}
          >
            Agree
          </Button>
        </div>
      )}
    </Paper>
  );
};

export default TermsContent;
