import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { setConversations } from '../slices/conversationsSlice';
import { DataGrid, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { css } from '@emotion/react';
import { Route } from 'react-router-dom';
import Workspace from '../components/Workspace';
import api from '../api';
import moment from 'moment';
import { ConversationSummaryDTO } from '../api/types/conversation';

type ColumnDefinition = {
  name: string,
  description: string,
  lookupName: string,
  toDisplay: (value: any, params?: any) => string,
  minWidth?: number
  flex?: number
};

const toString = (value: any): string => {
  return (value) ? value.toString() : '';
};

const toGender = (value: any): string => {
  switch (value) {
    case 'M': return 'Male';
    case 'F': return 'Female';
    case 'O': return 'Other';
  }
  return (value) ? value.toString() : '';
};

const toDate = (value: any): string => {
  return moment(value).format('DD/MM/YYYY hh:mm:ss')
};

const renderCell = (params: GridRenderCellParams) => (
  <Box my={0.5}>
    <Typography variant="body1">
      {params.value && params.value.trim().length > 0 ? params.value : '(N/A)'}
    </Typography>
  </Box>
);

const dataGridStyles = css({
  '.MuiDataGrid-viewport, .MuiDataGrid-row,.MuiDataGrid-renderingZone': {
    maxHeight: 'fit-content !important',
  },
  '.MuiDataGrid-cell': {
    maxHeight: 'fit-content !important',
    overflow: 'auto',
    alignItems: 'flex-start',
  },
  '.dataGrid--header': {
    backgroundColor: '#f5f5f5',
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
});

const columnDefs: ColumnDefinition[] = [{
  name: 'date',
  description: 'Date',
  lookupName: 'createdAt',
  toDisplay: toDate,
  minWidth: 200
}, {
  name: 'brief',
  description: 'Brief',
  lookupName: 'brief',
  toDisplay: toString,
  flex: 1
}, {
  name: 'cName',
  description: 'You',
  lookupName: 'candidateName',
  toDisplay: toString,
//  minWidth: 100
}, {
  name: 'cEthnicity',
  description: 'Your Ethnicity',
  lookupName: 'candidateEthnicity',
  toDisplay: toString,
  minWidth: 140
}, {
  name: 'botName',
  description: 'Bot',
  lookupName: 'botName',
  toDisplay: toString,
//  minWidth: 100
}, {
  name: 'botPersonality',
  description: 'Personality',
  lookupName: 'botPersonality',
  toDisplay: toString,
  minWidth: 120
}, {
  name: 'botEmotion',
  description: 'Emotion',
  lookupName: 'botEmotion',
  toDisplay: toString,
  minWidth: 140
}, {
  name: 'botGender',
  description: 'Gender',
  lookupName: 'botGender',
  toDisplay: toGender,
  minWidth: 140
}, {
  name: 'botEthnicity',
  description: 'Ethnicity',
  lookupName: 'botEthnicity',
  toDisplay: toString,
  minWidth: 140
}];

const Conversations = () => {
  const dispatch: AppDispatch = useDispatch();

  const conversations = useSelector((state: RootState) => state.conversations.conversations);

  useEffect(() => {
      api.getConversations({}).then(async (convs: ConversationSummaryDTO[]) => {
        dispatch(
          setConversations({
            conversations: convs
          })
        );
      });
  }, [dispatch]);

  const columns = useMemo(() => {
    return columnDefs.map((cd, index) => ({
      field: cd.name,
      headerName: cd.description,
      headerClassName: 'dataGrid--header',
      flex: cd.flex,
      width: cd.minWidth,
      renderCell,
    }));
  }, []);

  const rows = useMemo(() => {
    return conversations.map((dr: any, index: number) => {
      let result: any = {
        id: index,
      };

      columnDefs.forEach(cd => {
        result[cd.name] = cd.toDisplay(dr[cd.lookupName]);
      });

      return result;
    });
  }, [conversations]);

  const onRowClick = async (params: GridRowParams) => {
//    const { id } = params.row;
  };

  return (
    <Box height="100%" overflow="auto">
      <Typography display="inline" variant="h5">
        Recent Conversations
      </Typography>
      <Box p={1} my={0.75}>
        <DataGrid
          editMode="row"
          rowSelection={true}
          columns={columns}
          rows={rows}
          getRowHeight={() => 'auto'}
          disableColumnMenu={true}
          hideFooter={true}
          css={dataGridStyles}
          isCellEditable={() => false}
          onRowClick={onRowClick}
        />
      </Box>
    </Box>
  );
};

const ConversationsRoute = (path: string) => (
  <Route
    key={path}
    path={path}
    element={
      <Workspace>
        <Conversations></Conversations>
      </Workspace>
    }
  />
);

export default ConversationsRoute;
