import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CharactersIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M7 7.5C8.24264 7.5 9.25 6.49264 9.25 5.25C9.25 4.00736 8.24264 3 7 3C5.75736 3 4.75 4.00736 4.75 5.25C4.75 6.49264 5.75736 7.5 7 7.5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M5 16V20C5 20.552 5.448 21 6 21H8C8.552 21 9 20.552 9 20V16L10.055 15.297C10.333 15.112 10.5 14.799 10.5 14.465V11C10.5 10.448 10.052 10 9.5 10H4.5C3.948 10 3.5 10.448 3.5 11V14.465C3.5 14.799 3.667 15.112 3.945 15.297L5 16Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M14.5 5H20.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M14.5 8H20.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M14.5 11H18.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
  </SvgIcon>
);

export default CharactersIcon;
